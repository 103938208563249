import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    paddingLeft: 0,
    "@media only screen and (min-width: 750px)": {
      paddingLeft: 180,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 200,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 230,
    },
  },
}));
