import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import { useUiState } from "../../../context/UiStateContext";
import useUser from "../../../hooks/useUser";
import { LOGIN_PAGE_ROUTE, REGISTER_PAGE_ROUTE } from "../../../Routes";
import useStyles from "./DropdownMenu.styles";

export default function DropdownMenu() {
  const { clearUiState } = useUiState();
  const { logout } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToRoute = (route: string) => {
    handleClose();
    navigate(route);
  };

  const onLogoutClicked = () => {
    handleClose();
    clearUiState();
    logout();
  };

  return (
    <>
      <IconButton
        className={classes.iconButton}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!user && (
          <div>
            <MenuItem onClick={() => goToRoute(LOGIN_PAGE_ROUTE)}>
              <ListItemText primary="Log in" />
            </MenuItem>
            <MenuItem onClick={() => goToRoute(REGISTER_PAGE_ROUTE)}>
              <ListItemText primary="Register" />
            </MenuItem>
          </div>
        )}
        {user && (
          <MenuItem onClick={onLogoutClicked}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
