import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  dialogWrapper: {
    maxWidth: 680,
  },
  textarea: {
    marginTop: theme.spacing(2),
  },
  attachFileContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  attachedFileName: {
    marginLeft: theme.spacing(1),
  },
  turnaroundMessage: {
    marginTop: theme.spacing(2),
  },
}));
