import { useMutation } from "react-query";

import { useAuth } from "../../../context/AuthContext";
import { useNotifications } from "../../../context/NotificationContext";
import useStateReducer from "../../../hooks/useStateReducer";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import FailedAccountsRequestMessage from "../../components/FailedAccountsRequestMessage/FailedAccountsRequestMessage";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import useStyles from "./ChangePassword.styles";

type IState = {
  oldPassword: string;
  showOldPassword: boolean;
  isOldPasswordValid: boolean;
  newPassword: string;
  showNewPassword: boolean;
  isNewPasswordValid: boolean;
  confirmNewPassword: string;
  showConfirmNewPassword: boolean;
  isConfirmNewPasswordValid: boolean;
  showInputErrorMessages: boolean;
};

export default function AccountDetails() {
  const { changePassword } = useAuth();
  const { addNotification } = useNotifications();
  const classes = useStyles();
  const {
    mutate: doChangePassword,
    isLoading: isSubmitting,
    error: changePasswordError,
  } = useMutation<void, Error, { oldPassword: string; newPassword: string }>(
    changePassword
  );

  const initialState = {
    oldPassword: "",
    showOldPassword: false,
    isOldPasswordValid: false,
    newPassword: "",
    showNewPassword: false,
    isNewPasswordValid: false,
    confirmNewPassword: "",
    showConfirmNewPassword: false,
    isConfirmNewPasswordValid: false,
    showInputErrorMessages: false,
  };
  const [
    {
      oldPassword,
      showOldPassword,
      isOldPasswordValid,
      newPassword,
      showNewPassword,
      isNewPasswordValid,
      confirmNewPassword,
      showConfirmNewPassword,
      isConfirmNewPasswordValid,
      showInputErrorMessages,
    },
    setState,
  ] = useStateReducer<IState>(initialState);

  const clearForm = () => setState(initialState);

  const onSaveClicked = async () => {
    if (isSubmitting) {
      return;
    }
    if (
      !isOldPasswordValid ||
      !isNewPasswordValid ||
      !isConfirmNewPasswordValid
    ) {
      setState({ showInputErrorMessages: true });
    } else {
      setState({ showInputErrorMessages: false });
      await doChangePassword(
        { oldPassword, newPassword },
        {
          onSuccess: () => {
            clearForm();
            addNotification("Your password has been updated");
          },
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      {changePasswordError && (
        <FailedAccountsRequestMessage
          className={classes.failedRequestMessage}
          headline={changePasswordError.message}
        />
      )}
      <PasswordInput
        className={classes.input}
        title="Old password"
        onChange={(oldPassword, isOldPasswordValid) =>
          setState({ oldPassword, isOldPasswordValid })
        }
        onShowPasswordClick={() =>
          setState({ showOldPassword: !showOldPassword })
        }
        showPassword={showOldPassword}
        value={oldPassword}
        showError={showInputErrorMessages}
        hideHelperText
      />
      <PasswordInput
        className={classes.input}
        title="New password"
        onChange={(newPassword, isNewPasswordValid) =>
          setState({ newPassword, isNewPasswordValid })
        }
        onShowPasswordClick={() =>
          setState({ showNewPassword: !showNewPassword })
        }
        showPassword={showNewPassword}
        newPassword
        value={newPassword}
        showError={showInputErrorMessages}
      />
      <PasswordInput
        className={classes.input}
        title="Confirm new password"
        onChange={(confirmNewPassword, isConfirmNewPasswordValid) =>
          setState({ confirmNewPassword, isConfirmNewPasswordValid })
        }
        onShowPasswordClick={() =>
          setState({ showConfirmNewPassword: !showConfirmNewPassword })
        }
        showPassword={showConfirmNewPassword}
        value={confirmNewPassword}
        newPassword
        isConfirmPassword
        expectedPassword={newPassword}
        showError={showInputErrorMessages}
      />
      <ButtonWithProgress
        label="Save"
        isLoading={isSubmitting}
        onClick={onSaveClicked}
      />
    </div>
  );
}
