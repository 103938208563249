import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  root: {
    display: "block",
    "@media only screen and (min-width: 750px)": {
      display: "none",
    },
  },
}));
