import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  selectContainer: {
    width: "100%",
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
  },
  selectUnsetValue: {
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  actions: {
    justifyContent: "space-between",
  },
  rightActions: {
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
}));
