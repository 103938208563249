import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useAlert } from "../../../context/AlertContext";

export default function GlobalAlertDialog() {
  const { alert, removeAlert } = useAlert();

  return (
    <Dialog open={Boolean(alert)} onClose={removeAlert}>
      <DialogTitle>{alert?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{alert?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={removeAlert}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
