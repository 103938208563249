import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import AppTheme from "./AppTheme";
import { AlertProvider } from "./context/AlertContext";
import { AuthProvider } from "./context/AuthContext";
import { ErrorProvider } from "./context/ErrorContext";
import { NotificationProvider } from "./context/NotificationContext";
import { UiStateProvider } from "./context/UiStateContext";
import ScrollToTopController from "./views/components/ScrollToTopController/ScrollToTopController";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1 * 60 * 1000,
    },
  },
});

interface IProps {
  children: ReactNode;
}

export default function AppProviders({ children }: IProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <ScrollToTopController />
        <ThemeProvider theme={AppTheme}>
          <AuthProvider>
            <UiStateProvider>
              <ErrorProvider>
                <AlertProvider>
                  <NotificationProvider>{children}</NotificationProvider>
                </AlertProvider>
              </ErrorProvider>
            </UiStateProvider>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
