import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import useUser from "../../../hooks/useUser";
import {
  ECOSHOTS_PAGE_ROUTE,
  GROUP_ADMIN_INVITATIONS_PAGE_ROUTE,
  GROUP_ADMIN_MEMBERS_PAGE_ROUTE,
  GROUP_ADMINISTRATION_PAGE_ROUTE,
} from "../../../Routes";
import useStyles from "./GroupAdminPage.styles";
import Invitations from "./Invitations";
import Members from "./Members";

export default function GroupAdminPage() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "EcoShot Image Hub - Group Admin";
  }, []);

  const { userIsGroupAdmin, userIsSuperUser } = useUser();
  if (!userIsGroupAdmin && !userIsSuperUser) {
    return <Navigate to={ECOSHOTS_PAGE_ROUTE} replace />;
  }
  if (location.pathname === GROUP_ADMINISTRATION_PAGE_ROUTE) {
    return <Navigate to={GROUP_ADMIN_MEMBERS_PAGE_ROUTE} replace />;
  }

  return (
    <div>
      <Paper square>
        <div className={classes.tabsContainer}>
          <div className={classes.tabHeader}>
            <SupervisedUserCircleIcon fontSize="small" />
            <Typography variant="h6">Group Administration</Typography>
          </div>
          <Tabs
            value={location.pathname}
            onChange={(evt, route) => navigate(route)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Members" value={GROUP_ADMIN_MEMBERS_PAGE_ROUTE} />
            <Tab
              label="Invitations"
              value={GROUP_ADMIN_INVITATIONS_PAGE_ROUTE}
            />
          </Tabs>
        </div>
      </Paper>
      <div className={classes.contentContainer}>
        <Routes>
          <Route path="/members" element={<Members />} />
          <Route path="/invitations" element={<Invitations />} />
          <Route
            path="*"
            element={<Navigate to={GROUP_ADMIN_MEMBERS_PAGE_ROUTE} replace />}
          />
        </Routes>
      </div>
    </div>
  );
}
