import React, { useState } from "react";

import TextInput from "../TextInput/TextInput";

interface IProps {
  onChange: (name: string, isValid: boolean) => void;
  value: string;
  className?: string;
  showError?: boolean;
}

export default function CompanyNameInput({
  onChange,
  value,
  className,
  showError,
}: IProps) {
  const invalidEmailMessage = "Please enter your company name";
  // trim() - Remove whitespaces from both side of string
  const isCompanyNameValid = (name: string) => name.trim().length > 1;
  const [errorMessage, setErrorMessage] = useState(invalidEmailMessage);
  const [isCurrentValueValid, setIsCurrentValueValid] = useState(
    isCompanyNameValid(value)
  );

  const handleChange = (name: string) => {
    const valid = isCompanyNameValid(name);
    setErrorMessage(valid ? "" : invalidEmailMessage);
    setIsCurrentValueValid(valid);
    onChange(name, valid);
  };

  return (
    <TextInput
      className={className}
      title="Company name"
      onChange={handleChange}
      value={value}
      helperText={showError && errorMessage}
      error={showError && !isCurrentValueValid}
    />
  );
}
