import { Box, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React, { useState } from "react";

import { useModelPoseImageUrl } from "../../../hooks/api/models";
import useStyles from "./VariantImage.styles";

interface IProps {
  s3Key: string;
  label: string;
  preview: boolean;
  express: boolean;
}

export default function VariantImage({
  s3Key,
  label,
  preview,
  express,
}: IProps) {
  const classes = useStyles();
  const {
    data: imageUrl,
    isLoading: isImageUrlLoading,
    isError: isImageUrlError,
  } = useModelPoseImageUrl(s3Key);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  const ColoredText = withStyles({
    root: {
      color: express ? "#bd5d00" : preview ? "#00c4c4" : "black",
    },
  })(Typography);
  const text = express ? "Express" : preview ? "Preview" : label;
  return (
    <>
      {imageUrl && !errorLoadingImage && !isImageUrlError && (
        <Box display="inline-flex">
          <Paper
            className={classnames(classes.imageContainer, {
              [classes.expressContainer]: express,
              [classes.standardContainer]: !express && !preview,
              [classes.previewContainer]: preview && !express,
            })}
            elevation={0}
          >
            {text && imageHasLoaded && (
              <ColoredText className={classes.textOverlay}>{text}</ColoredText>
            )}
            <img
              src={imageUrl}
              className={classnames(classes.thumbnail, {
                [classes.showThumbnail]: imageHasLoaded,
                [classes.bigThumbnail]: !label,
              })}
              alt={s3Key}
              onLoad={() => setImageHasLoaded(true)}
              onError={() => setErrorLoadingImage(true)}
            />
          </Paper>
        </Box>
      )}
      {(!imageUrl || errorLoadingImage || isImageUrlError) && (
        <div
          className={classnames(classes.thumbnail, classes.missingThumbnail)}
        >
          {!isImageUrlLoading && (
            <Typography variant="caption" display="block" color="textSecondary">
              no thumbnail
            </Typography>
          )}
        </div>
      )}
    </>
  );
}
