import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Sticky from "react-stickynode";

import {
  useModelMeasurementManifest,
  useModelPosesManifest,
} from "../../../hooks/api/models";
import useFilterPosesUiState from "../../../hooks/useFilterPosesUiState";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import FilterPosesButton from "./FilterPosesButton";
import ModelPoseImage from "./ModelPoseImage";
import useStyles from "./ModelPosesPage.styles";

export default function ModelPosesPage() {
  const classes = useStyles();
  const { data: manifest, isLoading: isManifestLoading } =
    useModelPosesManifest();
  const { data: measurement, isLoading: isMeasurementLoading } =
    useModelMeasurementManifest();
  const rootElementRef = useRef<HTMLDivElement>(null);
  const [descending, setDescending] = useState(true);
  const { uiState: poseFilters } = useFilterPosesUiState();

  useEffect(() => {
    document.title = "EcoShot Image Hub - Model Poses";
  }, []);

  if (isManifestLoading || !manifest || !measurement || isMeasurementLoading) {
    return <PageLoadingIndicator />;
  }

  const scrollToTopOfListing = () =>
    rootElementRef.current?.scrollIntoView({ behavior: "smooth" });

  const reverseOrder = () => {
    setDescending((prevState) => !prevState);
  };

  const onSummaryLinkClicked = () => {
    window.open(
      "https://help.ecoshotstudio.com/en/articles/8658750-different-shoe-hair-and-trousers-options-for-models",
      "_blank"
    );
  };

  const inchesToCM = (inches: number) => {
    return Math.round(2.54 * inches);
  };

  const inchesToFeetAndInches = (inches: number) => {
    const feet = Math.floor(inches / 12);
    const inch = Math.round(inches % 12);
    return `${feet}'${inch}"`;
  };

  const formatMeasurementText = (text: string, inches: number) => {
    let formattedInch = `${Math.round(inches)}"`;
    if (text.toLocaleLowerCase() === "height") {
      formattedInch = inchesToFeetAndInches(inches);
    }
    return `${text} ${inchesToCM(inches)}cm/${formattedInch}`;
  };

  const sortedModels = manifest.models
    .filter((model) =>
      poseFilters.category ? model.category === poseFilters.category : true
    )
    .sort((a, b) => {
      if (a.model > b.model) {
        return 1;
      } else if (a.model < b.model) {
        return -1;
      } else {
        return 0;
      }
    });

  const listModels = sortedModels.map((model) => {
    const baseModel = model.baseModel;
    const modelMeasurements = measurement.Models[baseModel];
    const measurements = {
      Height: modelMeasurements.TotalHeight,
      Chest: modelMeasurements.ChestCircumference,
      "Narrow Waist": modelMeasurements.NarrowWaist,
      Hip: modelMeasurements.HipCircumference,
    };
    const formatted = Object.entries(measurements).map(([key, value]) => {
      return formatMeasurementText(key, value);
    });
    const measurementText = "Scan Measurements: " + formatted.join(", ");

    const images = model.images
      .filter(
        (image) =>
          (poseFilters.view ? image.view === poseFilters.view : true) &&
          (poseFilters.preview ? image.preview === poseFilters.preview : true)
      )
      .filter(
        (image) =>
          (poseFilters.view ? image.view === poseFilters.view : true) &&
          (poseFilters.express ? image.express === poseFilters.express : true)
      )
      .filter((image) =>
        poseFilters.shoes
          ? image.variants.shoes.includes(poseFilters.shoes)
          : true
      )
      .sort((a, b) => {
        if ((a.express && !b.express) || (a.preview && !b.preview)) {
          return -1;
        } else {
          return 1;
        }
      })
      .map((image) => (
        <ModelPoseImage
          key={image.image}
          imageKey={image}
          showShoes={poseFilters.showShoes}
        />
      ));
    if (images.length !== 0) {
      return (
        <Paper key={model.model} className={classes.modelContainer}>
          <Typography variant="h6" component="h3">
            {model.model}
          </Typography>
          <Typography variant="caption">{measurementText}</Typography>
          <div className={classes.posesContainer}>{images}</div>
        </Paper>
      );
    } else {
      return null;
    }
  });

  const ListingActions = () => {
    return (
      <div className={classes.actionsContainer}>
        <Sticky enabled top={64} innerZ={1}>
          {({ status }) => {
            return (
              <div
                className={classnames(classes.actions, {
                  [classes.actionsWhenSticky]: status === Sticky.STATUS_FIXED,
                })}
              >
                <div className={classes.actionButtons}>
                  <Button
                    size="small"
                    startIcon={<SortByAlphaIcon />}
                    onClick={() => {
                      reverseOrder();
                      status === Sticky.STATUS_FIXED && scrollToTopOfListing();
                    }}
                  >
                    Sort Order
                  </Button>
                  <FilterPosesButton
                    filterValues={manifest.filters}
                    onFiltersChanged={() => {
                      status === Sticky.STATUS_FIXED && scrollToTopOfListing();
                    }}
                  />
                </div>
                {poseFilters.showShoes && (
                  <div className={classes.actionButtons}>
                    <Button
                      size="small"
                      onClick={() => {
                        onSummaryLinkClicked();
                        status === Sticky.STATUS_FIXED &&
                          scrollToTopOfListing();
                      }}
                    >
                      Looking for different hair or shoes? Click here
                    </Button>
                  </div>
                )}
              </div>
            );
          }}
        </Sticky>
      </div>
    );
  };

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <EmojiPeopleIcon fontSize="small" />
            <Typography variant="h6">Model Poses</Typography>
          </div>
        </div>
        <div className={classes.headerContainer}>
          <div>
            <Typography variant="caption">
              VStitcher avatars of our models, created using our MeModel™
              technology, can be downloaded from the EcoShot plugin
            </Typography>
          </div>
        </div>
      </Paper>
      <div className={classes.contentContainer}>
        <ListingActions />
        {descending ? listModels.reverse() : listModels}
      </div>
    </div>
  );
}
