// useful for resetting all values in an object
export default function setObjectValues(
  obj: Record<string, unknown>,
  val: unknown
) {
  return Object.keys(obj).reduce((accumulator, current) => {
    accumulator[current] = val;
    return accumulator;
  }, {} as Record<string, unknown>);
}
