import {
  GetItemCommand,
  GetItemCommandOutput,
  QueryCommand,
  QueryCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { API } from "aws-amplify";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  ErrorWithResponse,
  GroupData,
  GroupInvitation,
  GroupManifest,
  GroupMember,
} from "../../types/core";
import useDynamoDbClient from "../useDynamoDbClient";
import useFetchClient from "../useFetchClient";
import useS3Client from "../useS3Client";
import useUser from "../useUser";

type GroupsQueryCommandOutput = Omit<QueryCommandOutput, "Items"> & {
  Items?: Array<Pick<GroupData, "GroupId" | "GroupName">>;
};

function useGroups() {
  const dynamoDbClient = useDynamoDbClient();
  const { userIsSuperUser } = useUser();
  return useQuery<GroupsQueryCommandOutput>({
    queryKey: ["groups"],
    queryFn: () => {
      const params = {
        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
        Select: "SPECIFIC_ATTRIBUTES",
        ProjectionExpression: "#gid,#gn",
        KeyConditionExpression: "PK = :pk",
        ExpressionAttributeValues: {
          ":pk": { S: "groupname" },
        },
        ExpressionAttributeNames: {
          "#gid": "GroupId",
          "#gn": "GroupName",
        },
      };
      return dynamoDbClient.send(
        new QueryCommand(params)
      ) as Promise<GroupsQueryCommandOutput>;
    },
    enabled: Boolean(userIsSuperUser),
  });
}

type UseGroupDataResponse = GetItemCommandOutput & {
  Item: GroupData;
};

function useGroupData() {
  const dynamoDbClient = useDynamoDbClient();
  const { userGroupId } = useUser();
  return useQuery<UseGroupDataResponse>({
    queryKey: ["group", userGroupId],
    queryFn: () => {
      return dynamoDbClient.send(
        new GetItemCommand({
          TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
          Key: {
            PK: { S: `group:${userGroupId}` },
            SK: { S: "metadata" },
          },
        })
      ) as Promise<UseGroupDataResponse>;
    },
    enabled: Boolean(userGroupId),
  });
}

type UseGroupMembersResponse = GetItemCommandOutput & {
  OK: boolean;
  Result: {
    Members: Array<GroupMember>;
  };
};

function useGroupMembers(groupId?: string | null) {
  const { userGroupId } = useUser();
  let group = groupId || userGroupId;
  if (!group.startsWith("group:")) {
    group = `group:${group}`;
  }
  return useQuery<UseGroupMembersResponse>({
    queryKey: ["group-members", group],
    queryFn: () =>
      API.post("API", `/`, {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "ListGroupMembers",
          Payload: { GroupId: group },
        },
      }),
  });
}

function useUpdateMemberPermissions() {
  const queryClient = useQueryClient();
  return useMutation<void, Error, { userId: string; permissions: number }>(
    ({ userId, permissions }) =>
      API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "SetUserPermissions",
          Payload: {
            UserId: userId,
            Permissions: permissions,
          },
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("group-members");
      },
    }
  );
}

function useDeleteMember() {
  const queryClient = useQueryClient();
  const { userGroupId } = useUser();
  return useMutation<void, Error, { userId: string }>(
    ({ userId }) =>
      API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "RemoveUserFromGroup",
          Payload: {
            GroupId: `group:${userGroupId}`,
            UserId: userId,
          },
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("group-members"),
    }
  );
}

type GroupInvitationsQueryCommandOutput = Omit<QueryCommandOutput, "Items"> & {
  Items?: Array<GroupInvitation>;
};

function useGroupInvitations(groupId?: string | null) {
  const dynamoDbClient = useDynamoDbClient();
  const { userGroupId, userIsSuperUser } = useUser();
  const group = userIsSuperUser ? groupId || userGroupId : userGroupId;
  return useQuery<GroupInvitationsQueryCommandOutput>({
    queryKey: ["group-invitations", group],
    queryFn: () => {
      const params = {
        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
        KeyConditionExpression: "PK = :pk and begins_with(SK, :sk)",
        ExpressionAttributeValues: {
          ":pk": { S: `group:${group}` },
          ":sk": { S: "groupinvite:" },
        },
      };
      return dynamoDbClient.send(
        new QueryCommand(params)
      ) as Promise<GroupInvitationsQueryCommandOutput>;
    },
  });
}

function useInviteMember() {
  const queryClient = useQueryClient();
  const { userGroupId } = useUser();
  return useMutation<
    void,
    ErrorWithResponse,
    { email: string; permissions: number }
  >(
    ({ email, permissions }) =>
      API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "SendGroupInvite",
          Payload: {
            GroupId: `group:${userGroupId}`,
            InviteeEmail: email,
            InviteePermissions: permissions,
          },
        },
      }),
    { onSuccess: () => queryClient.invalidateQueries("group-invitations") }
  );
}

function useDeleteInvite() {
  const queryClient = useQueryClient();
  const { userGroupId } = useUser();
  return useMutation<void, Error, { inviteId: string }>(
    ({ inviteId }) =>
      API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "RevokeGroupInvite",
          Payload: {
            GroupId: `group:${userGroupId}`,
            InviteId: `${inviteId}`,
          },
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("group-invitations"),
    }
  );
}

function useGroupManifest(manifestUri?: string | null) {
  const s3Client = useS3Client();
  const fetchClient = useFetchClient();
  return useQuery<GroupManifest>({
    queryKey: ["group-manifest"],
    queryFn: async () => {
      const preSignedUrl = await getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: manifestUri?.replaceAll("s3://ecoshot-assets/", ""),
        })
      );
      return fetchClient(preSignedUrl);
    },
    enabled: Boolean(manifestUri),
  });
}

export {
  useDeleteInvite,
  useDeleteMember,
  useGroupData,
  useGroupInvitations,
  useGroupManifest,
  useGroupMembers,
  useGroups,
  useInviteMember,
  useUpdateMemberPermissions,
};
