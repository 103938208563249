import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import moment from "moment";
import { ReactNode } from "react";

import { useGroupMembers } from "../../../hooks/api/groups";
import { Job } from "../../../types/core";
import RenderThumbnail from "../RenderThumbnail/RenderThumbnail";
import useStyles from "./JobCard.styles";

interface IProps {
  job: Job;
  actions?: ReactNode;
}

export default function JobCard({ job, actions }: IProps) {
  const classes = useStyles();
  const statusDate = moment(job.StatusTimestamp.S);
  const isRetouchStatus = job.Status.S.includes("Retouch");
  const isProblemStatus = job.Status.S.includes("Problem");
  const isRetouchCompleted = job.Status.S === "RetouchCompleted";
  const isCompleted = job.Status.S === "Completed";
  const isEcoshotInProgress =
    !isCompleted && !isRetouchStatus && !isProblemStatus;
  const isProblemReportInProgress = !isRetouchCompleted && isProblemStatus;
  const isRetouchInProgress = !isRetouchCompleted && isRetouchStatus;
  const { data: groupMembers } = useGroupMembers(job.GroupId.S);
  const userName =
    groupMembers?.Result.Members.find(
      (member) => member.UserId === job.RequestedBy.S
    )?.Name ?? "";

  let headerLabel = "Ecoshot";
  if (isRetouchCompleted) {
    headerLabel = "Retouched EcoShot";
  } else if (isCompleted) {
    headerLabel = "EcoShot";
  } else if (isEcoshotInProgress) {
    headerLabel = "Creating EcoShot";
  } else if (isRetouchInProgress) {
    headerLabel = "Creating Retouch";
  } else if (isProblemReportInProgress) {
    headerLabel = "Under Review";
  }

  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Paper className={classes.root}>
        <div
          className={classnames(classes.header, {
            [classes.headerRetouchedEcoshot]:
              isRetouchCompleted ||
              isRetouchInProgress ||
              isProblemReportInProgress,
            [classes.headerEcoshot]: isCompleted || isEcoshotInProgress,
          })}
        >
          {headerLabel}
        </div>
        <div className={classes.thumbnailContainer}>
          <RenderThumbnail job={job} />
        </div>
        <div className={classes.contentContainer}>
          <Tooltip title={job.ImageName.S} placement="top-start">
            <Typography variant="body2" gutterBottom className={classes.title}>
              {job.ImageName.S}
            </Typography>
          </Tooltip>
          <Tooltip title={userName} placement="top-start">
            <Typography variant="caption" display="block" noWrap>
              {userName}
            </Typography>
          </Tooltip>
          <Tooltip title={statusDate.format("llll")} placement="top-start">
            <Typography variant="caption" display="block" color="textSecondary">
              {statusDate.fromNow()}
            </Typography>
          </Tooltip>
          {actions && <div className={classes.actions}>{actions}</div>}
        </div>
      </Paper>
    </Grid>
  );
}
