import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React, { useState } from "react";

import { useModelPoseImageUrl } from "../../../hooks/api/models";
import useStyles from "./ModelPoseImage.styles";

export default function ModelPoseVariantImage(imagePrefix: string) {
  const classes = useStyles();
  const {
    data: imageUrl,
    isLoading: isImageUrlLoading,
    isError: isImageUrlError,
  } = useModelPoseImageUrl(imagePrefix);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  return (
    <>
      {imageUrl && !errorLoadingImage && !isImageUrlError && (
        <img
          src={imageUrl}
          className={classnames(classes.thumbnail, {
            [classes.showThumbnail]: imageHasLoaded,
          })}
          alt={imagePrefix}
          onLoad={() => setImageHasLoaded(true)}
          onError={() => setErrorLoadingImage(true)}
        />
      )}
      {(!imageUrl || errorLoadingImage || isImageUrlError) && (
        <div
          className={classnames(classes.thumbnail, classes.missingThumbnail)}
        >
          {!isImageUrlLoading && (
            <Typography variant="caption" display="block" color="textSecondary">
              no thumbnail
            </Typography>
          )}
        </div>
      )}
    </>
  );
}
