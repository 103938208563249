import red from "@material-ui/core/colors/red";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  root: pageContentContainer(theme),
  videoContainer: {
    margin: "0 auto",
    maxWidth: "40rem",
  },
  contentContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px 0`,
    margin: "0 auto",
    maxWidth: "32rem",
    marginBottom: theme.spacing(5),
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  failedRequestMessage: {
    marginBottom: theme.spacing(2),
  },
  inputs: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  agreement: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3.5),
  },
  agreementLink: {
    color: "inherit",
    textDecoration: "underline",
  },
  agreementError: {
    color: red[500],
  },
  submitButton: {
    margin: `0 0 ${theme.spacing(5)}px`,
  },
  alreadyHaveAccount: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    backgroundColor: "#f4f6f8",
    margin: `0 -${theme.spacing(3)}px`,
    padding: `${theme.spacing(5)}px 0`,
  },
  alreadyHaveAccountLink: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0.5),
    fontWeight: 500,
  },
  videoHeader: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  videoMessage: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
}));
