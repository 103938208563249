import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExtensionIcon from "@material-ui/icons/Extension";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useEffect } from "react";

import { usePluginChangelog } from "../../../hooks/api/plugin";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import useStyles from "./DownloadPluginPage.styles";
import VersionSection from "./VersionSection";

export default function DownloadPluginPage() {
  const classes = useStyles();
  const { data: changelogData } = usePluginChangelog();

  useEffect(() => {
    document.title = "EcoShot Image Hub - Download Plugin";
  }, []);

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <ExtensionIcon fontSize="small" />
            <Typography variant="h6">
              Download EcoShot Assets for VStitcher
            </Typography>
          </div>
        </div>
      </Paper>
      {!changelogData ? (
        <PageLoadingIndicator />
      ) : (
        <div className={classes.contentContainer}>
          <Paper className={classes.contentSection}>
            <div className={classes.buttonsContainer}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.sectionHeader}
              >
                Documentation
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://help.ecoshotstudio.com/en/collections/7196314-ecoshot-user-guide-2023"
                  )
                }
              >
                Support site and Videos
              </Button>
            </div>
          </Paper>
          <Paper className={classes.contentSection}>
            <div className={classes.buttonsContainer}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.sectionHeader}
              >
                Plugin
              </Typography>
              {/* <Button
                endIcon={<GetAppIcon />}
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/app/ecoshot-installer/id6450179002"
                  )
                }
              >
                Installer for Mac
              </Button> */}
              {/* <Button
                endIcon={<GetAppIcon />}
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.open(
                    "https://apps.microsoft.com/store/detail/ecoshot-installer/XPDP0X1559GXF1"
                  )
                }
              >
                Installer for Windows
              </Button> */}
              {/* <Typography className={classes.contentSection}>
                Can't use the installers? Manual installation of the plugin is
                also possible:
              </Typography> */}
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open("/dashboard/download-direct-plugin", "_self")
                }
              >
                Manual Plugin Download
              </Button>
            </div>
          </Paper>

          <Paper className={classes.contentSection}>
            <div className={classes.buttonsContainer}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.sectionHeader}
              >
                Lighting and Camera
              </Typography>
              <Button
                endIcon={<GetAppIcon />}
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.open(
                    "https://downloads.ecoshotstudio.com/assets/EcoShot%20Studio%20v1.hdr"
                  )
                }
              >
                Studio Lighting
              </Button>
              <Button
                endIcon={<GetAppIcon />}
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.open(
                    "https://downloads.ecoshotstudio.com/assets/EcoShot%20Camera.vcampos"
                  )
                }
              >
                Camera
              </Button>
            </div>
          </Paper>
          <Paper className={classes.contentSection}>
            <div className={classes.contentSectionInner}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.sectionHeader}
              >
                Changelog
              </Typography>
              {changelogData.Versions.map((version) => (
                <div key={version.Version} className={classes.versionContainer}>
                  <VersionSection {...version} />
                </div>
              ))}
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}
