import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classnames from "classnames";
import React, { ChangeEvent, ReactNode } from "react";

import useStyles from "./Checkbox.styles";

interface IProps {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  label: ReactNode;
}

export default function Checkbox({
  className = "",
  disabled,
  checked,
  onChange,
  label,
}: IProps) {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classnames(classes.root, {
        [className]: className,
      })}
      disabled={disabled}
      control={<MuiCheckbox checked={checked} onChange={onChange} />}
      label={label}
    />
  );
}
