import grey from "@material-ui/core/colors/grey";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    width: 230,
    "@media only screen and (min-width: 750px)": {
      width: 180,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    [theme.breakpoints.up("lg")]: {
      width: 230,
    },
  },
  accountDetails: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },
  avatarContainer: {
    marginBottom: theme.spacing(0.5),
  },
  avatar: {
    fontSize: "3.2rem",
    marginLeft: -4,
    display: "block",
    color: grey[600],
  },
  fullName: {
    marginBottom: theme.spacing(1),
  },
  groupName: {
    color: theme.palette.secondary.dark,
    lineHeight: 1.4,
  },
  groupType: {
    fontSize: "0.775rem",
    lineHeight: 1.3,
  },
  listItem: {
    boxShadow: `inset 4px 0 0 0 transparent`,
  },
  listItemSelected: {
    boxShadow: `inset 4px 0 0 0 ${theme.palette.secondary.light}`,
  },
  listIcon: {
    minWidth: 40,
  },
  badge: {
    marginRight: theme.spacing(1),
    marginTop: -2,
  },
  selectGroupButtom: {
    marginTop: 10,
  },
}));
