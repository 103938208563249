import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { useAlert } from "../../../context/AlertContext";
import { useError } from "../../../context/ErrorContext";
import { useInviteMember } from "../../../hooks/api/groups";
import useStateReducer from "../../../hooks/useStateReducer";
import { GroupInvitation, GroupMember } from "../../../types/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import Checkbox from "../../components/Checkbox/Checkbox";
import EmailInput from "../../components/EmailInput/EmailInput";
import InputTitle from "../../components/InputTitle/InputTitle";
import {
  MemberPermission,
  memberPermissionLabelsToInteger,
} from "../../utils/memberPermissions";
import useStyles from "./InviteMemberButton.styles";

interface IProps {
  groupMembers: Array<GroupMember>;
  groupInvitations: Array<GroupInvitation>;
}

export default function InviteMemberButton({
  groupMembers,
  groupInvitations,
}: IProps) {
  const classes = useStyles();
  const { addError } = useError();
  const { addAlert } = useAlert();
  const initialState = {
    email: "",
    isEmailValid: false,
    permissions: new Set<MemberPermission>(),
    showInputErrorMessages: false,
    isDialogOpen: false,
  };
  const [state, setState] = useStateReducer(initialState);
  const closeDialog = () => setState(initialState);
  const openDialog = () => setState({ isDialogOpen: true });
  const { mutate: doInviteMember, isLoading: isInvitingMember } =
    useInviteMember();

  const inviteEmails =
    groupInvitations?.map((invitation) => invitation.InviteeEmail.S) ?? [];
  const memberEmails = groupMembers?.map((member) => member.Email) ?? [];

  const inviteMember = async () => {
    if (isInvitingMember) {
      return;
    }
    const { email, isEmailValid, permissions } = state;
    if (!isEmailValid) {
      setState({ showInputErrorMessages: true });
      return;
    }
    setState({ showInputErrorMessages: false });
    if (inviteEmails.includes(email)) {
      addAlert({ message: `An invite already exists for ${email}.` });
    } else if (memberEmails.includes(email)) {
      addAlert({ message: `${email} is already a member of this group.` });
    } else {
      await doInviteMember(
        { email, permissions: memberPermissionLabelsToInteger(permissions) },
        {
          onSettled: closeDialog,
          onError: (response) => {
            if (response.response.status === 409) {
              addAlert({
                title: "Could not send invite",
                message: `${email} has already been invited to a group so cannot be invited to your group at this time.`,
              });
            } else {
              addError({
                message: `We could not invite ${email} at this time.`,
              });
            }
          },
        }
      );
    }
  };

  const createCheckbox = ({
    permission,
    label,
  }: {
    permission: MemberPermission;
    label: string;
  }) => {
    const { permissions } = state;
    return (
      <Checkbox
        label={label}
        checked={permissions.has(permission)}
        onChange={() => {
          if (permissions.has(permission)) {
            permissions.delete(permission);
            setState({ permissions });
          } else {
            setState({ permissions: permissions.add(permission) });
          }
        }}
      />
    );
  };

  return (
    <>
      <Button
        endIcon={<PersonAddIcon />}
        className={classes.inviteButton}
        variant="contained"
        color="secondary"
        onClick={openDialog}
      >
        Invite member
      </Button>
      <Dialog open={state.isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Invite member</DialogTitle>
        <DialogContent>
          <EmailInput
            onChange={(email, isEmailValid) =>
              setState({ email, isEmailValid })
            }
            value={state.email}
            className={classes.emailInput}
            showError={state.showInputErrorMessages}
          />
          <InputTitle title="Permissions" />
          <FormControl component="fieldset">
            <FormGroup>
              {createCheckbox({
                permission: "group_admin",
                label: "Group Admin",
              })}
              {createCheckbox({
                permission: "request_ecoshot",
                label: "Request EcoShot",
              })}
              {createCheckbox({
                permission: "request_retouch",
                label: "Request Retouch",
              })}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <ButtonWithProgress
            variant="text"
            onClick={inviteMember}
            label="Invite"
            isLoading={isInvitingMember}
            spinnerPosition="end"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
