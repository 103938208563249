import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";

import { useError } from "../../../context/ErrorContext";
import { useRenameImageProfile } from "../../../hooks/api/imageProfiles";
import useUser from "../../../hooks/useUser";
import { ImageProfile } from "../../../types/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import TextInput from "../../components/TextInput/TextInput";
import useStyles from "./ImageProfileItem.styles";

interface IProps {
  item: ImageProfile;
}

export default function ImageProfileItem({ item }: IProps) {
  const classes = useStyles();
  const { addError } = useError();
  const { userIsSuperUser } = useUser();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [profileName, setProfileName] = useState(item.Name.S);
  const {
    mutate: doRenameImageProfile,
    isLoading: isImageProfileBeingRenamed,
  } = useRenameImageProfile();

  useEffect(() => {
    setProfileName(item.Name.S);
  }, [item.Name.S]);

  const onCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const onRenameClicked = () => {
    doRenameImageProfile(
      {
        imageProfileId: item.ImageProfileId.S,
        groupId: item.GroupId.S,
        oldName: item.Name.S,
        newName: profileName,
      },
      {
        onSettled: () => setIsEditDialogOpen(false),
        onError: () =>
          addError({
            message: "We couldn't rename your image profile.",
          }),
      }
    );
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography>{item.Name.S}</Typography>
        {userIsSuperUser ? (
          <Typography variant="body2" color={"textSecondary"} noWrap>
            Read-Only
          </Typography>
        ) : (
          <IconButton onClick={() => setIsEditDialogOpen(true)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      <div>
        {item.Options.L.map((option) => (
          <Typography key={option.S} variant="body2" color="textSecondary">
            {option.S}
          </Typography>
        ))}
      </div>
      <Dialog open={isEditDialogOpen} onClose={onCloseEditDialog}>
        <DialogContent>
          <TextInput
            title="Profile Name"
            onChange={(name) => setProfileName(name)}
            value={profileName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseEditDialog}>Cancel</Button>
          <ButtonWithProgress
            label="Rename"
            onClick={onRenameClicked}
            isLoading={isImageProfileBeingRenamed}
            variant="text"
            spinnerPosition="end"
          />
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
