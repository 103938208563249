import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import useStateReducer from "../../../hooks/useStateReducer";
import { LOGIN_PAGE_ROUTE } from "../../../Routes";
import FailedAccountsRequestMessage from "../../components/FailedAccountsRequestMessage/FailedAccountsRequestMessage";
import FullWidthButtonWithProgress from "../../components/FullWidthButtonWithProgress/FullWidthButtonWithProgress";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import RequestNewVerificationCodeLink from "../../components/RequestNewVerificationCodeLink/RequestNewVerificationCodeLink";
import VerificationCodeInput from "../../components/VerificationCodeInput/VerificationCodeInput";
import useStyles from "./ResetPasswordPage.styles";

type IState = {
  verificationCode: string;
  isVerificationCodeValid: boolean;
  password: string;
  isPasswordValid: boolean;
  showPassword: boolean;
  showInputErrorMessages: boolean;
};

interface LocationState {
  email?: string;
}

export default function ResetPasswordPage() {
  const { forgotPasswordSubmit } = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    mutate: doForgotPasswordSubmit,
    isLoading: isSubmitting,
    error: submitError,
  } = useMutation<
    void,
    Error,
    { email: string; code: string; password: string }
  >(forgotPasswordSubmit);

  const initialFormState = {
    verificationCode: "",
    isVerificationCodeValid: false,
    password: "",
    isPasswordValid: false,
    showPassword: false,
    showInputErrorMessages: false,
  };
  const [formState, setFormState] = useStateReducer<IState>(initialFormState);
  const [showPasswordField, setShowPasswordField] = useState(false);

  useEffect(() => {
    document.title = "EcoShot Image Hub - Reset Password";
  }, []);

  const email = (location.state as LocationState)?.email;

  if (!email) {
    return <Navigate to={LOGIN_PAGE_ROUTE} replace />;
  }

  const onSubmitSuccess = () => navigate(LOGIN_PAGE_ROUTE);

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    const {
      verificationCode,
      isVerificationCodeValid,
      password,
      isPasswordValid,
    } = formState;
    if (!isVerificationCodeValid || !isPasswordValid) {
      setFormState({ showInputErrorMessages: true });
    } else {
      setFormState({ showInputErrorMessages: false });
      await doForgotPasswordSubmit(
        {
          email,
          code: verificationCode,
          password,
        },
        {
          onSuccess: onSubmitSuccess,
        }
      );
    }
  };

  const onSendNewCodeSuccess = () =>
    setFormState({ verificationCode: "", isVerificationCodeValid: false });

  const handleChange = (
    verificationCode: string,
    isVerificationCodeValid: boolean
  ) => {
    const isVerificationFieldEmpty = verificationCode === "" ? false : true;
    setFormState({ verificationCode, isVerificationCodeValid });
    setShowPasswordField(isVerificationFieldEmpty);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography variant="h4" component="h1" gutterBottom>
            Reset your password
          </Typography>
        </div>
        <Typography variant="body1" component="h2">
          We've emailed you your 6-digit verification code, please enter it
          along with your new password.
        </Typography>
        {submitError && (
          <FailedAccountsRequestMessage
            className={classes.failedRequestMessage}
            headline={submitError.message}
          />
        )}
        <div className={classes.inputs}>
          <VerificationCodeInput
            showTitle
            onChange={handleChange}
            value={formState.verificationCode}
            className={classes.verificationCodeInput}
            showError={formState.showInputErrorMessages}
          />
          {showPasswordField && (
            <PasswordInput
              title="New password"
              onChange={(password, isPasswordValid) =>
                setFormState({ password, isPasswordValid })
              }
              onShowPasswordClick={() =>
                setFormState({ showPassword: !formState.showPassword })
              }
              showPassword={formState.showPassword}
              newPassword
              value={formState.password}
              showError={formState.showInputErrorMessages}
            />
          )}
        </div>
        <FullWidthButtonWithProgress
          className={classes.submitButton}
          label="Submit"
          onClick={onSubmit}
          isLoading={isSubmitting}
        />
        <RequestNewVerificationCodeLink
          type="forgot-password"
          email={email}
          onSuccess={onSendNewCodeSuccess}
        />
      </Paper>
    </div>
  );
}
