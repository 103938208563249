import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useNavigate } from "react-router-dom";

import { useError } from "../../../context/ErrorContext";
import { useNotifications } from "../../../context/NotificationContext";
import {
  useAcceptUserInvite,
  useDeclineUserInvite,
  useUserInvites,
} from "../../../hooks/api/user";
import useUser from "../../../hooks/useUser";
import { ECOSHOTS_PAGE_ROUTE } from "../../../Routes";
import { UserInvitation } from "../../../types/core";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import useStyles from "./InvitesPage.styles";

export default function InvitesPage() {
  const classes = useStyles();
  const { addNotification } = useNotifications();
  const { addError } = useError();
  const { userGroupId } = useUser();
  const navigate = useNavigate();
  const { data: invites, isLoading: areInvitesLoading } = useUserInvites();
  const { mutate: doDeclineInvite, isLoading: isDecliningInvite } =
    useDeclineUserInvite();
  const { mutate: doAcceptInvite, isLoading: isAcceptingInvite } =
    useAcceptUserInvite();
  const isRespondingToInvite = isDecliningInvite || isAcceptingInvite;

  const declineInvite = (invite: UserInvitation) => {
    if (isRespondingToInvite) {
      return;
    }
    doDeclineInvite({ groupId: invite.GroupId, inviteId: invite.InviteId });
  };

  const acceptInvite = (invite: UserInvitation) => {
    if (isRespondingToInvite) {
      return;
    }
    doAcceptInvite(
      { groupId: invite.GroupId, inviteId: invite.InviteId },
      {
        onSuccess: () => {
          addNotification(
            `You are now a member of the ${invite.GroupName} group`
          );
          navigate(ECOSHOTS_PAGE_ROUTE);
        },
        onError: () =>
          addError({
            message: `There was a problem adding you to the ${invite.GroupName} group.`,
          }),
      }
    );
  };

  const invitations = invites?.Result.GroupInvites ?? [];

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <MailOutlineIcon fontSize="small" />
            <Typography variant="h6">Invites</Typography>
          </div>
        </div>
      </Paper>
      {areInvitesLoading ? (
        <PageLoadingIndicator />
      ) : (
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {invitations.length > 0 && userGroupId && (
              <Typography
                color="textSecondary"
                variant="body2"
                className={classes.message}
              >
                On accepting an invite, you will be removed from your current
                group.
              </Typography>
            )}
            {invitations.length > 0 ? (
              invitations.map((invite) => (
                <Card key={invite.InviteId}>
                  <CardContent>
                    <Typography component="p">
                      You have been invited to join the{" "}
                      <span className={classes.groupName}>
                        {invite.GroupName}
                      </span>{" "}
                      group
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Sent by {invite.InviteeEmail}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => declineInvite(invite)}>
                      Decline
                    </Button>
                    <Button size="small" onClick={() => acceptInvite(invite)}>
                      Accept
                    </Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <Typography color="textSecondary">
                You do not have any invites to respond to
              </Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
