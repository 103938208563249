import { Box, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FaceIcon from "@material-ui/icons/Face";

import { useModelPosesManifest } from "../../../hooks/api/models";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import VariantImage from "./VariantImage";
import useStyles from "./VariantsPage.styles";

export default function VariantsPage() {
  const classes = useStyles();
  const { data: manifest, isLoading: isManifestLoading } =
    useModelPosesManifest();

  if (isManifestLoading || !manifest) {
    return <PageLoadingIndicator />;
  }

  const sortedModels = manifest.models.sort((a, b) => {
    if (a.model > b.model) {
      return 1;
    } else if (a.model < b.model) {
      return -1;
    } else {
      return 0;
    }
  });

  const listModels = sortedModels.map((model) => {
    const images = model.images.map((image) => {
      const prefix = image.image.split("/").slice(0, -1).join("/");
      const pose = image.image.split("/").slice(-1)[0].split(".")[0];
      const vars = Object.keys(image.variants);
      const variants = vars.flatMap((category) => {
        return image.variants[category].map((varName) => {
          const s3Key = `${prefix}/${category}/${pose}-${varName}.jpg`;
          return (
            <VariantImage
              s3Key={s3Key}
              label={varName}
              express={false}
              preview={false}
            />
          );
          //return <img src={s3Key} className={classes.thumbnail} alt={varName} />;
          //return <div>{s3Key}</div>;
        });
      });
      return (
        <Box display="flex" flexWrap="wrap">
          <VariantImage
            s3Key={image.image}
            label={""}
            express={image.express}
            preview={image.preview}
          />
          {variants}
        </Box>
      );
    });
    return (
      <Paper key={model.model} className={classes.modelContainer}>
        <Typography variant="h6" component="h3">
          {model.model}
        </Typography>
        <div className={classes.posesContainer}>{images}</div>
      </Paper>
    );
  });

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <FaceIcon fontSize="small" />
            <Typography variant="h6">Styling Options</Typography>
          </div>
        </div>
        <div className={classes.headerContainer}>
          <div>
            <Typography variant="caption">
              Available options for the model photographs - including shoes,
              hairstyles, pairing garments and glasses.
            </Typography>
          </div>
        </div>
      </Paper>
      <div className={classes.contentContainer}>
        <div>{listModels}</div>
      </div>
    </div>
  );
}
