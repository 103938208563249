import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./SectionLoadingIndicator.styles";

export default function SectionLoadingIndicator() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
}
