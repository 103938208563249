import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React, { useState } from "react";

import { useModelPoseImageUrl } from "../../../hooks/api/models";
import { ImageKey } from "../../../types/core";
import useStyles from "./ModelPoseImage.styles";
import ModelPoseVariantImage from "./ModelPoseVariantImage";

interface IProps {
  imageKey: ImageKey;
  showShoes: boolean;
}

const BurntOrangeTextTypography = withStyles({
  root: {
    color: "#BD5D00",
  },
})(Typography);

const CyanTextTypography = withStyles({
  root: {
    color: "#00c4c4",
  },
})(Typography);

export default function ModelPoseImage({ imageKey, showShoes }: IProps) {
  const classes = useStyles();
  const {
    data: imageUrl,
    isLoading: isImageUrlLoading,
    isError: isImageUrlError,
  } = useModelPoseImageUrl(imageKey.image);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);

  const modelPrefix = imageKey.image.split("/").slice(0, -1).join("/");
  const modelPoseName = imageKey.image.split("/").slice(-1)[0].split(".")[0];
  const shoeArray = imageKey.variants.shoes ?? [];
  const shoeVariants = shoeArray.map((variant) => {
    const variantName = variant
      .replace("-", " ")
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
    const variantPrefix = `${modelPrefix}/shoes/${modelPoseName}-${variant}.jpg`;
    return (
      <div key={`${variantPrefix}-item`}>
        <Tooltip
          title={ModelPoseVariantImage(variantPrefix)}
          arrow
          placement="right"
        >
          <ListItem>
            <ListItemText
              classes={{ primary: classes.variantText }}
              primary={variantName}
            />
          </ListItem>
        </Tooltip>
        <Divider />
      </div>
    );
  });
  return (
    <>
      {imageUrl && !errorLoadingImage && !isImageUrlError && (
        <Box display="inline-flex">
          <Paper
            className={classnames(classes.imageContainer, {
              [classes.expressContainer]: imageKey.express,
              [classes.standardContainer]:
                !imageKey.express && !imageKey.preview,
              [classes.previewContainer]: imageKey.preview && !imageKey.express,
            })}
            elevation={0}
          >
            {imageKey.express && imageHasLoaded && (
              <BurntOrangeTextTypography className={classes.expressOverlay}>
                Express
              </BurntOrangeTextTypography>
            )}
            {!imageKey.express && imageKey.preview && imageHasLoaded && (
              <CyanTextTypography className={classes.previewOverlay}>
                Preview
              </CyanTextTypography>
            )}
            <img
              src={imageUrl}
              className={classnames(classes.thumbnail, {
                [classes.showThumbnail]: imageHasLoaded,
              })}
              alt={imageKey.image}
              onLoad={() => setImageHasLoaded(true)}
              onError={() => setErrorLoadingImage(true)}
            />
          </Paper>

          {showShoes && (
            <Paper elevation={0}>
              <List className={classes.variantContainer} dense>
                {(imageKey.variants.shoes ?? []).length !== 0 && (
                  <>
                    <Divider />
                    {shoeVariants}
                  </>
                )}
                {(imageKey.variants.shoes ?? []).length === 0 && (
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                    className={classes.noVariantContainer}
                  >
                    Ask us!
                  </Typography>
                )}
              </List>
            </Paper>
          )}
        </Box>
      )}
      {(!imageUrl || errorLoadingImage || isImageUrlError) && (
        <div
          className={classnames(classes.thumbnail, classes.missingThumbnail)}
        >
          {!isImageUrlLoading && (
            <Typography variant="caption" display="block" color="textSecondary">
              no thumbnail
            </Typography>
          )}
        </div>
      )}
    </>
  );
}
