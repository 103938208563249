import Grid from "@material-ui/core/Grid";

import InfiniteJobListing from "./InfiniteJobListing";
import JobItem from "./JobItem";

export default function CompletedJobs() {
  return (
    <InfiniteJobListing
      jobStatus="ImageRequestsProcessing"
      noJobsMessage="No processing jobs to show"
      listingContent={({ jobs }) => (
        <Grid container spacing={2}>
          {jobs.map((job) => (
            <JobItem key={job.ImageRequestId.S} job={job} />
          ))}
        </Grid>
      )}
    />
  );
}
