import makeStyles from "@material-ui/core/styles/makeStyles";

import { textEllipsis } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  header: {
    fontSize: "0.55rem",
    textTransform: "uppercase",
    letterSpacing: "0.4px",
    textAlign: "center",
    padding: "1px 0",
  },
  headerRetouchedEcoshot: {
    backgroundColor: "#bd5d00",
  },
  headerEcoshot: {
    backgroundColor: theme.palette.secondary.main,
  },
  thumbnailContainer: {
    backgroundColor: "#efefef",
    width: "100%",
    height: 150,
  },
  contentContainer: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
  },
  title: {
    minHeight: theme.spacing(5),
    ...textEllipsis(2),
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(1.25),
    marginLeft: -2,
    "& > *": {
      marginRight: "0.5rem",
    },
  },
}));
