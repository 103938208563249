import { useAuth } from "../context/AuthContext";
import getUserRoles from "../views/utils/getUserRoles";

export default function useUser() {
  const { user, credentials } = useAuth();
  const userIsVerified = user?.attributes?.email_verified;
  const userPayload = user?.signInUserSession?.idToken?.payload;
  const userGidClaim = userPayload?.["gid"];
  const cognitoGroups = userPayload?.["cognito:groups"];
  const userGroupId =
    userGidClaim ??
    (cognitoGroups && cognitoGroups.length > 0 ? cognitoGroups[0] : null);
  const userIntercomHash = userPayload?.["ecoshot:intercomHash"];

  const userPermissionsNum = userPayload?.["custom:permissions"] ?? 0;
  const roles = getUserRoles(userPermissionsNum);
  const userCanRequestEcoshot = roles.has("request_ecoshot");
  const userCanRequestRetouch = roles.has("request_retouch");
  const userIsGroupAdmin = roles.has("group_admin");
  const userIsSuperUser = roles.has("super_user");

  return {
    user,
    credentials,
    userIsVerified,
    userGroupId,
    userCanRequestEcoshot,
    userCanRequestRetouch,
    userIsGroupAdmin,
    userIsSuperUser,
    userIntercomHash,
  };
}
