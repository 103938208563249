import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    display: "none",
    "@media only screen and (min-width: 750px)": {
      display: "block",
    },
  },
  toolbar: theme.mixins.toolbar,
}));
