export type MemberPermission =
  | "group_admin"
  | "request_retouch"
  | "request_ecoshot"
  | "super_user";

type MemberPermissionData = {
  key: MemberPermission;
  label: string;
  value: number;
};

// note we ignore 'superuser' here as that's a special case
const MEMBER_PERMISSIONS: Array<MemberPermissionData> = [
  {
    key: "request_ecoshot",
    label: "Request EcoShot",
    value: 2,
  },
  {
    key: "request_retouch",
    label: "Request Retouch",
    value: 4,
  },
  {
    key: "group_admin",
    label: "Group Admin",
    value: 8,
  },
];

function memberPermissionLabels(
  permissions: Set<MemberPermission> = new Set<MemberPermission>()
) {
  if (permissions.size === 0) {
    return "No permissions granted";
  }
  return MEMBER_PERMISSIONS.map((perm) =>
    permissions.has(perm.key) ? perm.label : null
  )
    .filter(Boolean)
    .join(", ");
}

function memberPermissionLabelsToInteger(
  permissions: Set<MemberPermission> = new Set<MemberPermission>()
) {
  let num = 0;
  MEMBER_PERMISSIONS.forEach((permission) => {
    if (permissions.has(permission.key)) {
      num += permission.value;
    }
  });
  return num;
}

export {
  MEMBER_PERMISSIONS,
  memberPermissionLabels,
  memberPermissionLabelsToInteger,
};
