import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AddAPhoto";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useError } from "../../../context/ErrorContext";
import { useGroupData } from "../../../hooks/api/groups";
import {
  useRequestRetouch,
  useUploadAttachment,
} from "../../../hooks/api/jobs";
import useUser from "../../../hooks/useUser";
import InvertedColourTheme from "../../../InvertedColourTheme";
import { Job } from "../../../types/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import useStyles from "./RequestRetouchDialog.styles";

interface IProps {
  job: Job;
  isOpen: boolean;
  onClose: () => void;
}

export default function RequestRetouchDialog({ job, isOpen, onClose }: IProps) {
  const classes = useStyles();
  const { addError } = useError();
  const { user } = useUser();
  const userId = user.attributes.sub;
  const [requirements, setRequirements] = useState("");
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [isAttachFileDialogOpen, setIsAttachFileDialogOpen] = useState(false);
  const [uuid] = useState(uuidv4());

  const { mutate: doRequestRetouch, isLoading: isRequestingRetouch } =
    useRequestRetouch();

  const { data: groupData } = useGroupData();
  const uploadBucket = groupData?.Item.GroupUploadsBucket.S;

  const createUploadedFileKey = (file: File) => {
    return `user/${userId}/attachment/${uuid}/${file.name}`;
  };

  const onSendRequest = () => {
    doRequestRetouch(
      {
        imageRequestId: job.ImageRequestId.S,
        details: requirements,
        uploadedFileKey: attachedFile
          ? createUploadedFileKey(attachedFile)
          : null,
        bucket: uploadBucket,
      },
      {
        onError: (error) => {
          let message = "We could not request the Retouch at this time.";
          const errorCode = error.response.data.Error.Code;
          if (errorCode === "INSUFFICIENT_CREDITS") {
            message = "Your group has no image package with sufficient credits";
          }
          addError({
            message,
          });
        },
        onSuccess: () => closeDialog(),
      }
    );
  };

  const { mutate: doAttachFile } = useUploadAttachment();

  const onAttachFile = (file: File) => {
    if (!uploadBucket) {
      return null;
    }
    doAttachFile(
      { file, bucket: uploadBucket, fileKey: createUploadedFileKey(file) },
      {
        onError: () => {
          addError({
            message: "Your attachment could not be uploaded at this time.",
          });
        },
        onSuccess: (response) => {
          setAttachedFile(file);
          setIsAttachFileDialogOpen(false);
        },
      }
    );
  };

  const closeDialog = () => {
    setRequirements("");
    setAttachedFile(null);
    onClose();
  };

  const disableSendButton = !requirements;

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      scroll="paper"
      classes={{
        paper: classes.dialogWrapper,
      }}
    >
      <DialogTitle>Optional Advanced Retouching</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Please enter any advanced requirements not covered by the standard
          fixes or common requests.
        </Typography>
        <Typography gutterBottom>
          Note - advanced retouching requests will be credited against your paid
          image allocation.
        </Typography>
        <TextField
          className={classes.textarea}
          color="secondary"
          label="Enter requirements"
          type="text"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={requirements}
          onChange={(evt) => setRequirements(evt.target.value)}
        />
        {uploadBucket && (
          <div className={classes.attachFileContainer}>
            <Button
              endIcon={<AttachFileIcon />}
              variant="contained"
              color="secondary"
              onClick={() => setIsAttachFileDialogOpen(true)}
            >
              Attach File
            </Button>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.attachedFileName}
            >
              {attachedFile?.name}
            </Typography>
            <ThemeProvider theme={InvertedColourTheme}>
              <DropzoneDialog
                filesLimit={1}
                open={isAttachFileDialogOpen}
                acceptedFiles={["image/*"]}
                onClose={() => setIsAttachFileDialogOpen(false)}
                onSave={(files) => {
                  if (files.length > 0) {
                    onAttachFile(files[0]);
                  }
                }}
                showPreviews={false}
                showPreviewsInDropzone
              />
            </ThemeProvider>
          </div>
        )}
        <Typography className={classes.turnaroundMessage}>
          Metail aim to return images requiring advanced retouching{" "}
          <b>within 2 UK working days</b>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isRequestingRetouch} onClick={closeDialog}>
          Cancel
        </Button>
        <ButtonWithProgress
          variant="text"
          onClick={onSendRequest}
          label="Send request"
          disabled={disableSendButton}
          isLoading={isRequestingRetouch}
          spinnerPosition="end"
        />
      </DialogActions>
    </Dialog>
  );
}
