import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface IAlert {
  message: string;
  title?: string;
}

interface IAlertContext {
  alert: IAlert | null;
  addAlert: (alert: IAlert) => void;
  removeAlert: () => void;
}

const defaultValue = {};
const AlertContext = createContext<IAlertContext>(
  defaultValue as IAlertContext
);

function AlertProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const [alert, setAlert] = useState<IAlert | null>(null);

  const removeAlert = useCallback(() => setAlert(null), []);

  const addAlert = useCallback(
    ({ title = "", message }) => setAlert({ title, message }),
    []
  );

  const value = useMemo(
    () => ({
      alert,
      addAlert,
      removeAlert,
    }),
    [alert, addAlert, removeAlert]
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
}

function useAlert() {
  const context = useContext(AlertContext);
  if (context === defaultValue) {
    throw new Error(
      "useAlert hook must be used within a AlertProvider component"
    );
  }
  return context;
}

export { AlertProvider, useAlert };
