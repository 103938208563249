import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  thumbnail: {
    display: "none",
    width: 140,
    height: 210,
    backgroundColor: "#efefef",
    margin: theme.spacing(0.5),
  },
  bigThumbnail: {
    width: 200,
    height: 300,
  },
  showThumbnail: {
    display: "block",
  },
  missingThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    marginLeft: theme.spacing(0.25),
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  variantContainer: {
    width: 130,
    maxHeight: 210,
    overflow: "auto",
    scrollbarWidth: "thin",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(0.5),
  },
  variantText: {
    fontSize: "0.9em",
  },
  standardContainer: {
    backgroundColor: "#ffffff",
  },
  previewContainer: {
    backgroundColor: "#00c4c4",
  },
  textOverlay: {
    fontSize: "small",
    position: "absolute",
    top: "4pt",
    left: 0,
    right: 0,
    textAlign: "center",
    textShadow: "0 0 3px white",
  },
  expressContainer: {
    backgroundColor: "#BD5D00",
  },
  noVariantContainer: {
    marginLeft: theme.spacing(1.5),
  },
}));
