import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface IErrorData {
  message: string;
  details?: string;
}

interface IErrorContext {
  error: IErrorData | null;
  addError: (errorData: IErrorData) => void;
  removeError: () => void;
}

const defaultValue = {};
const ErrorContext = createContext<IErrorContext>(
  defaultValue as IErrorContext
);

function ErrorProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const [error, setError] = useState<IErrorData | null>(null);

  const removeError = useCallback(() => setError(null), []);

  const addError = useCallback((errorData) => setError(errorData), []);

  const value = useMemo(
    () => ({
      error,
      addError,
      removeError,
    }),
    [error, addError, removeError]
  );

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
}

function useError() {
  const context = useContext(ErrorContext);
  if (context === defaultValue) {
    throw new Error(
      "useError hook must be used within a ErrorProvider component"
    );
  }
  return context;
}

export { ErrorProvider, useError };
