import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import useStyles from "./FullWidthButtonWithProgress.styles";

interface IProps {
  label: string;
  className?: string;
  onClick: () => void;
  isLoading: boolean;
}

export default function FullWidthButtonWithProgress({
  label,
  className,
  onClick,
  isLoading,
}: IProps) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={onClick}
        disabled={isLoading}
      >
        {label}
      </Button>
      {isLoading && (
        <LinearProgress className={classes.progress} color="secondary" />
      )}
    </div>
  );
}
