import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useQuery } from "react-query";

import { PluginChangelog } from "../../types/core";
import useFetchClient from "../useFetchClient";
import useS3Client from "../useS3Client";

export function usePluginChangelog() {
  const s3Client = useS3Client();
  const fetchClient = useFetchClient();
  return useQuery<PluginChangelog>({
    queryKey: ["plugin-changelog"],
    queryFn: async () => {
      const preSignedUrl = await getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: "plugin/changelog2.json",
        })
      );
      return fetchClient(preSignedUrl);
    },
  });
}

export function usePluginChangelogV3() {
  const s3Client = useS3Client();
  const fetchClient = useFetchClient();
  return useQuery<PluginChangelog>({
    queryKey: ["plugin-changelog"],
    queryFn: async () => {
      const preSignedUrl = await getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: "plugin/changelog3.json",
        })
      );
      return fetchClient(preSignedUrl);
    },
  });
}

export function usePluginDownloadUrl(version?: string) {
  const s3Client = useS3Client();
  return useQuery<string>({
    queryKey: ["plugin-download-url", version],
    queryFn: () => {
      return getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: `plugin/EcoShot-${version}.zip`,
        })
      );
    },
    enabled: Boolean(version),
  });
}

export function usePluginDownloadUrlV3(version?: string) {
  const s3Client = useS3Client();
  return useQuery<string>({
    queryKey: ["plugin-download-url", version],
    queryFn: () => {
      return getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: `plugin/EcoShot-${version}.zip`,
        })
      );
    },
    enabled: Boolean(version),
  });
}
