import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExtensionIcon from "@material-ui/icons/Extension";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useEffect } from "react";

import {
  usePluginChangelog,
  usePluginDownloadUrl,
} from "../../../hooks/api/plugin";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import VideoSection from "../../components/VideoSection/VideoSection";
import useStyles from "./DownloadDirectPluginPage.styles";

export default function DownloadDirectPluginPage() {
  const classes = useStyles();
  const { data: changelogData } = usePluginChangelog();
  const latestVersion = changelogData?.Versions[0].Version;
  const { data: pluginDownloadUrl } = usePluginDownloadUrl(latestVersion);

  useEffect(() => {
    document.title = "EcoShot Image Hub - Download Plugin";
  }, []);

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <ExtensionIcon fontSize="small" />
            <Typography variant="h6">Direct Download</Typography>
          </div>
        </div>
      </Paper>
      {!changelogData ? (
        <PageLoadingIndicator />
      ) : (
        <div className={classes.contentContainer}>
          <Paper className={classes.contentSection}>
            <div className={classes.buttonsContainer}>
              <Typography
                style={{ fontWeight: 600 }}
                className={classes.contentSection}
              >
                Note that we recommend using the Windows or MacOS installers
                when installing the EcoShot plugin.
              </Typography>
              <Typography className={classes.contentSection}>
                Using the installers will mean that your plugin will
                automatically stay up to date and there will be no need to
                manually install the EcoShot plugin every time there is an
                update.
              </Typography>
              <Typography className={classes.contentSection}>
                If you are unable to use the installers, you can download the
                latest version of the EcoShot plugin below:
              </Typography>
              {pluginDownloadUrl && (
                <Button
                  endIcon={<GetAppIcon />}
                  variant="contained"
                  color="secondary"
                  onClick={() => window.open(pluginDownloadUrl)}
                >
                  Download latest version ({latestVersion})
                </Button>
              )}
              <Typography className={classes.contentSection}>
                Please install the plugin into VStitcher manually. The video
                below shows a walkthrough of how to do this, or you can reach us
                on the Image Hub instant chat.
              </Typography>
            </div>
            <div>
              <VideoSection url="https://www.youtube.com/watch?v=4h9OkR2joH0&t=113s" />
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}
