import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  root: {
    display: "flex",
  },
  bullet: {
    flexShrink: 0,
    flexGrow: 0,
    width: 48,
  },
  content: {
    flexShrink: 1,
    flexGrow: 1,
  },
}));
