import { S3 } from "@aws-sdk/client-s3";

import useUser from "./useUser";

export default function useS3Client() {
  const { credentials } = useUser();
  return new S3({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: credentials ?? undefined,
  });
}
