import { ReactNode } from "react";

import useStyles from "./BulletPoint.styles";

interface IProps {
  bullet: ReactNode;
  children: ReactNode;
}

export default function BulletPoint({ bullet, children }: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.bullet}>{bullet}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
