import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import useStateReducer from "../../../hooks/useStateReducer";
import {
  LOGIN_PAGE_ROUTE,
  POST_REGISTRATION_PAGE_ROUTE,
} from "../../../Routes";
import Checkbox from "../../components/Checkbox/Checkbox";
import CompanyNameInput from "../../components/CompanyNameInput/CompanyNameInput";
import EmailInput from "../../components/EmailInput/EmailInput";
import FailedAccountsRequestMessage from "../../components/FailedAccountsRequestMessage/FailedAccountsRequestMessage";
import FullnameInput from "../../components/FullnameInput/FullnameInput";
import FullWidthButtonWithProgress from "../../components/FullWidthButtonWithProgress/FullWidthButtonWithProgress";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
// import VideoSection from "../../components/VideoSection/VideoSection";
import useStyles from "./RegisterPage.styles";

export default function RegisterPage() {
  const { signup } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    mutate: doSignup,
    isLoading: isSubmitting,
    error: signupError,
  } = useMutation<
    void,
    Error,
    { email: string; password: string; name: string; companyName: string }
  >(signup);

  useEffect(() => {
    document.title = "EcoShot Image Hub - Register";
  }, []);

  const [formState, setFormState] = useStateReducer({
    name: "",
    isNameValid: false,
    email: "",
    isEmailValid: false,
    companyName: "",
    isCompanyNameValid: false,
    password: "",
    isPasswordValid: false,
    showPassword: false,
    confirmPassword: "",
    isConfirmPasswordValid: false,
    showConfirmPassword: false,
    showInputErrorMessages: false,
    hasCheckedAgreement: false,
  });

  const onSubmitSuccess = () => {
    navigate(POST_REGISTRATION_PAGE_ROUTE, {
      state: { email: formState.email },
    });
  };

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    const {
      name,
      isNameValid,
      email,
      isEmailValid,
      companyName,
      isCompanyNameValid,
      password,
      isPasswordValid,
      isConfirmPasswordValid,
      hasCheckedAgreement,
    } = formState;
    if (
      !isNameValid ||
      !isEmailValid ||
      !isCompanyNameValid ||
      !isPasswordValid ||
      !isConfirmPasswordValid ||
      !hasCheckedAgreement
    ) {
      setFormState({ showInputErrorMessages: true });
    } else {
      setFormState({ showInputErrorMessages: false });
      await doSignup(
        { email, password, name, companyName },
        {
          onSuccess: onSubmitSuccess,
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper className={classes.contentContainer}>
            <Typography variant="h4" component="h2" className={classes.header}>
              Sign up free!
            </Typography>
            <Typography component="h4" className={classes.videoHeader}>
              Register for free and gain acess to free trial with 5 images!
            </Typography>
            {signupError && (
              <FailedAccountsRequestMessage
                className={classes.failedRequestMessage}
                headline={signupError.message}
              />
            )}
            <div className={classes.inputs}>
              <FullnameInput
                onChange={(name, isNameValid) =>
                  setFormState({ name, isNameValid })
                }
                value={formState.name}
                className={classes.input}
                showError={formState.showInputErrorMessages}
              />
              <CompanyNameInput
                onChange={(companyName, isCompanyNameValid) =>
                  setFormState({ companyName, isCompanyNameValid })
                }
                value={formState.companyName}
                className={classes.input}
                showError={formState.showInputErrorMessages}
              />
              <EmailInput
                onChange={(email, isEmailValid) =>
                  setFormState({ email, isEmailValid })
                }
                value={formState.email}
                className={classes.input}
                showError={formState.showInputErrorMessages}
                showHelperText
              />

              <PasswordInput
                onChange={(password, isPasswordValid) =>
                  setFormState({ password, isPasswordValid })
                }
                onShowPasswordClick={() =>
                  setFormState({ showPassword: !formState.showPassword })
                }
                showPassword={formState.showPassword}
                value={formState.password}
                showError={formState.showInputErrorMessages}
              />
              <PasswordInput
                onChange={(confirmPassword, isConfirmPasswordValid) =>
                  setFormState({ confirmPassword, isConfirmPasswordValid })
                }
                onShowPasswordClick={() =>
                  setFormState({
                    showConfirmPassword: !formState.showConfirmPassword,
                  })
                }
                showPassword={formState.showConfirmPassword}
                value={formState.confirmPassword}
                isConfirmPassword
                expectedPassword={formState.password}
                showError={formState.showInputErrorMessages}
              />
            </div>
            <Checkbox
              className={classnames(classes.agreement, {
                [classes.agreementError]: formState.showInputErrorMessages,
              })}
              label={
                <Typography variant="body2">
                  By checking this box, you confirm you have read and consent to
                  the{" "}
                  <a
                    className={classes.agreementLink}
                    href="https://tronog.com/pages/ecoshot-services-agreement"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EcoShot Services Agreement
                  </a>
                  and{" "}
                  <a
                    className={classes.agreementLink}
                    href="https://tronog.com/policies/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy and Data Policy
                  </a>
                </Typography>
              }
              checked={formState.hasCheckedAgreement}
              onChange={(evt) =>
                setFormState({ hasCheckedAgreement: evt.target.checked })
              }
            />
            <FullWidthButtonWithProgress
              className={classes.submitButton}
              label="Register"
              onClick={onSubmit}
              isLoading={isSubmitting}
            />
            <Typography variant="body2" className={classes.alreadyHaveAccount}>
              Already have an account?
              <Link
                className={classes.alreadyHaveAccountLink}
                href="#"
                onClick={(evt) => {
                  evt.preventDefault();
                  navigate(LOGIN_PAGE_ROUTE);
                }}
              >
                Log in
              </Link>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div className={classes.videoContainer}>
            <Typography
              variant="h4"
              component="h1"
              className={classes.videoHeader}
            ></Typography>
            <Typography variant="body2" className={classes.videoMessage}>
              No payment required. Upgrade when you're ready. Experience EcoShot
              with a complimentary trial, featuring storage of 5 images for 30
              days and access for 1 user account. Start your free trial today!
            </Typography>
            <Typography variant="body2" className={classes.videoMessage}>
              Explore our EcoShot{" "}
              <a
                href="https://tronog.odoo.com/knowledge/article/135"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Knowledge Database</b>
              </a>{" "}
              and discover how to maximize EcoShot for your advantage and
              troubleshoot any issues effortlessly.
            </Typography>
            <Typography variant="body2" className={classes.videoMessage}>
              Unlock the insights you need to succeed!
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
