import { useUiState } from "../context/UiStateContext";

const uiStateKey = "filter-poses-button";

const defaultFilters = {
  category: null,
  preview: false,
  express: false,
  showShoes: false,
  view: null,
  shoes: null,
};

export type IPosesFilters = {
  category: string | null;
  preview: boolean;
  express: boolean;
  showShoes: boolean;
  view: string | null;
  shoes: string | null;
};

interface IFilterPosesUiState {
  uiState: IPosesFilters;
  numberOfActiveFilters: number;
  setUiState: (updates: Partial<IPosesFilters>) => void;
  resetUiState: () => void;
  defaultFilters: IPosesFilters;
}

export default function useFilterPosesUiState(): IFilterPosesUiState {
  const { uiState, setUiState } = useUiState();
  const filterPosesUiState = uiState[uiStateKey] ?? defaultFilters;
  const numberOfActiveFilters =
    Object.values(filterPosesUiState).filter(Boolean).length;
  return {
    uiState: filterPosesUiState,
    numberOfActiveFilters,
    setUiState: (updates) => setUiState(uiStateKey, updates),
    resetUiState: () => setUiState(uiStateKey, defaultFilters),
    defaultFilters,
  };
}
