import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import useUser from "./hooks/useUser";
import {
  ACCEPTABLE_USE_POLICY_PAGE_ROUTE,
  ACCOUNT_VERIFICATION_PAGE_ROUTE,
  DOWNLOAD_PLUGIN_PAGE_ROUTE,
  ECOSHOTS_COMPLETED_PAGE_ROUTE,
  FORGOT_PASSWORD_PAGE_ROUTE,
  IMAGE_RIGHTS_MANAGEMENT_TEMPLATE_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  POST_REGISTRATION_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
  RESET_PASSWORD_PAGE_ROUTE,
  SERVICES_AGREEMENT_PAGE_ROUTE,
} from "./Routes";
import FullPageErrorFallback from "./views/components/FullPageErrorFallback/FullPageErrorFallback";
import GlobalAlertDialog from "./views/components/GlobalAlertDialog/GlobalAlertDialog";
import GlobalErrorDialog from "./views/components/GlobalErrorDialog/GlobalErrorDialog";
import GlobalNotifications from "./views/components/GlobalNotifications/GlobalNotifications";
import PageHeader from "./views/components/PageHeader/PageHeader";
import AcceptableUsePolicyPage from "./views/screens/AcceptableUsePolicyPage/AcceptableUsePolicyPage";
import AccountVerificationPage from "./views/screens/AccountVerificationPage/AccountVerificationPage";
import Dashboard from "./views/screens/Dashboard/Dashboard";
import ForgotPasswordPage from "./views/screens/ForgotPasswordPage/ForgotPasswordPage";
import ImageRightsManagementTemplatePage from "./views/screens/ImageRightsManagementTemplatePage/ImageRightsManagementTemplatePage";
import LoginPage from "./views/screens/LoginPage/LoginPage";
import PostRegistrationPage from "./views/screens/PostRegistrationPage/PostRegistrationPage";
import RegisterPage from "./views/screens/RegisterPage/RegisterPage";
import ResetPasswordPage from "./views/screens/ResetPasswordPage/ResetPasswordPage";
import ServicesAgreementPage from "./views/screens/ServicesAgreementPage/ServicesAgreementPage";

export default function App() {
  const { user, userIsVerified, userGroupId } = useUser();
  const fallbackRoute = userGroupId
    ? ECOSHOTS_COMPLETED_PAGE_ROUTE
    : DOWNLOAD_PLUGIN_PAGE_ROUTE;

  const RequiresNoAuth = ({ children }: { children: ReactNode }) => {
    if (userIsVerified) {
      return <Navigate to={fallbackRoute} replace />;
    } else if (user && !userIsVerified) {
      return (
        <Navigate
          to={{
            pathname: POST_REGISTRATION_PAGE_ROUTE,
          }}
          state={{ email: user.username }}
          replace
        />
      );
    } else {
      return <>{children}</>;
    }
  };

  const RequiresAuth = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    if (userIsVerified) {
      return <>{children}</>;
    } else if (user && !userIsVerified) {
      return (
        <Navigate
          to={{
            pathname: POST_REGISTRATION_PAGE_ROUTE,
          }}
          state={{ email: user.username }}
          replace
        />
      );
    } else {
      return (
        <Navigate
          to={{
            pathname: LOGIN_PAGE_ROUTE,
          }}
          state={{ referrer: location.pathname }}
          replace
        />
      );
    }
  };

  return (
    <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
      <PageHeader />
      <GlobalAlertDialog />
      <GlobalErrorDialog />
      <GlobalNotifications />
      <Routes>
        {/* General routes*/}
        <Route
          path={ACCEPTABLE_USE_POLICY_PAGE_ROUTE}
          element={<AcceptableUsePolicyPage />}
        />
        <Route
          path={SERVICES_AGREEMENT_PAGE_ROUTE}
          element={<ServicesAgreementPage />}
        />
        <Route
          path={IMAGE_RIGHTS_MANAGEMENT_TEMPLATE_PAGE_ROUTE}
          element={<ImageRightsManagementTemplatePage />}
        />

        {/* Logged in user routes */}
        <Route
          path="/dashboard/*"
          element={
            <RequiresAuth>
              <Dashboard />
            </RequiresAuth>
          }
        />

        {/* Accounts routes*/}
        <Route
          path={ACCOUNT_VERIFICATION_PAGE_ROUTE}
          element={<AccountVerificationPage />}
        />
        <Route
          path={POST_REGISTRATION_PAGE_ROUTE}
          element={<PostRegistrationPage />}
        />
        <Route
          path={FORGOT_PASSWORD_PAGE_ROUTE}
          element={
            <RequiresNoAuth>
              <ForgotPasswordPage />
            </RequiresNoAuth>
          }
        />
        <Route
          path={RESET_PASSWORD_PAGE_ROUTE}
          element={
            <RequiresNoAuth>
              <ResetPasswordPage />
            </RequiresNoAuth>
          }
        />
        <Route
          path={LOGIN_PAGE_ROUTE}
          element={
            <RequiresNoAuth>
              <LoginPage />
            </RequiresNoAuth>
          }
        />
        <Route
          path={REGISTER_PAGE_ROUTE}
          element={
            <RequiresNoAuth>
              <RegisterPage />
            </RequiresNoAuth>
          }
        />
        <Route path="*" element={<Navigate to={fallbackRoute} replace />} />
      </Routes>
    </ErrorBoundary>
  );
}
