import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddProfileIcon from "@material-ui/icons/AddToPhotos";
import React, { ChangeEvent, useEffect, useState } from "react";

import { useCreateImageProfile } from "../../../hooks/api/imageProfiles";
import useStateReducer from "../../../hooks/useStateReducer";
import useUser from "../../../hooks/useUser";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import Checkbox from "../../components/Checkbox/Checkbox";
import TextInput from "../../components/TextInput/TextInput";
import useStyles from "./AddImageProfileButton.styles";

type IState = {
  RemoveLogos: string | null;
  RemoveVisibleUnderwear: string | null;
  Background: string | null;
  Resolution: string | null;
  Comments: string | null;
};

export default function AddImageProfileButton() {
  const classes = useStyles();
  const { userGroupId } = useUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [profileName, setProfileName] = useState("");
  const {
    mutate: doCreateImageProfile,
    isLoading: isImageProfileBeingCreated,
  } = useCreateImageProfile();

  const [resolution, setResolution] = useState("2896x4344");

  const initialOptions = {
    RemoveLogos: null,
    RemoveVisibleUnderwear: null,
    Background: null,
    Resolution: null,
    Comments: null,
  };

  const [options, setOptions] = useStateReducer<IState>(initialOptions);

  useEffect(() => {
    if (options.Resolution) {
      setOptions({ Resolution: resolution });
    }
  }, [resolution, options.Resolution, setOptions]);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => {
    setOptions(initialOptions);
    setResolution("2896x4344");
    setIsDialogOpen(false);
  };

  const showCommentRequestsTextInput = resolution === "other";

  const onCreateClicked = () => {
    const requestOptions = [
      ...(options.RemoveLogos ? ["Remove logos from shoes"] : []),
      ...(options.RemoveVisibleUnderwear ? ["Remove visible underwear"] : []),
      ...(options.Background ? ["Transparent background"] : []),
      ...(options.Resolution ? [`Resolution: ${resolution}`] : []),
      ...(options.Comments && showCommentRequestsTextInput
        ? [`Comments: ${options.Comments}`]
        : []),
    ];
    doCreateImageProfile(
      {
        groupId: userGroupId,
        name: profileName,
        options: requestOptions,
      },
      { onSettled: closeDialog }
    );
  };

  const onResolutionChanges = (evt: ChangeEvent<HTMLSelectElement>) => {
    setResolution(evt.target.value);
    setOptions((prevState) => {
      prevState.Resolution = resolution;
      return { ...prevState };
    });
  };

  const onRemoveLogoToggled = (evt: ChangeEvent<HTMLInputElement>) => {
    setOptions((prevState) => {
      // NOTE that we're default to from-shoes now, but this value will come from a select later
      prevState.RemoveLogos = evt.target.checked ? "from-shoes" : null;
      return { ...prevState };
    });
  };
  const onRemoveVisibleUnderwearToggled = (
    evt: ChangeEvent<HTMLInputElement>
  ) => {
    setOptions((prevState) => {
      // NOTE that we're default to 'all' now, but this value will come from a select later
      prevState.RemoveVisibleUnderwear = evt.target.checked ? "all" : null;
      return { ...prevState };
    });
  };
  const onBackgroundToggled = (evt: ChangeEvent<HTMLInputElement>) => {
    setOptions((prevState) => {
      // NOTE that we're default to transparent now, but this value will come from an input later
      prevState.Background = evt.target.checked ? "transparent" : null;
      return { ...prevState };
    });
  };
  const onResolutionToggled = (evt: ChangeEvent<HTMLInputElement>) => {
    setOptions((prevState) => {
      prevState.Resolution = evt.target.checked ? resolution : null;
      return { ...prevState };
    });
  };
  const onCommonRequestCommentsChanged = (
    evt: ChangeEvent<HTMLInputElement>
  ) => {
    setOptions((prevState) => {
      prevState.Comments = evt.target.value || null;
      return { ...prevState };
    });
  };

  return (
    <>
      <Button
        endIcon={<AddProfileIcon />}
        className={classes.createButton}
        variant="contained"
        color="secondary"
        onClick={openDialog}
      >
        Create New Profile
      </Button>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Add a new standard processing configuration</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            The manual finishing process creates the highest quality EcoShot
            image.
          </Typography>
          <Typography gutterBottom>
            If you would like additional processing, you can create a profile
            with the following options:
          </Typography>
          <Typography className={classes.sectionHeader}>
            Additional Processing Requests
          </Typography>
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              label="Remove logos on shoes"
              checked={Boolean(options.RemoveLogos)}
              onChange={onRemoveLogoToggled}
            />
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              label="Remove visible underwear"
              checked={Boolean(options.RemoveVisibleUnderwear)}
              onChange={onRemoveVisibleUnderwearToggled}
            />
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              label="Transparent background"
              checked={Boolean(options.Background)}
              onChange={onBackgroundToggled}
            />
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              label="Specific resolution"
              checked={Boolean(options.Resolution)}
              onChange={onResolutionToggled}
            />
            <select
              value={resolution}
              onChange={onResolutionChanges}
              onBlur={onResolutionChanges}
            >
              <option value="1365x2048">1365x2048</option>
              <option value="683x1024">683x1024</option>
              <option value="other">Other (please specify below)</option>
            </select>
          </div>
          {showCommentRequestsTextInput && (
            <TextField
              className={classes.textarea}
              color="secondary"
              label="Enter comments related to the above selections"
              type="text"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={options.Comments ?? ""}
              onChange={onCommonRequestCommentsChanged}
            />
          )}
          <Typography className={classes.turnaroundMessage}>
            Metail target a turnaround time of within <b>1 UK working day</b>{" "}
            for Manual Finish images including this standard processing.
          </Typography>
          <TextInput
            className={classes.profileNameInput}
            title="Profile Name"
            onChange={(name) => setProfileName(name)}
            value={profileName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <ButtonWithProgress
            variant="text"
            onClick={onCreateClicked}
            label="Create New Profile"
            isLoading={isImageProfileBeingCreated}
            spinnerPosition="end"
            disabled={!profileName}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
