import makeStyles from "@material-ui/core/styles/makeStyles";

import { container, pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  headerContainer: container(theme),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  contentContainer: pageContentContainer(theme),
  content: {
    maxWidth: 640,
  },
  buyPackageButton: {
    marginBottom: theme.spacing(4),
  },
  tableContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(4),
  },
  table: {
    "& .MuiTableHead-root *": {
      backgroundColor: "#e8e8e8",
    },
    "& .MuiTableRow-root": {
      "&:nth-of-type(even)": {
        backgroundColor: "#f8f8f8",
      },
    },
  },
}));
