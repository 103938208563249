import {
  GetItemCommandOutput,
  QueryCommand,
  QueryCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { API } from "aws-amplify";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ImageProfile } from "../../types/core";
import useDynamoDbClient from "../useDynamoDbClient";
import { IJobsFilters } from "../useFilterJobsUiState";
import useUser from "../useUser";

type ImageProfilesQueryCommandOutput = Omit<QueryCommandOutput, "Items"> & {
  Items?: Array<ImageProfile>;
};

export function useImageProfiles(groupSelect?: IJobsFilters | null) {
  const dynamoDbClient = useDynamoDbClient();
  const {
    userIsGroupAdmin,
    userGroupId,
    userCanRequestEcoshot,
    userIsSuperUser,
  } = useUser();
  const groupId = userIsSuperUser
    ? groupSelect?.group || userGroupId
    : userGroupId;
  return useQuery<ImageProfilesQueryCommandOutput>({
    queryKey: ["image-profiles", groupId],
    queryFn: () => {
      const params = {
        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
        KeyConditionExpression: "PK = :pk and begins_with(SK, :sk)",
        ExpressionAttributeValues: {
          ":pk": {
            S: `group:${groupId}`,
          },
          ":sk": { S: "imgprof:" },
        },
      };
      return dynamoDbClient.send(
        new QueryCommand(params)
      ) as Promise<ImageProfilesQueryCommandOutput>;
    },
    enabled: Boolean(
      userIsGroupAdmin || userCanRequestEcoshot || userIsSuperUser
    ),
  });
}

type UseCreateImageProfileResponse = GetItemCommandOutput & {
  OK: boolean;
  Result: {
    ImageProfileId: string;
  };
};

export function useCreateImageProfile() {
  const queryClient = useQueryClient();
  return useMutation<
    UseCreateImageProfileResponse,
    Error,
    {
      groupId: string;
      name: string;
      options: Array<string>;
    }
  >(
    ({ groupId, name, options }) =>
      API.post("API", `/`, {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "CreateImageProfile",
          Payload: {
            GroupId: `group:${groupId}`,
            Name: name,
            Options: options,
          },
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("image-profiles");
      },
    }
  );
}

type UseRenameImageProfileResponse = GetItemCommandOutput & {
  OK: boolean;
  Result: Record<string, never>;
};

export function useRenameImageProfile() {
  const queryClient = useQueryClient();
  return useMutation<
    UseRenameImageProfileResponse,
    Error,
    {
      imageProfileId: string;
      groupId: string;
      oldName: string;
      newName: string;
    }
  >(
    ({ imageProfileId, groupId, oldName, newName }) =>
      API.post("API", `/`, {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "RenameImageProfile",
          Payload: {
            GroupId: groupId,
            ImageProfileId: imageProfileId,
            OldName: oldName,
            NewName: newName,
          },
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("image-profiles");
      },
    }
  );
}
