import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import PackagesIcon from "@material-ui/icons/BusinessCenter";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { useEffect } from "react";

import { useError } from "../../../context/ErrorContext";
import { useGroupMembers } from "../../../hooks/api/groups";
import {
  useGetImagePackageLineItems,
  useImagePackages,
} from "../../../hooks/api/imagePackages";
import useFilterJobsUiState from "../../../hooks/useFilterJobsUiState";
import useUser from "../../../hooks/useUser";
import { ImagePackage } from "../../../types/core";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import useStyles from "./PackagesPage.styles";

export default function PackagesPage() {
  const classes = useStyles();
  const { addError } = useError();
  const { userIsSuperUser } = useUser();
  const { uiState: groupSelect } = useFilterJobsUiState();
  const { data: imagePackages, isLoading: areImagePackagesLoading } =
    useImagePackages(userIsSuperUser ? groupSelect : null);
  const {
    mutate: doGetImagePackageLineItems,
    isLoading: isGettingImagePackageLineItems,
  } = useGetImagePackageLineItems(userIsSuperUser ? groupSelect : null);
  const { data: groupMembers } = useGroupMembers(
    userIsSuperUser ? groupSelect.group : null
  );

  useEffect(() => {
    document.title = "EcoShot Image Hub - Packages";
  }, []);

  const packageItems = imagePackages?.Items ?? [];

  const downloadImagePackageLineItems = (imagePackageId: string) => {
    if (isGettingImagePackageLineItems) {
      return null;
    }
    doGetImagePackageLineItems(
      {
        imagePackageId,
      },
      {
        onSuccess: (response) => {
          const data = response.Items?.[0]?.LineItems.L.map((item) => {
            return [
              item.M.ImageName.S.replace(/#/g, "_"),
              groupMembers?.Result.Members.find(
                (member) => member.UserId === item.M.RequestedBy.S
              )?.Name ?? "",
              item.M.RequestedTimestamp.S,
              item.M.RequestId.S.startsWith("imgreq") ? "EcoShot" : "Retouch",
              item.M.Refund?.BOOL ? "Refund" : "Charge",
            ];
          });
          if (data) {
            const dataWithTitle = [
              [
                "Image Name",
                "Requested By",
                "Requested Timestamp",
                "Request Type",
                "Charge / Refund",
              ],
              ...data,
            ];
            const csvContent =
              "data:text/csv;charset=utf-8," +
              dataWithTitle.map((row) => row.join(",")).join("\n");
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "image_package.csv");
            document.body.appendChild(link);
            link.click();
          } else {
            addError({
              message: `We can't get the line items for this package.`,
            });
          }
        },
        onError: () =>
          addError({
            message: `We can't get the line items for this package.`,
          }),
      }
    );
  };

  const PackagesTable = ({
    packages,
    isExpiredTable,
  }: {
    packages: Array<ImagePackage>;
    isExpiredTable?: boolean;
  }) => {
    return (
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Package</TableCell>
              <TableCell align="right">Images Available</TableCell>
              <TableCell align="right">Activated</TableCell>
              <TableCell align="right">
                {isExpiredTable ? "Expired" : "Expires"}
              </TableCell>
              <TableCell align="right">Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map(
              ({
                PackageName,
                ImagePackId,
                ImageQuota,
                ImagesRemaining,
                ActivatedTimestamp,
                ExpiresTimestamp,
              }) => {
                return (
                  <TableRow key={ImagePackId.S}>
                    <TableCell component="th" scope="row">
                      {PackageName.S}
                    </TableCell>
                    <TableCell align="right">
                      {ImageQuota.N && ImagesRemaining.N
                        ? `${Math.floor(ImagesRemaining.N / 2)}
                            /${ImageQuota.N / 2}`
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      {ActivatedTimestamp &&
                        moment(ActivatedTimestamp.S).format("MMM D YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      {ExpiresTimestamp &&
                        moment(ExpiresTimestamp.S).format("MMM D YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() =>
                          ImagePackId.S &&
                          downloadImagePackageLineItems(ImagePackId.S)
                        }
                        disabled={isGettingImagePackageLineItems}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  let content;
  if (areImagePackagesLoading) {
    content = <PageLoadingIndicator />;
  } else if (packageItems.length === 0) {
    content = (
      <Typography color="textSecondary">
        You have not yet bought any image packages
      </Typography>
    );
  } else {
    const momentNow = moment();
    const expiredOrFinishedPackages = packageItems
      .filter(
        (packageItem) =>
          (packageItem.ExpiresTimestamp &&
            moment(packageItem.ExpiresTimestamp.S) <= momentNow) ||
          packageItem.ImagesRemaining.N < 2
      )
      .sort((a, b) => {
        if (
          !a.ExpiresTimestamp ||
          (b.ExpiresTimestamp && a.ExpiresTimestamp.S > b.ExpiresTimestamp.S)
        ) {
          return -1;
        } else {
          return 1;
        }
      });
    const activeOrUnstartedPackages = packageItems
      .filter(
        (packageItem) =>
          (!packageItem.ExpiresTimestamp ||
            (packageItem.ExpiresTimestamp &&
              moment(packageItem.ExpiresTimestamp.S) > momentNow)) &&
          packageItem.ImagesRemaining.N >= 2
      )
      .sort((a, b) => {
        if (
          a.ExpiresTimestamp &&
          b.ExpiresTimestamp &&
          a.ExpiresTimestamp.S > b.ExpiresTimestamp.S
        ) {
          return -1;
        } else {
          return 1;
        }
      });

    content = (
      <>
        <Typography>
          Please click on the <b>Activity</b> download link to view detailed
          usage for any given package
        </Typography>
        <br></br>
        <Typography>
          <i>Active and purchased packages</i>
        </Typography>
        <PackagesTable packages={activeOrUnstartedPackages} />
        <Typography>
          <i>Expired and exhausted packages</i>
        </Typography>
        <PackagesTable packages={expiredOrFinishedPackages} isExpiredTable />
      </>
    );
  }
  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <PackagesIcon fontSize="small" />
            <Typography variant="h6">Packages</Typography>
          </div>
        </div>
      </Paper>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {!userIsSuperUser && (
            <Button
              className={classes.buyPackageButton}
              variant="contained"
              color="secondary"
              onClick={() => window.open("https://tronog.com/pages/ecoshot")}
            >
              Buy Package
            </Button>
          )}
          {content}
        </div>
      </div>
    </div>
  );
}
