import TextField from "@material-ui/core/TextField";
import { useMutation } from "react-query";

import { useAuth } from "../../../context/AuthContext";
import { useError } from "../../../context/ErrorContext";
import { useNotifications } from "../../../context/NotificationContext";
import useStateReducer from "../../../hooks/useStateReducer";
import useUser from "../../../hooks/useUser";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import CompanyNameInput from "../../components/CompanyNameInput/CompanyNameInput";
import FullnameInput from "../../components/FullnameInput/FullnameInput";
import InputTitle from "../../components/InputTitle/InputTitle";
import useStyles from "./AccountDetails.styles";

type IState = {
  fullname: string;
  isFullnameValid: boolean;
  companyName: string;
  isCompanyNameValid: boolean;
  showInputErrorMessages: boolean;
  userHasChangedAValue: boolean;
};

export default function AccountDetails() {
  const { updateUserAttributes } = useAuth();
  const { user } = useUser();
  const { addNotification } = useNotifications();
  const { addError } = useError();
  const classes = useStyles();
  const { mutate: doUpdateUserAttributes, isLoading: isUpdating } = useMutation<
    void,
    Error,
    { name: string; "custom:company_name": string }
  >(updateUserAttributes);
  const [
    {
      fullname,
      isFullnameValid,
      companyName,
      isCompanyNameValid,
      showInputErrorMessages,
      userHasChangedAValue,
    },
    setState,
  ] = useStateReducer<IState>({
    fullname: user.attributes.name,
    isFullnameValid: true,
    companyName: user.attributes["custom:company_name"] ?? "",
    isCompanyNameValid: true,
    showInputErrorMessages: false,
    userHasChangedAValue: false,
  });

  const setFormState = (newValues: Partial<IState>) =>
    setState({ ...newValues, userHasChangedAValue: true });

  const onSaveClicked = async () => {
    if (isUpdating) {
      return;
    }
    if (!isFullnameValid || !isCompanyNameValid) {
      setFormState({ showInputErrorMessages: true });
    } else {
      setFormState({ showInputErrorMessages: false });
      await doUpdateUserAttributes(
        { name: fullname, "custom:company_name": companyName },
        {
          onSuccess: () => addNotification("Your profile has been updated"),
          onError: () =>
            addError({
              message: `We encountered a problem saving your changes.`,
            }),
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <InputTitle title="Email" />
        <TextField
          disabled
          color="secondary"
          variant="outlined"
          margin="normal"
          fullWidth
          autoComplete="username"
          value={user.attributes.email}
        />
      </div>
      <div className={classes.itemContainer}>
        <FullnameInput
          className={classes.input}
          value={fullname}
          onChange={(fullname, isFullnameValid) =>
            setFormState({ fullname, isFullnameValid })
          }
          showError={showInputErrorMessages}
        />
        <CompanyNameInput
          className={classes.input}
          value={companyName}
          onChange={(companyName, isCompanyNameValid) =>
            setFormState({ companyName, isCompanyNameValid })
          }
          showError={showInputErrorMessages}
        />
      </div>
      <ButtonWithProgress
        label="Save"
        isLoading={isUpdating}
        onClick={onSaveClicked}
        disabled={!userHasChangedAValue}
      />
    </div>
  );
}
