import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

export default responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: "#bef536",
      },
    },
    overrides: {
      MuiDialog: {
        paper: {
          minWidth: 340,
        },
      },
    },
  })
);
