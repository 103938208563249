import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    backgroundColor: "#fafafa",
    paddingTop: theme.spacing(4),
  },
  content: {
    maxWidth: 560,
    padding: theme.spacing(2),
    margin: "0 auto",
    "& pre": {
      whiteSpace: "pre-wrap",
    },
  },
}));
