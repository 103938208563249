import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    "& .MuiFormControlLabel-label": {
      display: "flex",
      alignItems: "center",
      lineHeight: 1.2,
      padding: "11px 0",
    },
  },
}));
