import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect } from "react";

import useStateReducer from "../../../hooks/useStateReducer";
import AccountDetails from "./AccountDetails";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import useStyles from "./ProfilePage.styles";

type IState = {
  expandedPanel: string | null;
};

export default function ProfilePage() {
  const classes = useStyles();
  const [state, setState] = useStateReducer<IState>({
    expandedPanel: null,
  });

  useEffect(() => {
    document.title = "EcoShot Image Hub - Profile";
  }, []);

  const handleChangePanel =
    (panel: string) => (event: any, isExpanded: boolean) => {
      setState({ expandedPanel: isExpanded ? panel : null });
    };

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <AccountCircleIcon fontSize="small" />
            <Typography variant="h6">Profile</Typography>
          </div>
        </div>
      </Paper>

      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div>
            <Accordion
              expanded={state.expandedPanel === "account-details-panel"}
              onChange={handleChangePanel("account-details-panel")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Account details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccountDetails />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={state.expandedPanel === "change-password-panel"}
              onChange={handleChangePanel("change-password-panel")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Change password</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ChangePassword />
              </AccordionDetails>
            </Accordion>
          </div>
          <Paper className={classes.listSection}>
            <DeleteAccount />
          </Paper>
        </div>
      </div>
    </div>
  );
}
