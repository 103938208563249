import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  inviteButton: {
    marginBottom: theme.spacing(4),
  },
  emailInput: {
    marginBottom: theme.spacing(2),
  },
}));
