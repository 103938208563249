import {
  QueryCommand,
  QueryCommandInput,
  QueryCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { S3 } from "@aws-sdk/client-s3";
import { GetObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { API } from "aws-amplify";
import moment from "moment";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import {
  ErrorWithResponse,
  Job,
  JobStatus,
  RetouchedJob,
} from "../../types/core";
import useDynamoDbClient from "../useDynamoDbClient";
import { IJobsFilters } from "../useFilterJobsUiState";
import useS3Client from "../useS3Client";
import useUser from "../useUser";

type JobsQueryCommandOutput = Omit<QueryCommandOutput, "Items"> & {
  Items?: Array<Job>;
};

function useJobs({
  jobStatus,
  pageSize = 12,
  filters,
}: {
  jobStatus: JobStatus;
  pageSize: number;
  filters: IJobsFilters;
}) {
  const dynamoDbClient = useDynamoDbClient();
  const { userGroupId } = useUser();
  return useInfiniteQuery<JobsQueryCommandOutput, Error>({
    queryKey: ["jobs", userGroupId, jobStatus, pageSize, filters],
    queryFn: ({ pageParam = null }) => {
      const groupId = filters.group || userGroupId;
      const keyConditionExpressionParts = ["GroupId = :gid"];
      const filterExpressionParts = [];
      const expressionAttributeValues: QueryCommandInput["ExpressionAttributeValues"] =
        {
          ":gid": { S: `group:${groupId}` },
        };
      const expressionAttributeNames: QueryCommandInput["ExpressionAttributeNames"] =
        {};
      if (filters.title) {
        expressionAttributeValues[":imageName"] = {
          S: filters.title.toLowerCase(),
        };
        filterExpressionParts.push("contains (ImageNameLower, :imageName)");
      }
      if (filters.startDate && !filters.endDate) {
        expressionAttributeValues[":startDate"] = {
          S: moment(filters.startDate).toISOString(),
        };
        keyConditionExpressionParts.push("CompletedTimestamp > :startDate ");
      }
      if (!filters.startDate && filters.endDate) {
        expressionAttributeValues[":endDate"] = {
          S: moment(filters.endDate).toISOString(),
        };
        keyConditionExpressionParts.push("CompletedTimestamp < :endDate ");
      }
      if (filters.startDate && filters.endDate) {
        expressionAttributeValues[":startDate"] = {
          S: moment(filters.startDate).toISOString(),
        };
        expressionAttributeValues[":endDate"] = {
          S: moment(filters.endDate).toISOString(),
        };
        keyConditionExpressionParts.push(
          "CompletedTimestamp BETWEEN :startDate and :endDate"
        );
      }
      if (filters.submittedBy) {
        expressionAttributeValues[":requestedBy"] = {
          S: filters.submittedBy,
        };
        filterExpressionParts.push("RequestedBy = :requestedBy");
      }
      if (filters.type) {
        expressionAttributeValues[":status"] = {
          S: "Retouch",
        };
        expressionAttributeNames["#status"] = "Status";
        if (filters.type === "Retouch") {
          filterExpressionParts.push("begins_with (#status, :status)");
        } else {
          filterExpressionParts.push("not ( begins_with (#status, :status) )");
        }
      }
      const params: QueryCommandInput = {
        KeyConditionExpression: keyConditionExpressionParts.join(" AND "),
        IndexName: jobStatus,
        ExpressionAttributeValues: expressionAttributeValues,
        ExpressionAttributeNames:
          Object.keys(expressionAttributeNames).length > 0
            ? expressionAttributeNames
            : undefined,
        Select: "ALL_PROJECTED_ATTRIBUTES",
        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
        // we don't want to set a limit if there are any filters applied
        Limit: filterExpressionParts.length === 0 ? pageSize : undefined,
        ScanIndexForward: false,
      };
      if (filterExpressionParts.length > 0) {
        params.FilterExpression = filterExpressionParts.join(" AND ");
      }
      if (pageParam) {
        params.ExclusiveStartKey = pageParam;
      }
      return dynamoDbClient.send(
        new QueryCommand(params)
      ) as Promise<JobsQueryCommandOutput>;
    },
    refetchInterval: 30 * 60 * 1000, // refetch every 30 minutes so the signed thumbnail links don't go stale
    keepPreviousData: true,
    getNextPageParam: (lastPage: any) => {
      if (lastPage?.LastEvaluatedKey) {
        return lastPage.LastEvaluatedKey;
      }
    },
  });
}

function useRefreshJobs() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries("jobs");
}

export function useJobImageUrl(key?: string) {
  const s3Client = useS3Client();
  return useQuery<string>({
    queryKey: ["job-image", key],
    queryFn: () => {
      return getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_CUSTOMER_IMAGES_BUCKET,
          Key: key,
        })
      );
    },
    enabled: Boolean(key),
  });
}

function useGetJobImage() {
  const s3Client = useS3Client();
  return useMutation<string, Error, { key: string; filename: string }>(
    ({ key, filename }) =>
      getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_CUSTOMER_IMAGES_BUCKET,
          Key: key,
          ResponseContentDisposition: `attachment; filename="${encodeURI(
            filename
          )}"; filename*=UTF-8''${encodeURI(filename)}`,
        })
      )
  );
}

type JobRetouchRequestsQueryCommandOutput = Omit<
  QueryCommandOutput,
  "Items"
> & {
  Items?: Array<RetouchedJob>;
};

function useJobRetouchRequests({
  groupId,
  imageRequestId,
  enabled,
}: {
  groupId: string;
  imageRequestId: string;
  enabled: boolean;
}) {
  const dynamoDbClient = useDynamoDbClient();
  return useQuery<JobRetouchRequestsQueryCommandOutput>({
    queryKey: ["job-retouch-requests", groupId, imageRequestId],
    queryFn: () => {
      const params = {
        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
        KeyConditionExpression: "PK = :pk and begins_with(SK, :sk)",
        ExpressionAttributeValues: {
          ":pk": { S: groupId },
          ":sk": { S: `rtreq:${imageRequestId.replace("imgreq:", "")}:` },
        },
      };
      return dynamoDbClient.send(
        new QueryCommand(params)
      ) as Promise<JobRetouchRequestsQueryCommandOutput>;
    },
    enabled,
  });
}

function useRequestRetouch() {
  const refreshJobs = useRefreshJobs();
  const { userGroupId } = useUser();
  return useMutation<
    void,
    ErrorWithResponse,
    {
      imageRequestId: string;
      details: string;
      uploadedFileKey?: string | null;
      bucket?: string | null;
    }
  >(
    ({ imageRequestId, details, uploadedFileKey, bucket }) => {
      return API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "RequestRetouch",
          Payload: {
            GroupId: `group:${userGroupId}`,
            ImageRequestId: imageRequestId,
            Details: details,
            ...(uploadedFileKey && bucket
              ? { Attachments: [{ Bucket: bucket, Key: uploadedFileKey }] }
              : {}),
          },
        },
      });
    },
    {
      onSuccess: () => refreshJobs(),
    }
  );
}

function useReportIssue() {
  const refreshJobs = useRefreshJobs();
  const { userGroupId } = useUser();
  return useMutation<
    void,
    ErrorWithResponse,
    {
      imageRequestId: string;
      details: string;
      uploadedFileKey?: string | null;
      bucket?: string | null;
    }
  >(
    ({ imageRequestId, details, uploadedFileKey, bucket }) => {
      return API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "ReportProblem",
          Payload: {
            GroupId: `group:${userGroupId}`,
            ImageRequestId: imageRequestId,
            Details: details,
            ...(uploadedFileKey && bucket
              ? { Attachments: [{ Bucket: bucket, Key: uploadedFileKey }] }
              : {}),
          },
        },
      });
    },
    {
      onSuccess: () => refreshJobs(),
    }
  );
}

function bucketRegion(bucket: string): string | undefined {
  const re = new RegExp(
    "-((?:us|af|ap|ca|eu|me|sa)-(?:north|northeast|east|southeast|south|southwest|west|northwest)-[0-9]+)$"
  );
  const found = bucket.match(re);
  if (found == null) {
    return process.env.REACT_APP_AWS_REGION;
  }
  return found[1];
}

function useUploadAttachment() {
  const { credentials } = useUser();
  return useMutation<
    any,
    Error,
    { bucket: string; fileKey: string; file: File }
  >(({ bucket, fileKey, file }) => {
    const s3Client = new S3({
      region: bucketRegion(bucket),
      credentials: credentials ?? undefined,
    });
    return s3Client.send(
      new PutObjectCommand({
        Body: file,
        Bucket: bucket,
        Key: fileKey,
      })
    );
  });
}

export {
  useGetJobImage,
  useJobRetouchRequests,
  useJobs,
  useRefreshJobs,
  useReportIssue,
  useRequestRetouch,
  useUploadAttachment,
};
