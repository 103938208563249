import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    width: "50%",
  },
}));
