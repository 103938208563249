import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { ReactNode } from "react";

import useStyles from "./TextAndButtonFormRow.styles";

interface IProps {
  label: string;
  icon: ReactNode;
  onClick: () => void;
  removeHorizontalPadding?: boolean;
}

export default function TextAndButtonFormRow({
  label,
  icon,
  onClick,
  removeHorizontalPadding = false,
}: IProps) {
  const classes = useStyles();
  return (
    <div
      className={classnames(classes.root, {
        [classes.removeHorizontalPadding]: removeHorizontalPadding,
      })}
    >
      <Typography>{label}</Typography>
      <IconButton className={classes.icon} onClick={onClick}>
        {icon}
      </IconButton>
    </div>
  );
}
