import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import useUrlQueryParams from "../../../hooks/useUrlQueryParams";
import { LOGIN_PAGE_ROUTE } from "../../../Routes";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import RequestNewVerificationCodeLink from "../../components/RequestNewVerificationCodeLink/RequestNewVerificationCodeLink";
import useStyles from "./AccountVerificationPage.styles";

export default function AccountVerificationPage() {
  const { confirmSignup } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const { email, code } = useUrlQueryParams();
  const {
    mutate: doConfirmSignup,
    isLoading: isVerificationInProgress,
    error: verificationError,
    isSuccess: isVerificationSuccess,
  } = useMutation<void, Error, { email: string; code: string }>(confirmSignup);

  useEffect(() => {
    doConfirmSignup({ email, code });
  }, [doConfirmSignup, email, code]);

  if (!email || !code) {
    return <Navigate to={LOGIN_PAGE_ROUTE} replace />;
  }

  const onContinue = () => {
    navigate(LOGIN_PAGE_ROUTE);
  };

  if (isVerificationInProgress) {
    return <PageLoadingIndicator />;
  }

  const userAlreadyConfirmed = verificationError?.message.includes(
    "Current status is CONFIRMED"
  );
  const showError = verificationError && !userAlreadyConfirmed;
  const showSuccess = isVerificationSuccess || userAlreadyConfirmed;

  return (
    <div className={classes.root}>
      <Paper className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography variant="h4" component="h1" gutterBottom>
            {showError ? "Problem verifying your account" : "Account verified!"}
          </Typography>
        </div>
        <div className={classes.content}>
          {showError && (
            <Typography variant="body1" component="h2">
              We couldn't verify your account using the details provided. Please
              click the link below to receive a new email and try again.
            </Typography>
          )}
          {showSuccess && (
            <>
              <Typography variant="body1" component="h2">
                Your account has been verified, please continue to log in and
                complete the process.
              </Typography>
              <Button
                className={classes.continueButton}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onContinue}
              >
                Continue
              </Button>
            </>
          )}
        </div>
        {showError && (
          <RequestNewVerificationCodeLink email={email} type="verify-account" />
        )}
      </Paper>
    </div>
  );
}
