// General routes
export const ACCEPTABLE_USE_POLICY_PAGE_ROUTE = "/acceptable-use-policy";
export const SERVICES_AGREEMENT_PAGE_ROUTE = "/services-agreement";
export const IMAGE_RIGHTS_MANAGEMENT_TEMPLATE_PAGE_ROUTE =
  "/image-rights-management-template";

// Accounts routes
export const LOGIN_PAGE_ROUTE = "/login";
export const REGISTER_PAGE_ROUTE = "/register";
export const FORGOT_PASSWORD_PAGE_ROUTE = "/forgot-password";
export const RESET_PASSWORD_PAGE_ROUTE = "/reset-password";
export const ACCOUNT_VERIFICATION_PAGE_ROUTE = "/verify-account";
export const POST_REGISTRATION_PAGE_ROUTE = "/thankyou-for-registering";

// Logged in user routes
export const DASHBOARD_ROUTE = "/dashboard";
export const ECOSHOTS_PAGE_ROUTE = "/dashboard/ecoshots";
export const ECOSHOTS_COMPLETED_PAGE_ROUTE = "/dashboard/ecoshots/completed";
export const ECOSHOTS_PROCESSING_PAGE_ROUTE = "/dashboard/ecoshots/processing";
export const DOWNLOAD_PLUGIN_PAGE_ROUTE = "/dashboard/download-plugin";
export const GROUP_ADMINISTRATION_PAGE_ROUTE =
  "/dashboard/group-administration";
export const GROUP_ADMIN_MEMBERS_PAGE_ROUTE =
  "/dashboard/group-administration/members";
export const GROUP_ADMIN_INVITATIONS_PAGE_ROUTE =
  "/dashboard/group-administration/invitations";
export const IMAGE_PROFILES_PAGE_ROUTE = "/dashboard/image-profiles";
export const INVITES_PAGE_ROUTE = "/dashboard/invites";
export const MODEL_POSES_PAGE_ROUTE = "/dashboard/model-poses";
export const VARIANTS_PAGE_ROUTE = "/dashboard/variants";
export const PACKAGES_PAGE_ROUTE = "/dashboard/packages";
export const PROFILE_PAGE_ROUTE = "/dashboard/profile";
