import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import BulletPoint from "../../components/BulletPoint/BulletPoint";
import useStyles from "./AcceptableUsePolicyPage.styles";

export default function AcceptableUsePolicyPage() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.content}>
        <Typography variant="h5" component="h2" className={classes.heading}>
          EcoShot Acceptable Use Policy
        </Typography>
        <div className={classes.metadata}>
          <Typography color="textSecondary" variant="body2">
            Version 2
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Effective: 20 April 2023
          </Typography>
        </div>
        <div className={classes.mainContent}>
          <Typography variant="body2">
            As the Customer, you may only use the Product for lawful purposes.
            You must not use the Product or any of the materials relating to the
            Product, including without limitation any Supplier Materials, Third
            Party Materials or Combined Materials:
          </Typography>
          <BulletPoint bullet="1">
            <Typography variant="body2">
              In any way that breaches any applicable local, national or
              international law or regulation.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="2">
            <Typography variant="body2">
              In any way that is unlawful or fraudulent or has any unlawful or
              fraudulent purpose or effect.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="3">
            <Typography variant="body2">
              For the purpose of harming or attempting to harm minors in any
              way.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="4">
            <Typography variant="body2">
              In connection with the transmitting any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="5">
            <Typography variant="body2">
              To knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="6">
            <Typography variant="body2">
              In any way that is defamatory, offensive or otherwise and
              comparably objectionable including in connection with material
              which is pornographic, offensive or obscene.
            </Typography>
          </BulletPoint>
          <Typography variant="body2">You also agree:</Typography>
          <BulletPoint bullet="1">
            <Typography variant="body2">
              Not to disassemble, de-compile, reverse engineer or (save as
              provided for at paragraph 5 below) create derivative works based
              on the whole or any part of the Product or the Services nor
              attempt to do any such things.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="2">
            <Typography variant="body2">
              Not to reproduce, duplicate, copy or re-sell any part of our
              Product save as permitted under the terms of the Agreement.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="3">
            <Typography variant="body2">
              Not to access without authority, interfere with, damage or disrupt
              any part of Product.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="4">
            <Typography variant="body2">
              In respect of the Model Images:
            </Typography>
            <BulletPoint bullet="o">
              <Typography variant="body2">
                Not to publish the original undressed Model Images as they are
                provided to you (i.e. before they have been combined with the
                Creative Materials to produce the Combined Materials);
              </Typography>
            </BulletPoint>
            <BulletPoint bullet="o">
              <Typography variant="body2">
                Not to use, publish to alter the Model Images in any way which
                is not expressly permitted in the terms of the Agreement;
              </Typography>
            </BulletPoint>
            <BulletPoint bullet="o">
              <Typography variant="body2">
                Not to use, publish or alter the Model Images in such a way that
                may defame the subject of the image.
              </Typography>
            </BulletPoint>
          </BulletPoint>
          <BulletPoint bullet="5">
            <Typography variant="body2">
              Not to create EcoShot images of Models without clothes or with
              minimal clothing and then combining the images with garment
              renders in order to bypass the standard service and associated
              costs.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="6">
            <Typography variant="body2">
              In respect of the Combined Materials, to amend or alter such
              materials only as strictly necessary for the reasonable commercial
              use (including the addition of a corporate logo, image background
              alterations and image cropping)
            </Typography>
          </BulletPoint>
        </div>
      </Paper>
    </div>
  );
}
