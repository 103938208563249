import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  initialLoadingIndicator: {
    width: "70%",
    margin: `${theme.spacing(16)}px auto 0`,
  },
  actionsContainer: {
    marginTop: theme.spacing(-2),
  },
  actions: {
    backgroundColor: "#fafafa",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actionsWhenSticky: {
    boxShadow: theme.shadows[2],
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  navigation: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1.5),
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  paginationLabel: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  listingContainer: {
    position: "relative",
  },
  loadMoreJobsWaypoint: {
    position: "absolute",
    width: "100%",
    bottom: 600,
    left: 0,
    height: 0,
  },
  progressContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    opacity: 0.65,
  },
  nextPageLoadingContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
