import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { useAlert } from "../../../context/AlertContext";
import { useError } from "../../../context/ErrorContext";
import { useNotifications } from "../../../context/NotificationContext";
import {
  useDeleteMember,
  useUpdateMemberPermissions,
} from "../../../hooks/api/groups";
import useStateReducer from "../../../hooks/useStateReducer";
import useUser from "../../../hooks/useUser";
import { GroupData, GroupMember } from "../../../types/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import Checkbox from "../../components/Checkbox/Checkbox";
import getUserRoles from "../../utils/getUserRoles";
import {
  MemberPermission,
  memberPermissionLabels,
  memberPermissionLabelsToInteger,
} from "../../utils/memberPermissions";
import useStyles from "./MemberItem.styles";

interface IProps {
  group: GroupData;
  member: GroupMember;
  numberOfGroupAdmins: number;
}

export default function MemberItem({
  group,
  member,
  numberOfGroupAdmins,
}: IProps) {
  const classes = useStyles();
  const { addError } = useError();
  const { addNotification } = useNotifications();
  const { addAlert } = useAlert();
  const { userIsSuperUser } = useUser();

  // need to guard against permissions being null
  const memberPermissions = getUserRoles(member.Permissions);

  const [
    {
      menuAnchorEl,
      isEditPermissionsDialogOpen,
      permissionsInDialog,
      isRemoveMemberDialogOpen,
    },
    setState,
  ] = useStateReducer({
    menuAnchorEl: null,
    isEditPermissionsDialogOpen: false,
    permissionsInDialog: memberPermissions,
    isRemoveMemberDialogOpen: false,
  });

  const { mutate: doUpdatePermissions, isLoading: isUpdatingPermissions } =
    useUpdateMemberPermissions();

  const updatePermissions = () => {
    if (isUpdatingPermissions) {
      return;
    }
    doUpdatePermissions(
      {
        userId: member.UserId,
        permissions: memberPermissionLabelsToInteger(permissionsInDialog),
      },
      {
        onSettled: closeEditPermissionsDialog,
        onSuccess: () =>
          addNotification(`Permissions updated for ${member.Email}`),
        onError: () =>
          addError({
            message: `We could not update the permissions for ${member.Email} at this time.`,
          }),
      }
    );
  };
  const closeMenu = () => setState({ menuAnchorEl: null });
  const openMenu = (evt: any) => setState({ menuAnchorEl: evt.currentTarget });

  const closeEditPermissionsDialog = () =>
    setState({
      isEditPermissionsDialogOpen: false,
    });
  const openEditPermissionsDialog = () => {
    if (memberPermissions.has("super_user")) {
      addAlert({
        title: "Cannot edit permissions",
        message:
          "You are unable to edit this member's permissions as they are a site Super user.",
      });
      setState({
        menuAnchorEl: null,
      });
    } else {
      setState({
        isEditPermissionsDialogOpen: true,
        menuAnchorEl: null,
        permissionsInDialog: new Set(memberPermissions),
      });
    }
  };

  const { mutate: doRemoveMember, isLoading: isRemovingMember } =
    useDeleteMember();
  const closeRemoveMemberDialog = () =>
    setState({ isRemoveMemberDialogOpen: false });
  const removeMemberClicked = () => {
    if (isOnlyAdminInTheGroup) {
      addAlert({
        title: "Cannot remove member",
        message:
          "This member is the only admin in the group.  To remove them you must first give another member the Group Admin permission.",
      });
      setState({
        menuAnchorEl: null,
      });
    } else {
      setState({
        isRemoveMemberDialogOpen: true,
        menuAnchorEl: null,
      });
    }
  };
  const confirmRemoveMember = async () => {
    if (isRemovingMember) {
      return;
    }
    await doRemoveMember(
      { userId: member.UserId },
      {
        onSettled: closeRemoveMemberDialog,
        onSuccess: () =>
          addNotification(`Removed ${member.Email} from the group`),
        onError: () =>
          addError({
            message: `We could not remove ${member.Email} from the group at this time.`,
          }),
      }
    );
  };

  const isOnlyAdminInTheGroup =
    memberPermissions.has("group_admin") && numberOfGroupAdmins === 1;

  const createCheckbox = ({
    permission,
    disabled,
    label,
  }: {
    permission: MemberPermission;
    disabled?: boolean;
    label: string;
  }) => (
    <Checkbox
      label={label}
      disabled={disabled}
      checked={permissionsInDialog.has(permission)}
      onChange={() => {
        if (permissionsInDialog.has(permission)) {
          permissionsInDialog.delete(permission);
          setState({ permissionsInDialog });
        } else {
          setState({
            permissionsInDialog: permissionsInDialog.add(permission),
          });
        }
      }}
    />
  );

  return (
    <ListItem key="item">
      <ListItemText
        primary={member.Name}
        secondary={
          <>
            {member.Email}
            <br />
            {memberPermissionLabels(memberPermissions)}
          </>
        }
      />
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={openEditPermissionsDialog}>
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          Edit permissions
        </MenuItem>
        <MenuItem onClick={removeMemberClicked}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Remove member
        </MenuItem>
      </Menu>
      <Dialog
        open={isEditPermissionsDialogOpen}
        onClose={closeEditPermissionsDialog}
      >
        <DialogTitle>Editing permissions for {member.Email}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {createCheckbox({
                permission: "group_admin",
                label: "Group Admin",
                disabled: isOnlyAdminInTheGroup,
              })}
              {createCheckbox({
                permission: "request_ecoshot",
                label: "Request EcoShot",
              })}
              {createCheckbox({
                permission: "request_retouch",
                label: "Request Retouch",
              })}
              {isOnlyAdminInTheGroup && (
                <FormHelperText className={classes.formHelperText}>
                  The 'Group admin' permission cannot be unset for this member
                  as there must be at least one admin in the group.
                </FormHelperText>
              )}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditPermissionsDialog}>Cancel</Button>
          <ButtonWithProgress
            label="Submit"
            onClick={updatePermissions}
            isLoading={isUpdatingPermissions}
            variant="text"
            spinnerPosition="end"
          />
        </DialogActions>
      </Dialog>
      <Dialog open={isRemoveMemberDialogOpen} onClose={closeRemoveMemberDialog}>
        <DialogTitle>Confirm remove member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {member.Email} from the group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveMemberDialog}>Cancel</Button>
          <ButtonWithProgress
            label="Remove"
            onClick={confirmRemoveMember}
            isLoading={isRemovingMember}
            variant="text"
            spinnerPosition="end"
          />
        </DialogActions>
      </Dialog>
      <ListItemSecondaryAction>
        {userIsSuperUser ? (
          <Typography variant="body2" color={"textSecondary"} noWrap>
            Read-Only
          </Typography>
        ) : (
          <IconButton edge="end" onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
