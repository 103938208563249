import MomentUtils from "@date-io/moment";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import moment, { Moment } from "moment";

const pickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#00c4c4",
    },
    secondary: {
      main: "#00c4c4",
    },
  },
});

interface IProps {
  label: string;
  value?: Date;
  onChange: (date: Moment) => void;
  minDate?: Date;
  maxDate?: Date;
}

export default function DatePicker({
  label,
  value,
  onChange,
  minDate,
  maxDate,
}: IProps) {
  return (
    <div>
      <ThemeProvider theme={pickerTheme}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <MuiDatePicker
            margin="normal"
            clearable
            format="DD/MM/yyyy"
            label={label}
            value={value ?? null}
            onChange={(date) => onChange(date as Moment)}
            InputLabelProps={{
              shrink: true,
            }}
            minDate={minDate}
            maxDate={maxDate}
            fullWidth
            disableFuture
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}
