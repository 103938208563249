import Link from "@material-ui/core/Link";
import { useNavigate } from "react-router-dom";

interface IProps {
  className?: string;
  route: string;
  label: string;
}

export default function RouteLink({
  className,
  route,
  label,
  ...linkProps
}: IProps) {
  const navigate = useNavigate();
  return (
    <Link
      {...linkProps}
      className={className}
      href="#"
      onClick={(evt) => {
        evt.preventDefault();
        navigate(route);
      }}
    >
      {label}
    </Link>
  );
}
