import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import moment from "moment";

import { useError } from "../../../context/ErrorContext";
import { useGetJobImage, useJobRetouchRequests } from "../../../hooks/api/jobs";
import useStateReducer from "../../../hooks/useStateReducer";
import useUser from "../../../hooks/useUser";
import { Job } from "../../../types/core";
import JobCard from "../../components/JobCard/JobCard";
import MenuDivider from "../../components/MenuDivider/MenuDivider";
import { downloadUrl } from "../../utils/downloadUrl";
import ReportProblemDialog from "./ReportIssueDialog";
import RequestRetouchDialog from "./RequestRetouchDialog";

interface IProps {
  job: Job;
}

type IState = {
  showRetouchDialog: boolean;
  showReportDialog: boolean;
  menuAnchorEl: Element | null;
};

export default function JobItem({ job }: IProps) {
  const { addError } = useError();
  const { userCanRequestRetouch, userCanRequestEcoshot, userGroupId } =
    useUser();

  const isCompleted = job.Status.S === "Completed";
  const isCompletedRetouch = job.Status.S === "RetouchCompleted";
  const canDownloadEcoShot = isCompleted || job.Status.S.startsWith("Retouch");

  // NOTE we have to check for this as a 'Superuser' is able to view jobs from all groups and a user
  // is only able to request a retouch for their own group
  const userCanRequestRetouchForThisJob =
    userCanRequestRetouch &&
    `group:${userGroupId}` === job.GroupId.S &&
    (isCompletedRetouch || isCompleted);

  const userCanReportIssueForThisJob =
    (userCanRequestRetouch || userCanRequestEcoshot) &&
    `group:${userGroupId}` === job.GroupId.S &&
    (isCompletedRetouch || isCompleted);

  const [state, setState] = useStateReducer<IState>({
    showRetouchDialog: false,
    showReportDialog: false,
    menuAnchorEl: null,
  });
  const { mutate: getJobImage } = useGetJobImage();

  const { data: retouchRequests } = useJobRetouchRequests({
    groupId: job.GroupId.S,
    imageRequestId: job.ImageRequestId.S,
    enabled: Boolean(isCompletedRetouch && state.menuAnchorEl),
  });
  const retouchedJobs = ((retouchRequests?.Items ?? []) as Array<Job>).filter(
    (item) => item.ImageRequestId.S === job.ImageRequestId.S
  );

  const handleCloseMenu = () => setState({ menuAnchorEl: null });

  const handleOpenMenu = (evt: any) =>
    setState({ menuAnchorEl: evt.currentTarget });

  const closeRetouchDialog = () => setState({ showRetouchDialog: false });

  const closeReportDialog = () => setState({ showReportDialog: false });

  const showRetouchDialog = () =>
    setState({ menuAnchorEl: null, showRetouchDialog: true });

  const showReportDialog = () =>
    setState({ menuAnchorEl: null, showReportDialog: true });

  const downloadImageClicked = async (jobToDownload: Job) => {
    const imageKey = jobToDownload.ImageLocation.M.Key.S;
    const fileExtension = imageKey.split(".").pop();
    const downloadFilename = `${jobToDownload.ImageName.S.replaceAll(
      ",",
      ""
    )}.${fileExtension}`;
    await getJobImage(
      {
        key: imageKey,
        filename: downloadFilename,
      },
      {
        onSettled: () => setState({ menuAnchorEl: null }),
        onSuccess: (response) => {
          downloadUrl(response, downloadFilename);
        },
        onError: () =>
          addError({
            message: `We can't download this image right now.`,
          }),
      }
    );
  };

  return (
    <JobCard
      job={job}
      actions={
        (userCanRequestRetouchForThisJob ||
          userCanReportIssueForThisJob ||
          canDownloadEcoShot ||
          retouchedJobs.length > 0) && (
          <>
            <IconButton size="small" onClick={handleOpenMenu}>
              <MoreVertOutlinedIcon fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={state.menuAnchorEl}
              keepMounted
              open={Boolean(state.menuAnchorEl)}
              onClose={handleCloseMenu}
            >
              {userCanRequestRetouchForThisJob && (
                <MenuItem button onClick={showRetouchDialog}>
                  <ListItemIcon>
                    <TouchAppIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Request Retouch</Typography>
                </MenuItem>
              )}
              {userCanRequestRetouchForThisJob && <MenuDivider />}
              {userCanReportIssueForThisJob && (
                <MenuItem button onClick={showReportDialog}>
                  <ListItemIcon>
                    <ReportProblemIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Report Issue</Typography>
                </MenuItem>
              )}
              {userCanReportIssueForThisJob && <MenuDivider />}
              {retouchedJobs.length > 0 &&
                retouchedJobs.map((item) => (
                  <MenuItem button onClick={() => downloadImageClicked(item)}>
                    <ListItemIcon>
                      <SaveAltIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Download Retouch (
                      {moment(item.StatusTimestamp.S).format("lll")})
                    </Typography>
                  </MenuItem>
                ))}
              {canDownloadEcoShot && (
                <MenuItem button onClick={() => downloadImageClicked(job)}>
                  <ListItemIcon>
                    <SaveAltIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Download EcoShot</Typography>
                </MenuItem>
              )}
            </Menu>
            <RequestRetouchDialog
              onClose={closeRetouchDialog}
              isOpen={state.showRetouchDialog}
              job={job}
            />
            <ReportProblemDialog
              onClose={closeReportDialog}
              isOpen={state.showReportDialog}
              job={job}
            />
          </>
        )
      }
    />
  );
}
