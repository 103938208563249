import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./ButtonWithProgress.styles";

interface IProps {
  label: string;
  className?: string;
  onClick: () => void;
  isLoading?: boolean;
  type?: ButtonProps["type"];
  disabled?: boolean;
  variant?: ButtonProps["variant"];
  spinnerPosition?: "start" | "end";
}

export default function ButtonWithProgress({
  label,
  className,
  onClick,
  isLoading,
  type = "button",
  disabled = false,
  variant = "contained",
  spinnerPosition = "start",
}: IProps) {
  const classes = useStyles();
  const spinner = (
    <CircularProgress
      className={classes.progress}
      color="secondary"
      size={20}
    />
  );
  return (
    <div className={`${classes.root} ${className}`}>
      {isLoading && spinnerPosition === "start" && spinner}
      <Button
        variant={variant}
        color="secondary"
        onClick={onClick}
        disabled={isLoading || disabled}
        type={type}
      >
        {label}
      </Button>
      {isLoading && spinnerPosition === "end" && spinner}
    </div>
  );
}
