import { useUiState } from "../context/UiStateContext";

const uiStateKey = "filter-jobs-button";

const defaultFilters = {
  group: null,
  title: null,
  submittedBy: null,
  type: null,
  startDate: null,
  endDate: null,
};

export type IJobsFilters = {
  group: string | null;
  title: string | null;
  submittedBy: string | null;
  type: string | null;
  startDate: Date | null;
  endDate: Date | null;
};

interface IFilterJobsUiState {
  uiState: IJobsFilters;
  numberOfActiveFilters: number;
  setUiState: (updates: Partial<IJobsFilters>) => void;
  resetUiState: () => void;
  defaultFilters: IJobsFilters;
}

export default function useFilterJobsUiState(): IFilterJobsUiState {
  const { uiState, setUiState } = useUiState();
  const filterJobsUiState = uiState[uiStateKey] ?? defaultFilters;
  const numberOfActiveFilters =
    Object.values(filterJobsUiState).filter(Boolean).length;
  return {
    uiState: filterJobsUiState,
    numberOfActiveFilters,
    setUiState: (updates) => setUiState(uiStateKey, updates),
    resetUiState: () => setUiState(uiStateKey, defaultFilters),
    defaultFilters,
  };
}
