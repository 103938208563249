import { GetItemCommandOutput } from "@aws-sdk/client-dynamodb";
import { API } from "aws-amplify";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useAuth } from "../../context/AuthContext";
import { UserInvitation } from "../../types/core";

type UseGroupInvitesResponse = GetItemCommandOutput & {
  OK: boolean;
  Result: {
    GroupInvites: Array<UserInvitation>;
  };
};

function useUserInvites() {
  return useQuery<UseGroupInvitesResponse>({
    queryKey: ["user-invites"],
    queryFn: () =>
      API.post("API", `/`, {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "GetMyGroupInvites",
          Payload: null,
        },
      }),
  });
}

function useAcceptUserInvite() {
  const queryClient = useQueryClient();
  const { refreshCurrentUser } = useAuth();
  return useMutation<void, Error, { groupId: string; inviteId: string }>(
    ({ groupId, inviteId }) =>
      API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "AcceptGroupInvite",
          Payload: {
            GroupId: groupId,
            InviteId: inviteId,
          },
        },
      }),
    {
      onSuccess: () => {
        refreshCurrentUser();
        queryClient.invalidateQueries("user-invites");
      },
    }
  );
}

function useDeclineUserInvite() {
  const queryClient = useQueryClient();
  return useMutation<void, Error, { groupId: string; inviteId: string }>(
    ({ groupId, inviteId }) => {
      return API.post("API", "/", {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          Action: "DeclineGroupInvite",
          Payload: {
            GroupId: groupId,
            InviteId: inviteId,
          },
        },
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries("user-invites"),
    }
  );
}

export { useAcceptUserInvite, useDeclineUserInvite, useUserInvites };
