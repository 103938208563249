import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import React, { useEffect, useState } from "react";

import useFilterPosesUiState, {
  IPosesFilters,
} from "../../../hooks/useFilterPosesUiState";
import useStateReducer from "../../../hooks/useStateReducer";
import { IPoseFilterValues } from "../../../types/core";
import Checkbox from "../../components/Checkbox/Checkbox";
import useStyles from "./FilterPosesButton.styles";

interface IProps {
  filterValues: IPoseFilterValues;
  onFiltersChanged: () => void;
}

export default function FilterPosesButton({
  filterValues,
  onFiltersChanged,
}: IProps) {
  const classes = useStyles();
  const {
    uiState,
    setUiState,
    resetUiState,
    numberOfActiveFilters,
    defaultFilters,
  } = useFilterPosesUiState();
  const [filters, setFilters] = useStateReducer<IPosesFilters>(uiState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPreviewButtonDisabled, setIsPreviewButtonDisabled] = useState(false);

  useEffect(() => {
    setFilters(uiState);
    setIsPreviewButtonDisabled(uiState.express);
  }, [isDialogOpen, setFilters, uiState, setIsPreviewButtonDisabled]);

  const handleOpenDialog = () => setIsDialogOpen(true);

  const handleCloseDialog = () => setIsDialogOpen(false);

  const onApplyClicked = () => {
    handleCloseDialog();
    onFiltersChanged();
    setUiState(filters);
  };

  const clearForm = () => {
    setFilters(defaultFilters);
    setIsPreviewButtonDisabled(false);
  };

  const shoeVariants = filterValues.shoes.filter(
    (shoe) => !shoe.includes("barefoot")
  );

  return (
    <>
      <Button
        size="small"
        startIcon={<FilterListIcon />}
        onClick={handleOpenDialog}
        variant={numberOfActiveFilters > 0 ? "contained" : "outlined"}
        color={numberOfActiveFilters > 0 ? "secondary" : "default"}
      >
        Filters {numberOfActiveFilters > 0 && `(${numberOfActiveFilters})`}
      </Button>
      {numberOfActiveFilters > 0 && (
        <Button
          size="small"
          onClick={() => {
            resetUiState();
            onFiltersChanged();
          }}
        >
          Clear filters
        </Button>
      )}
      <Dialog
        disableBackdropClick
        open={isDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Filter Model Poses</DialogTitle>
        <DialogContent>
          <div>
            <FormControl className={classes.selectContainer}>
              <InputLabel shrink color="secondary">
                Category
              </InputLabel>
              <Select
                color="secondary"
                value={filters.category ?? ""}
                onChange={(evt) =>
                  setFilters({ category: evt.target.value as string })
                }
              >
                <MenuItem className={classes.selectUnsetValue}>All</MenuItem>
                {filterValues.categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.selectContainer}>
              <InputLabel shrink color="secondary">
                View
              </InputLabel>
              <Select
                color="secondary"
                value={filters.view ?? ""}
                onChange={(evt) =>
                  setFilters({ view: evt.target.value as string })
                }
              >
                <MenuItem value="" className={classes.selectUnsetValue}>
                  All
                </MenuItem>
                {filterValues.views.map((view) => (
                  <MenuItem key={view} value={view}>
                    {view}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.selectContainer}>
              <InputLabel shrink color="secondary">
                Shoe Variant
              </InputLabel>
              <Select
                color="secondary"
                value={filters.shoes ?? ""}
                onChange={(evt) =>
                  setFilters({ shoes: evt.target.value as string })
                }
              >
                <MenuItem value="" className={classes.selectUnsetValue}>
                  All
                </MenuItem>
                {shoeVariants.map((shoe) => (
                  <MenuItem key={shoe} value={shoe}>
                    {shoe}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.selectContainer}>
              <Checkbox
                checked={filters.preview}
                label="Preview Available"
                disabled={isPreviewButtonDisabled}
                onChange={(evt) =>
                  setFilters({ preview: evt.target.checked as boolean })
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.selectContainer}>
              <Checkbox
                checked={filters.express}
                label="Express Available"
                onChange={(evt) => {
                  setFilters({
                    express: evt.target.checked as boolean,
                    preview: evt.target.checked as boolean,
                  });
                  setIsPreviewButtonDisabled(evt.target.checked as boolean);
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.selectContainer}>
              <Checkbox
                checked={filters.showShoes}
                label="Show Shoe Variants"
                onChange={(evt) =>
                  setFilters({ showShoes: evt.target.checked as boolean })
                }
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={clearForm}>Clear</Button>
          <div className={classes.rightActions}>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={onApplyClicked}>Apply</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
