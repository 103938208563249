import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";

import {
  useGroupData,
  useGroupMembers,
  useGroups,
} from "../../../hooks/api/groups";
import useFilterJobsUiState, {
  IJobsFilters,
} from "../../../hooks/useFilterJobsUiState";
import useStateReducer from "../../../hooks/useStateReducer";
import useUser from "../../../hooks/useUser";
import { JobStatus } from "../../../types/core";
import DatePicker from "../../components/DatePicker/DatePicker";
import useStyles from "./FilterJobsButton.styles";

interface IProps {
  jobStatus: JobStatus;
  onFiltersChanged: () => void;
}

export default function FilterJobsButton({
  jobStatus,
  onFiltersChanged,
}: IProps) {
  const classes = useStyles();
  const { userGroupId } = useUser();
  const { data: groups } = useGroups();
  const {
    uiState,
    setUiState,
    resetUiState,
    numberOfActiveFilters,
    defaultFilters,
  } = useFilterJobsUiState();
  const [filters, setFilters] = useStateReducer<IJobsFilters>(uiState);
  const { data: groupMembers } = useGroupMembers(filters.group);
  const { data: groupData } = useGroupData();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const groupName = groupData?.Item.GroupName.S;

  useEffect(() => {
    setFilters(uiState);
  }, [isDialogOpen, setFilters, uiState]);

  const handleOpenDialog = () => setIsDialogOpen(true);

  const handleCloseDialog = () => setIsDialogOpen(false);

  const onApplyClicked = () => {
    handleCloseDialog();
    onFiltersChanged();
    setUiState(filters);
  };

  const clearForm = () => setFilters(defaultFilters);

  return (
    <>
      <Button
        size="small"
        startIcon={<FilterListIcon />}
        onClick={handleOpenDialog}
        variant={numberOfActiveFilters > 0 ? "contained" : "outlined"}
        color={numberOfActiveFilters > 0 ? "secondary" : "default"}
      >
        Filters {numberOfActiveFilters > 0 && `(${numberOfActiveFilters})`}
      </Button>
      {numberOfActiveFilters > 0 && (
        <Button
          size="small"
          onClick={() => {
            resetUiState();
            onFiltersChanged();
          }}
        >
          Clear filters
        </Button>
      )}
      <Dialog
        disableBackdropClick
        open={isDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Filter EcoShot Images</DialogTitle>
        <DialogContent>
          <TextField
            color="secondary"
            margin="normal"
            label="Title"
            onChange={(evt) => setFilters({ title: evt.target.value })}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={filters.title ?? ""}
          />
          {groupMembers && groupMembers.Result.Members.length > 0 && (
            <div>
              <FormControl className={classes.selectContainer}>
                <InputLabel shrink color="secondary">
                  Submitted by
                </InputLabel>
                <Select
                  color="secondary"
                  value={filters.submittedBy ?? ""}
                  onChange={(evt) =>
                    setFilters({ submittedBy: evt.target.value as string })
                  }
                >
                  <MenuItem value="" className={classes.selectUnsetValue}>
                    All
                  </MenuItem>
                  {groupMembers.Result.Members.map(({ Email, UserId }) => (
                    <MenuItem key={Email} value={UserId}>
                      {Email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <FormControl className={classes.selectContainer}>
              <InputLabel shrink color="secondary">
                Type
              </InputLabel>
              <Select
                color="secondary"
                value={filters.type ?? ""}
                onChange={(evt) =>
                  setFilters({ type: evt.target.value as string })
                }
              >
                <MenuItem value="" className={classes.selectUnsetValue}>
                  All
                </MenuItem>
                <MenuItem value="Retouch">Has Retouch</MenuItem>
                <MenuItem value="NoRetouch">Does not have Retouch</MenuItem>
              </Select>
            </FormControl>
          </div>
          <DatePicker
            label="Start date"
            value={filters.startDate ?? undefined}
            onChange={(startDate: Moment) =>
              setFilters({ startDate: startDate?.startOf("day").toDate() })
            }
            maxDate={filters.endDate ?? undefined}
          />
          <DatePicker
            label="End date"
            value={filters.endDate ?? undefined}
            onChange={(endDate: Moment) =>
              setFilters({ endDate: endDate?.endOf("day").toDate() })
            }
            minDate={filters.startDate ?? undefined}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={clearForm}>Clear</Button>
          <div className={classes.rightActions}>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={onApplyClicked}>Apply</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
