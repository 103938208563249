import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  releaseHeader: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.secondary.dark,
  },
  releaseDate: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    marginLeft: theme.spacing(1),
  },
  changes: {
    padding: `0 0 0 ${theme.spacing(2)}px`,
  },
}));
