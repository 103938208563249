import makeStyles from "@material-ui/core/styles/makeStyles";

import { container, pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  headerContainer: container(theme),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  contentContainer: pageContentContainer(theme),
  contentSection: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  contentSectionInner: {
    maxWidth: 640,
  },
  buttonsContainer: {
    marginBottom: theme.spacing(2.5),
    "& > button": {
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(3),
  },
  versionContainer: {
    marginBottom: theme.spacing(6),
  },
}));
