import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.5rem 1rem",
    backgroundColor: "#fdf5f5",
    borderTop: "3px solid",
    borderColor: theme.palette.error.main,
    textAlign: "center",
  },
  headline: {
    fontWeight: 500,
  },
}));
