import red from "@material-ui/core/colors/red";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  root: {
    width: "100%",
  },
  icon: {
    color: red[500],
  },
}));
