import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import useStateReducer from "../../../hooks/useStateReducer";
import { RESET_PASSWORD_PAGE_ROUTE } from "../../../Routes";
import EmailInput from "../../components/EmailInput/EmailInput";
import FailedAccountsRequestMessage from "../../components/FailedAccountsRequestMessage/FailedAccountsRequestMessage";
import FullWidthButtonWithProgress from "../../components/FullWidthButtonWithProgress/FullWidthButtonWithProgress";
import useStyles from "./ForgotPasswordPage.styles";

type IState = {
  email: string;
  isEmailValid: boolean;
  showInputErrorMessages: boolean;
};

export default function ForgotPasswordPage() {
  const { forgotPassword } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    mutate: doForgotPassword,
    isLoading: isSubmitting,
    error: forgotPasswordError,
  } = useMutation<void, Error, { email: string }>(forgotPassword);

  const [formState, setFormState] = useStateReducer<IState>({
    email: "",
    isEmailValid: false,
    showInputErrorMessages: false,
  });
  const { email, isEmailValid } = formState;

  const onForgotPasswordSuccess = () => {
    navigate(RESET_PASSWORD_PAGE_ROUTE, {
      state: { email },
    });
  };

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    if (!isEmailValid) {
      setFormState({ showInputErrorMessages: true });
    } else {
      setFormState({ showInputErrorMessages: false });
      await doForgotPassword(
        { email },
        {
          onSuccess: onForgotPasswordSuccess,
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography variant="h4" component="h1" gutterBottom>
            Forgot your password
          </Typography>
        </div>
        <Typography variant="body1" component="h2">
          Enter your email address and we'll send you a code for resetting your
          password.
        </Typography>
        {forgotPasswordError && (
          <FailedAccountsRequestMessage
            className={classes.failedRequestMessage}
            headline={forgotPasswordError.message}
          />
        )}
        <div className={classes.inputs}>
          <EmailInput
            hideTitle
            onChange={(email, isEmailValid) =>
              setFormState({ email, isEmailValid })
            }
            value={formState.email}
            className={classes.emailInput}
            showError={formState.showInputErrorMessages}
          />
        </div>
        <FullWidthButtonWithProgress
          className={classes.submitButton}
          label="Submit"
          onClick={onSubmit}
          isLoading={isSubmitting}
        />
      </Paper>
    </div>
  );
}
