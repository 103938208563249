import React, { useState } from "react";

import TextInput from "../TextInput/TextInput";

interface IProps {
  onChange: (fullname: string, isValid: boolean) => void;
  value: string;
  className?: string;
  showError?: boolean;
}

export default function FullnameInput({
  onChange,
  value,
  className,
  showError,
}: IProps) {
  const invalidEmailMessage = "Please enter your full name";
  const isFullnameValid = (fullname: string) => fullname.length > 1;
  const [errorMessage, setErrorMessage] = useState(invalidEmailMessage);
  const [isCurrentValueValid, setIsCurrentValueValid] = useState(
    isFullnameValid(value)
  );

  const handleChange = (fullname: string) => {
    const valid = isFullnameValid(fullname);
    setErrorMessage(valid ? "" : invalidEmailMessage);
    setIsCurrentValueValid(valid);
    onChange(fullname, valid);
  };

  return (
    <TextInput
      className={className}
      title="Full name"
      onChange={handleChange}
      value={value}
      helperText={showError && errorMessage}
      error={showError && !isCurrentValueValid}
    />
  );
}
