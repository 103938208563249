import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#f4f6f8",
    margin: `0 -${theme.spacing(3)}px`,
    padding: `${theme.spacing(5)}px 0`,
  },
  contentContainer: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: 2,
    right: -24,
  },
  link: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0.5),
    fontWeight: 500,
  },
}));
