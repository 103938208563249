import makeStyles from "@material-ui/core/styles/makeStyles";

import { pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  root: pageContentContainer(theme),
  contentContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px 0`,
    margin: "0 auto",
    maxWidth: "32rem",
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  content: {
    paddingBottom: theme.spacing(4),
  },
  continueButton: {
    marginTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
  },
}));
