import makeStyles from "@material-ui/core/styles/makeStyles";

import { pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  root: pageContentContainer(theme),
  contentContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px 0`,
    margin: "0 auto",
    maxWidth: "32rem",
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  failedRequestMessage: {
    marginBottom: theme.spacing(2),
  },
  errorRouteLink: {
    color: theme.palette.text.primary,
    textDecoration: "underline",
  },
  inputs: {
    marginBottom: theme.spacing(1),
  },
  emailInput: {
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(4)}px`,
  },
  footerLinks: {
    marginBottom: theme.spacing(4),
  },
  footerLink: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    textDecoration: "underline",
  },
  noAccount: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    backgroundColor: "#f4f6f8",
    margin: `0 -${theme.spacing(3)}px`,
    padding: `${theme.spacing(5)}px 0`,
  },
  noAccountLink: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0.5),
    fontWeight: 500,
  },
}));
