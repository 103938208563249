import YouTube, { YouTubeProps } from "react-youtube";

import getYouTubeUrlId from "../../utils/getYouTubeUrlId";
import useStyles from "./VideoSection.styles";

interface IProps {
  url?: string;
}

export default function VideoSection({ url }: IProps) {
  const classes = useStyles();
  if (!url) {
    return null;
  }
  const youtubeId = getYouTubeUrlId(url);
  const options: YouTubeProps["opts"] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      rel: 0,
    },
  };
  return (
    <div className={classes.root}>
      {youtubeId ? (
        <YouTube
          className={classes.youtubePlayer}
          videoId={youtubeId}
          opts={options}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}
