import { QueryCommand, QueryCommandOutput } from "@aws-sdk/client-dynamodb";
import { useMutation, useQuery } from "react-query";

import { useGroupData } from "../../hooks/api/groups";
import { ImagePackage, ImagePackageLineItem } from "../../types/core";
import useDynamoDbClient from "../useDynamoDbClient";
import { IJobsFilters } from "../useFilterJobsUiState";
import useUser from "../useUser";

type ImagePackagesQueryCommandOutput = Omit<QueryCommandOutput, "Items"> & {
  Items?: Array<ImagePackage>;
};

export function useImagePackages(groupSelect?: IJobsFilters | null) {
  const dynamoDbClient = useDynamoDbClient();
  const { userIsGroupAdmin, userGroupId, userIsSuperUser } = useUser();
  const groupId = userIsSuperUser
    ? groupSelect?.group || userGroupId
    : userGroupId;
  const { data: groupData } = useGroupData();
  const isIntro = groupData?.Item.IsIntro?.BOOL;

  return useQuery<ImagePackagesQueryCommandOutput>({
    queryKey: ["image-packages", groupId],
    queryFn: () => {
      const params = {
        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
        KeyConditionExpression: "PK = :pk and begins_with(SK, :sk)",
        ExpressionAttributeValues: {
          ":pk": {
            S: `group:${groupId}`,
          },
          ":sk": { S: "imgpack:" },
        },
        ProjectionExpression:
          "GroupId,ImagePackId,PackageName,PurchasedTimestamp,ActivatedTimestamp,ExpiresTimestamp,DurationDays,ImageQuota,ImagesRemaining",
      };
      return dynamoDbClient.send(
        new QueryCommand(params)
      ) as Promise<ImagePackagesQueryCommandOutput>;
    },
    enabled: Boolean(userIsGroupAdmin || userIsSuperUser || isIntro),
  });
}

type ImagePackageLineItemsQueryCommandOutput = Omit<
  QueryCommandOutput,
  "Items"
> & {
  Items?: Array<{
    LineItems: {
      L: Array<{
        M: ImagePackageLineItem;
      }>;
    };
  }>;
};

export function useGetImagePackageLineItems(groupSelect?: IJobsFilters | null) {
  const dynamoDbClient = useDynamoDbClient();
  const { userGroupId, userIsSuperUser } = useUser();
  return useMutation<
    ImagePackageLineItemsQueryCommandOutput,
    Error,
    { imagePackageId: string }
  >(({ imagePackageId }) => {
    const params = {
      TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
      KeyConditionExpression: "PK = :pk and SK = :sk",
      ExpressionAttributeValues: {
        ":pk": {
          S: `group:${
            userIsSuperUser ? groupSelect?.group || userGroupId : userGroupId
          }`,
        },
        ":sk": { S: `${imagePackageId}` },
      },
      ProjectionExpression: "LineItems",
    };
    return dynamoDbClient.send(
      new QueryCommand(params)
    ) as Promise<ImagePackageLineItemsQueryCommandOutput>;
  });
}
