import makeStyles from "@material-ui/core/styles/makeStyles";

import { container, pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  headerContainer: container(theme),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  heading: {
    fontWeight: 600,
  },
  metadata: {
    margin: `${theme.spacing(2)}px 0`,
    fontStyle: "italic",
  },
  mainContent: {
    "& p": {
      margin: `0 0 ${theme.spacing(1.5)}px 0`,
    },
  },
  emphasis: {
    fontWeight: 600,
  },
  scheduleHeading: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  contentContainer: pageContentContainer(theme),
  modelContainer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
  },
  thumbnail: {
    display: "none",
    width: 140,
    height: 210,
    backgroundColor: "#efefef",
    margin: theme.spacing(0.5),
  },
  posesContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
}));
