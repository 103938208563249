import { useCallback } from "react";

import { useAuth } from "../context/AuthContext";
import fetchClient, { FetchClientOpts } from "../views/utils/fetchClient";

const useFetchClient = () => {
  const { logout } = useAuth();
  return useCallback(
    (endpoint: string, config?: FetchClientOpts) =>
      fetchClient(endpoint, {
        ...config,
        on401Unauthorised: () => logout(),
      }),
    [logout]
  );
};

export type ApiClient = ReturnType<typeof useFetchClient>;

export default useFetchClient;
