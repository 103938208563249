import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    display: "block",
    "@media only screen and (min-width: 750px)": {
      display: "none",
    },
  },
  logo: {
    width: 36,
    height: 36,
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    marginRight: `-${theme.spacing(1)}px`,
  },
  accountButton: {
    display: "none",
    "@media only screen and (min-width: 750px)": {
      display: "block",
    },
    "&.MuiButtonBase-root": {
      marginLeft: theme.spacing(1),
    },
  },
}));
