import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useQuery } from "react-query";

import { ModelMeasurementManifest, ModelPoseManifest } from "../../types/core";
import useFetchClient from "../useFetchClient";
import useS3Client from "../useS3Client";

export function useModelPosesManifest() {
  const s3Client = useS3Client();
  const fetchClient = useFetchClient();
  return useQuery<ModelPoseManifest>({
    queryKey: ["model-poses-manifest"],
    queryFn: async () => {
      const preSignedUrl = await getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: "model-poses-preview/manifest_v3.json",
        })
      );
      return fetchClient(preSignedUrl);
    },
  });
}

export function useModelPoseImageUrl(key?: string | null) {
  const s3Client = useS3Client();
  return useQuery<string>({
    queryKey: ["model-pose", key],
    queryFn: () => {
      return getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: key ?? "",
        })
      );
    },
    enabled: Boolean(key),
  });
}

export function useModelMeasurementManifest() {
  const s3Client = useS3Client();
  const fetchClient = useFetchClient();
  return useQuery<ModelMeasurementManifest>({
    queryKey: ["model-poses-measurements"],
    queryFn: async () => {
      const preSignedUrl = await getSignedUrl(
        s3Client,
        new GetObjectCommand({
          Bucket: process.env.REACT_APP_S3_ASSETS_BUCKET,
          Key: "model-poses-preview/measurements.json",
        })
      );
      return fetchClient(preSignedUrl);
    },
  });
}
