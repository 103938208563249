import { useReducer } from "react";

export default function useStateReducer<
  StateType extends Record<string, unknown>
>(initialValue: StateType | (() => StateType)) {
  const reducer = (
    prevState: StateType,
    newState:
      | Partial<StateType>
      | ((prevState: StateType) => Partial<StateType>)
  ) => {
    const stateUpdates =
      typeof newState === "function" ? newState(prevState) : newState;
    return { ...prevState, ...stateUpdates };
  };
  const value =
    typeof initialValue === "function" ? initialValue() : initialValue;
  return useReducer(reducer, value);
}
