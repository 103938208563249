import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React, { useState } from "react";

import { useJobImageUrl } from "../../../hooks/api/jobs";
import { Job } from "../../../types/core";
import useStyles from "./RenderThumbnail.styles";

interface IProps {
  job: Job;
}

export default function RenderThumbnail({ job }: IProps) {
  const classes = useStyles();
  const [thumbnailHasLoaded, setThumbnailHasLoaded] = useState(false);
  const [errorLoadingThumbnail, setErrorLoadingThumbnail] = useState(false);

  const {
    data: imageUrl,
    isLoading: isImageUrlLoading,
    isError: isImageUrlError,
  } = useJobImageUrl(job.ThumbnailLocation?.M.Key.S);

  return (
    <>
      {imageUrl && !errorLoadingThumbnail && !isImageUrlError && (
        <img
          src={imageUrl}
          className={classnames(classes.thumbnail, {
            [classes.showThumbnail]: thumbnailHasLoaded,
          })}
          alt="Thumbnail"
          onLoad={() => setThumbnailHasLoaded(true)}
          onError={() => setErrorLoadingThumbnail(true)}
        />
      )}
      {(!imageUrl || errorLoadingThumbnail || isImageUrlError) && (
        <div
          className={classnames(classes.thumbnail, classes.missingThumbnail)}
        >
          {!isImageUrlLoading && (
            <Typography variant="caption" display="block" color="textSecondary">
              no thumbnail
            </Typography>
          )}
        </div>
      )}
    </>
  );
}
