import TextField from "@material-ui/core/TextField";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField/TextField";

import InputTitle from "../InputTitle/InputTitle";
import useStyles from "./TextInput.styles";

interface IProps extends Omit<OutlinedTextFieldProps, "onChange" | "variant"> {
  title?: string;
  onChange: (val: string) => void;
  value: string;
  className?: string;
}

export default function TextInput({
  title,
  onChange,
  value,
  className,
  ...inputProps
}: IProps) {
  const classes = useStyles();
  return (
    <div className={className}>
      {title && <InputTitle title={title} className={classes.title} />}
      <TextField
        color="secondary"
        variant="outlined"
        margin="normal"
        fullWidth
        onChange={(evt) => onChange(evt.target.value)}
        value={value}
        {...inputProps}
      />
    </div>
  );
}
