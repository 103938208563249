import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(1),
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
  },
  itemContainer: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));
