import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { ICredentials } from "aws-amplify/lib/Common/types/types";
import md5 from "blueimp-md5";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import useInterval from "../hooks/useInterval";
import { LOGIN_PAGE_ROUTE } from "../Routes";
import FullPageErrorFallback from "../views/components/FullPageErrorFallback/FullPageErrorFallback";
import FullPageSpinner from "../views/components/FullPageSpinner/FullPageSpinner";
import { useAsync } from "../views/utils/hooks";

function pushUserIdToDataLayer() {
  const currentUser = Auth.currentUserInfo().then((user) => {
    const email = user.attributes.email;
    const co = user.attributes["custom:company_name"] ?? "";
    (window as any).dataLayer.push({
      event: "set_user",
      user_id: md5(email),
      company: co,
    });
  });
}

async function bootstrapAppData() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    let credentials = null;
    if (user) {
      const currentCredentials = await Auth.currentCredentials();
      credentials = currentCredentials
        ? Auth.essentialCredentials(currentCredentials)
        : null;
      pushUserIdToDataLayer();
    }
    return { user, credentials };
  } catch (err) {
    return { user: null, credentials: null };
  }
}

interface IAuthContext {
  user: CognitoUserInterface;
  credentials: ICredentials | null;
  refreshCurrentUser: () => Promise<any>;
  signup: (o: {
    email: string;
    password: string;
    name: string;
  }) => Promise<any>;
  confirmSignup: (o: { email: string; code: string }) => Promise<any>;
  resendSignup: (o: { email: string }) => Promise<any>;
  login: (o: {
    email: string;
    password: string;
    newPasswordOnChallenge?: string;
  }) => Promise<any>;
  logout: (o?: { replaceTo?: string }) => Promise<any>;
  forgotPassword: (o: { email: string }) => Promise<any>;
  forgotPasswordSubmit: (o: {
    email: string;
    code: string;
    password: string;
  }) => Promise<any>;
  changePassword: (o: {
    oldPassword: string;
    newPassword: string;
  }) => Promise<any>;
  updateUserAttributes: (attributes: Record<string, unknown>) => Promise<any>;
  deleteAccount: () => Promise<any>;
}

const defaultValue = {};
const AuthContext = createContext<IAuthContext>(defaultValue as IAuthContext);
AuthContext.displayName = "AuthContext";

function AuthProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const {
    data: { user, credentials },
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync({ data: { user: null, credentials: null } });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    const appDataPromise = bootstrapAppData();
    run(appDataPromise);
  }, [run]);

  const updateUser = useCallback(
    (user: CognitoUserInterface | null) => {
      if (user) {
        Auth.currentCredentials().then((creds) => {
          setData({ user, credentials: Auth.essentialCredentials(creds) });
        });
        pushUserIdToDataLayer();
      } else {
        setData({ user, credentials: null });
      }
    },
    [setData]
  );

  const refreshCurrentUser = useCallback(
    () =>
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((response) => {
        updateUser(response);
        return response;
      }),
    [updateUser]
  );

  // refresh a logged in user every 30 minutes so the tokens/credentials don't go stale
  useInterval(refreshCurrentUser, user ? 1000 * 60 * 30 : null);

  const signup = useCallback(
    ({ email, password, name, companyName }) =>
      Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: { name, "custom:company_name": companyName },
      }).then((response) => {
        return response;
      }),
    []
  );

  const confirmSignup = useCallback(
    ({ email, code }) =>
      Auth.confirmSignUp(email.toLowerCase(), code).then((data) => {
        updateUser(null);
        return data;
      }),
    [updateUser]
  );

  const resendSignup = useCallback(
    ({ email }) => Auth.resendSignUp(email.toLowerCase()),
    []
  );

  const login = useCallback(
    ({ email, password }) =>
      Auth.signIn(email.toLowerCase(), password).then((cognitoUser) => {
        updateUser(cognitoUser);
        return cognitoUser;
      }),
    [updateUser]
  );

  const logout = useCallback(
    () =>
      Auth.signOut().then((data) => {
        updateUser(null);
        navigate(LOGIN_PAGE_ROUTE);
        queryClient.clear();
        return data;
      }),
    [navigate, updateUser, queryClient]
  );

  const forgotPassword = useCallback(
    ({ email }) => Auth.forgotPassword(email.toLowerCase()),
    []
  );

  const forgotPasswordSubmit = useCallback(
    ({ email, code, password }) =>
      Auth.forgotPasswordSubmit(email.toLowerCase(), code, password),
    []
  );

  const changePassword = useCallback(
    ({ oldPassword, newPassword }) =>
      Auth.currentAuthenticatedUser().then((user) =>
        Auth.changePassword(user, oldPassword, newPassword)
      ),
    []
  );

  const updateUserAttributes = useCallback(
    (attributes) =>
      Auth.currentAuthenticatedUser()
        .then((user) => Auth.updateUserAttributes(user, attributes))
        .then(() => Auth.currentAuthenticatedUser())
        .then((user) => updateUser(user)),
    [updateUser]
  );

  const deleteAccount = useCallback(
    () =>
      Auth.currentAuthenticatedUser()
        .then((user) =>
          user.deleteUser(() => {
            // NO-OP - we don't need to do anything in the callback, but it will throw an error if we don't provide one
          })
        )
        .then((data) => {
          updateUser(null);
          return data;
        }),
    [updateUser]
  );

  const value = useMemo(
    () => ({
      user,
      credentials,
      refreshCurrentUser,
      signup,
      confirmSignup,
      resendSignup,
      login,
      logout,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
      updateUserAttributes,
      deleteAccount,
    }),
    [
      user,
      credentials,
      refreshCurrentUser,
      signup,
      confirmSignup,
      resendSignup,
      login,
      logout,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
      updateUserAttributes,
      deleteAccount,
    ]
  );

  if (isLoading || isIdle) {
    return <FullPageSpinner />;
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  if (isSuccess) {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useAuth hook must be used within a AuthProvider component"
    );
  }
  return context;
}

export { AuthProvider, useAuth };
