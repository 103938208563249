import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import { useError } from "../../../context/ErrorContext";
import useStateReducer from "../../../hooks/useStateReducer";
import { LOGIN_PAGE_ROUTE } from "../../../Routes";
import TextAndButtonFormRow from "../../components/TextAndButtonFormRow/TextAndButtonFormRow";
import useStyles from "./DeleteAccount.styles";

type IState = {
  showConfirmDialog: boolean;
  confirmChecked: boolean;
};

export default function DeleteAccount() {
  const classes = useStyles();
  const { deleteAccount } = useAuth();
  const { addError } = useError();
  const navigate = useNavigate();
  const [state, setState] = useStateReducer<IState>({
    showConfirmDialog: false,
    confirmChecked: false,
  });
  const { mutate: onDeleteAccount } = useMutation(deleteAccount);

  const handleCloseDialog = () => {
    setState({
      showConfirmDialog: false,
      confirmChecked: false,
    });
  };
  const handleOpenDialog = () => setState({ showConfirmDialog: true });

  const onConfirmDeleteAccount = async () => {
    await onDeleteAccount(undefined, {
      onError: () =>
        addError({
          message: `We encountered a problem deleting your account.`,
        }),
    });
    navigate(LOGIN_PAGE_ROUTE);
  };

  return (
    <div className={classes.root}>
      <TextAndButtonFormRow
        label="Delete your account"
        icon={<DeleteForeverIcon className={classes.icon} />}
        onClick={handleOpenDialog}
      />
      <Dialog open={state.showConfirmDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to permanently delete your account?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.confirmChecked}
                onChange={(evt) =>
                  setState({ confirmChecked: evt.target.checked })
                }
                color="secondary"
              />
            }
            label="I am sure"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={onConfirmDeleteAccount}
            disabled={!state.confirmChecked}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
