import { Theme } from "@material-ui/core";

export const container = (theme: Theme) => ({
  margin: "0 auto",
  maxWidth: 1280,
  width: "96%",
  [theme.breakpoints.up("sm")]: {
    width: "94%",
  },
  [theme.breakpoints.up("md")]: {
    width: "92%",
  },
});

export const pageContentContainer = (theme: Theme) => ({
  ...container(theme),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(8),
});

export const thinPageContentContainer = (theme: Theme) => ({
  ...pageContentContainer(theme),
  maxWidth: 640,
});

export const textEllipsis = (numLines: number) => ({
  display: "-webkit-box",
  "-webkit-line-clamp": numLines,
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
});
