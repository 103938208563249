import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import useStyles from "./FullPageErrorFallback.styles";

interface IProps {
  error: Error;
}

export default function FullPageErrorFallback({ error }: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        <Typography>
          An unexpected error occurred, please refresh the app.
        </Typography>
        <pre>{error && error.message}</pre>
      </Paper>
    </div>
  );
}
