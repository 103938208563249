export type FetchClientOpts = {
  method?: RequestInit["method"];
  headers?: RequestInit["headers"];
  data?: Record<string | number, any>;
  getBearerToken?: () => Promise<string | undefined>;
  noJsonInSuccessResponse?: boolean;
  on401Unauthorised?: () => Promise<any>;
} & Partial<RequestInit>;

const fetchClient = async (
  url: string,
  {
    method,
    headers: customHeaders,
    data,
    getBearerToken,
    noJsonInSuccessResponse,
    on401Unauthorised,
    ...customConfig
  }: FetchClientOpts
): Promise<any> => {
  const bearerToken = getBearerToken ? await getBearerToken() : undefined;

  const config = {
    method: method ?? "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(data && { "Content-Type": "application/json" }),
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` }),
      ...customHeaders,
    },
    ...customConfig,
  };

  return window.fetch(url, config).then(async (response) => {
    if (response.ok) {
      const successData = noJsonInSuccessResponse
        ? { ok: true }
        : await response.json();
      return successData;
    } else {
      if (response.status === 401 && on401Unauthorised) {
        await on401Unauthorised();
      }
      const errorData = await response.json();
      return Promise.reject({ ...errorData, requestUrl: url });
    }
  });
};

export default fetchClient;
