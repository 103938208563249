import Drawer from "@material-ui/core/Drawer";

import DrawerContent from "./DrawerContent";
import useStyles from "./PersistentDrawer.styles";

export default function PersistentDrawer() {
  const classes = useStyles();

  return (
    <Drawer className={classes.root} variant="permanent">
      <div className={classes.toolbar} />
      <DrawerContent />
    </Drawer>
  );
}
