import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { useMutation } from "react-query";

import { useAuth } from "../../../context/AuthContext";
import { useError } from "../../../context/ErrorContext";
import { useNotifications } from "../../../context/NotificationContext";
import useStyles from "./RequestNewVerificationCodeLink.styles";

interface IProps {
  type: "forgot-password" | "verify-account" | "post-registration";
  email: string;
  onSuccess?: () => void;
  plainStyling?: boolean;
}

export default function RequestNewVerificationCodeLink({
  type,
  email,
  onSuccess,
  plainStyling,
}: IProps) {
  const classes = useStyles();
  const { forgotPassword, resendSignup } = useAuth();
  const { addError } = useError();
  const { addNotification } = useNotifications();
  const { mutate: sendCode, isLoading: isSendingCode } = useMutation(() => {
    if (type === "forgot-password") {
      return forgotPassword({ email });
    } else if (type === "verify-account" || type === "post-registration") {
      return resendSignup({ email });
    } else {
      throw new Error(`Unknown verification code type: ${type}`);
    }
  });

  const messages = {
    "forgot-password": {
      successNotification: `New verification code sent to ${email}`,
      label: "Need a new code?",
    },
    "post-registration": {
      successNotification: `New email sent to ${email}`,
      label: "Didn't receive the email?",
    },
    "verify-account": {
      successNotification: `New email sent to ${email}`,
      label: "Need a new confirmation email?",
    },
  };

  const onSendNewCode = async () => {
    if (isSendingCode) {
      return;
    }
    await sendCode(undefined, {
      onSuccess: () => {
        addNotification(messages[type].successNotification);
        onSuccess && onSuccess();
      },
      onError: () =>
        addError({
          message: `We encountered a problem sending the verification email to ${email}.`,
        }),
    });
  };

  return (
    <div className={classnames({ [classes.root]: !plainStyling })}>
      <div className={classes.contentContainer}>
        <Typography variant="body2">
          {messages[type].label}
          <Link
            className={classes.link}
            href="#"
            onClick={(evt) => {
              evt.preventDefault();
              onSendNewCode();
            }}
          >
            Click here
          </Link>
        </Typography>
        {isSendingCode && (
          <CircularProgress
            className={classes.progress}
            color="secondary"
            size={16}
          />
        )}
      </div>
    </div>
  );
}
