import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
