import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { PluginChangelogVersion } from "../../../types/core";
import VideoSection from "../../components/VideoSection/VideoSection";
import useStyles from "./VersionSection.styles";

export default function VersionSection({
  Version,
  ReleaseDate,
  Changes,
  VideoLink,
}: PluginChangelogVersion) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" component="h3" className={classes.releaseHeader}>
        v{Version}
        <span className={classes.releaseDate}>({ReleaseDate})</span>
      </Typography>
      <Divider />
      <div>
        <ul className={classes.changes}>
          {Changes.map((change, index) => (
            <li key={index}>
              <Typography>{change}</Typography>
            </li>
          ))}
        </ul>
        <VideoSection url={VideoLink} />
      </div>
    </div>
  );
}
