import makeStyles from "@material-ui/core/styles/makeStyles";

import { thinPageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  content: {
    ...thinPageContentContainer(theme),
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
  },
  heading: {
    fontWeight: 600,
  },
  metadata: {
    margin: `${theme.spacing(2)}px 0`,
    fontStyle: "italic",
  },
  mainContent: {
    "& p": {
      margin: `0 0 ${theme.spacing(1.5)}px 0`,
    },
  },
  emphasis: {
    fontWeight: 600,
  },
  scheduleHeading: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}));
