import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

export default responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: { main: "#bef536" },
      secondary: { main: "#ffffff" },
    },
  })
);
