import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";

import {
  INotification,
  useNotifications,
} from "../../../context/NotificationContext";
import useStyles from "./GlobalNotifications.styles";

export default function GlobalNotifications() {
  const { notifications, removeNotification } = useNotifications();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<INotification | null>(null);

  useEffect(() => {
    if (notifications.length && !messageInfo) {
      setMessageInfo({ ...notifications[0] });
      removeNotification();
      setOpen(true);
    } else if (notifications.length && messageInfo && open) {
      setOpen(false);
    }
  }, [notifications, removeNotification, messageInfo, open]);

  const handleExited = () => {
    setMessageInfo(null);
  };

  const classes = useStyles();
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={(event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      }}
      onExited={handleExited}
      message={messageInfo ? messageInfo.message : undefined}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
}
