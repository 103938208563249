import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Group from "@material-ui/icons/Group";
import React, { useEffect, useState } from "react";

import { useGroupData, useGroups } from "../../../hooks/api/groups";
import useFilterJobsUiState, {
  IJobsFilters,
} from "../../../hooks/useFilterJobsUiState";
import useStateReducer from "../../../hooks/useStateReducer";
import useStyles from "./SelectGroupsButton.styles";

export default function SelectGroupsButton() {
  const classes = useStyles();
  const { data: groups } = useGroups();
  const { uiState, setUiState, defaultFilters } = useFilterJobsUiState();
  const [select, setSelects] = useStateReducer<IJobsFilters>(uiState);
  const { data: groupData } = useGroupData();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const groupName = groupData?.Item.GroupName.S;

  useEffect(() => {
    setSelects(uiState);
  }, [isDialogOpen, setSelects, uiState]);

  const handleOpenDialog = () => setIsDialogOpen(true);

  const handleCloseDialog = () => setIsDialogOpen(false);

  const onApplyClicked = () => {
    handleCloseDialog();
    setUiState(select);
  };

  const clearForm = () => setSelects(defaultFilters);

  return (
    <>
      <Button
        size="small"
        startIcon={<Group />}
        onClick={handleOpenDialog}
        variant={"contained"}
        color={"secondary"}
      >
        {select.group
          ? groups?.Items?.find(
              (group) =>
                group.GroupId.S?.replaceAll("group:", "") === select.group
            )?.GroupName.S
          : "Select Group"}
      </Button>

      <Dialog
        disableBackdropClick
        open={isDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          {groups?.Items && (
            <div>
              <FormControl className={classes.selectContainer}>
                <InputLabel shrink color="secondary">
                  Group
                </InputLabel>
                <Select
                  color="secondary"
                  value={select.group ?? ""}
                  onChange={(evt) => {
                    setSelects({
                      group: evt.target.value as string,
                    });
                  }}
                >
                  {groups.Items.map((group) => {
                    const groupId = group.GroupId.S?.replaceAll("group:", "");
                    return (
                      <MenuItem key={group.GroupId.S} value={groupId}>
                        {group.GroupName.S}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={clearForm}>Clear</Button>
          <div className={classes.rightActions}>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={onApplyClicked}>Apply</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
