import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(2)}px`,
  },
  removeHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  icon: {
    marginRight: -theme.spacing(1.5),
  },
}));
