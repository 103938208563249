import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

import { useError } from "../../../context/ErrorContext";
import { useNotifications } from "../../../context/NotificationContext";
import { useDeleteInvite, useGroupMembers } from "../../../hooks/api/groups";
import useFilterJobsUiState from "../../../hooks/useFilterJobsUiState";
import useStateReducer from "../../../hooks/useStateReducer";
import useUser from "../../../hooks/useUser";
import { GroupInvitation } from "../../../types/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import getUserRoles from "../../utils/getUserRoles";
import { memberPermissionLabels } from "../../utils/memberPermissions";

interface IProps {
  invite: GroupInvitation;
}

export default function InvitationItem({ invite }: IProps) {
  const { addError } = useError();
  const { addNotification } = useNotifications();
  const { userIsSuperUser } = useUser();
  const { uiState: groupSelect } = useFilterJobsUiState();
  const { data: groupMembers } = useGroupMembers(
    userIsSuperUser ? groupSelect.group : null
  );
  const [{ isRemoveDialogOpen }, setState] = useStateReducer({
    isRemoveDialogOpen: false,
  });
  const closeRemoveDialog = () => setState({ isRemoveDialogOpen: false });
  const openRemoveDialog = () => setState({ isRemoveDialogOpen: true });

  const { mutate: doDeleteInvite, isLoading: isDeletingInvite } =
    useDeleteInvite();

  const confirmDelete = () => {
    if (isDeletingInvite) {
      return;
    }
    doDeleteInvite(
      { inviteId: invite.InviteId.S },
      {
        onSuccess: () =>
          addNotification(
            `The invite to ${invite.InviteeEmail.S} has been deleted`
          ),
        onError: () => {
          addError({
            message: `We could not delete the invite for ${invite.InviteeEmail.S} at this time.`,
          });
          closeRemoveDialog();
        },
      }
    );
  };

  const invitedByName =
    groupMembers?.Result.Members.find(
      (member) => member.UserId === invite.InvitedBy.S
    )?.Name ?? "";

  return (
    <ListItem key="item">
      <ListItemText
        primary={invite.InviteeEmail.S}
        secondary={
          <span>
            <span>
              {memberPermissionLabels(
                getUserRoles(invite.InviteePermissions.N)
              )}
            </span>
            <br />
            <span>
              Invited on {moment(invite.InvitedAt.S).format("L")} by{" "}
              {invitedByName}
            </span>
          </span>
        }
      />
      <Dialog open={isRemoveDialogOpen} onClose={closeRemoveDialog}>
        <DialogTitle>Confirm removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this invite?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog}>Cancel</Button>
          <ButtonWithProgress
            label="Delete"
            onClick={confirmDelete}
            isLoading={isDeletingInvite}
            variant="text"
            spinnerPosition="end"
          />
        </DialogActions>
      </Dialog>
      <ListItemSecondaryAction>
        {userIsSuperUser ? (
          <Typography variant="body2" color={"textSecondary"} noWrap>
            Read-Only
          </Typography>
        ) : (
          <IconButton edge="end" onClick={openRemoveDialog}>
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
