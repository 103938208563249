import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import classnames from "classnames";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Sticky from "react-stickynode";

import { useJobs, useRefreshJobs } from "../../../hooks/api/jobs";
import useFilterJobsUiState from "../../../hooks/useFilterJobsUiState";
import { Job, JobStatus } from "../../../types/core";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import SectionLoadingIndicator from "../../components/SectionLoadingIndicator/SectionLoadingIndicator";
import FilterJobsButton from "./FilterJobsButton";
import useStyles from "./InfiniteJobListing.styles";

const PAGE_SIZE = 36;

interface IProps {
  jobStatus: JobStatus;
  listingContent: FC<{ jobs: Array<Job> }>;
  noJobsMessage: any;
}

export default function InfiniteJobListing({
  jobStatus,
  listingContent: ListingContent,
  noJobsMessage,
}: IProps) {
  const classes = useStyles();
  const refreshJobs = useRefreshJobs();
  const { uiState: jobFilters } = useFilterJobsUiState();
  const { ref: endOfEcoShots, inView: isEndOfEcoShotsInView } = useInView();
  const rootElementRef = useRef<HTMLDivElement>(null);

  const {
    data: jobs,
    isLoading: areJobsLoading,
    isFetching: areJobsBeingFetched,
    isFetchingNextPage: isNextJobsPageBeingFetched,
    fetchNextPage: fetchNextJobsPage,
  } = useJobs({
    jobStatus,
    pageSize: PAGE_SIZE,
    filters: jobFilters,
  });

  const scrollToTopOfListing = () =>
    rootElementRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    if (isEndOfEcoShotsInView) {
      fetchNextJobsPage();
    }
  }, [isEndOfEcoShotsInView, fetchNextJobsPage]);

  const allJobs = useMemo(
    () => jobs?.pages.flatMap((page) => page.Items ?? []),
    [jobs]
  );

  const ListingActions = () => {
    return (
      <div className={classes.actionsContainer}>
        <Sticky enabled top={64} innerZ={1}>
          {({ status }) => {
            return (
              <div
                className={classnames(classes.actions, {
                  [classes.actionsWhenSticky]: status === Sticky.STATUS_FIXED,
                })}
              >
                <div className={classes.actionButtons}>
                  <Button
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      refreshJobs();
                      status === Sticky.STATUS_FIXED && scrollToTopOfListing();
                    }}
                  >
                    Refresh
                  </Button>
                  <FilterJobsButton
                    jobStatus={jobStatus}
                    onFiltersChanged={() => {
                      status === Sticky.STATUS_FIXED && scrollToTopOfListing();
                    }}
                  />
                </div>
              </div>
            );
          }}
        </Sticky>
      </div>
    );
  };

  return (
    <div ref={rootElementRef}>
      {areJobsLoading && !allJobs && <PageLoadingIndicator />}
      {allJobs && (
        <>
          <ListingActions />
          <div
            className={classnames(classes.listingContainer, {
              [classes.loading]:
                areJobsLoading ||
                (areJobsBeingFetched && !isNextJobsPageBeingFetched),
            })}
          >
            {allJobs.length > 0 ? (
              <>
                <ListingContent jobs={allJobs} />
                <div
                  ref={endOfEcoShots}
                  className={classes.loadMoreJobsWaypoint}
                ></div>
                {isNextJobsPageBeingFetched && (
                  <div className={classes.nextPageLoadingContainer}>
                    <SectionLoadingIndicator />
                  </div>
                )}
              </>
            ) : (
              <Typography color="textSecondary">{noJobsMessage}</Typography>
            )}
          </div>
        </>
      )}
    </div>
  );
}
