import makeStyles from "@material-ui/core/styles/makeStyles";

import { container, pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  headerContainer: container(theme),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  actionsContainer: {
    marginTop: theme.spacing(-2),
  },
  actions: {
    backgroundColor: "#fafafa",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actionsWhenSticky: {
    boxShadow: theme.shadows[2],
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  contentContainer: pageContentContainer(theme),
  modelContainer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
  },
  posesContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
}));
