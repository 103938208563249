import LinearProgress from "@material-ui/core/LinearProgress";

import useStyles from "./PageLoadingIndicator.styles";

export default function PageLoadingIndicator() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}
