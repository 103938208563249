import makeStyles from "@material-ui/core/styles/makeStyles";

import { container, pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  headerContainer: container(theme),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  contentContainer: pageContentContainer(theme),
  content: {
    maxWidth: 560,
  },
  deleteAccountPanelDetails: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(
      1.5
    )}px `,
  },
  listSection: {
    marginTop: theme.spacing(2),
  },
}));
