import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import { useGroupMembers } from "../../../hooks/api/groups";
import useFilterJobsUiState from "../../../hooks/useFilterJobsUiState";
import useUser from "../../../hooks/useUser";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import getUserRoles from "../../utils/getUserRoles";
import MemberItem from "./MemberItem";
import useStyles from "./Members.styles";

export default function Members() {
  const classes = useStyles();
  const { userGroupId, userIsSuperUser } = useUser();
  const { uiState: groupSelect } = useFilterJobsUiState();
  const { data: groupMembers, isLoading: areGroupMembersLoading } =
    useGroupMembers(userIsSuperUser ? groupSelect.group : null);
  if (areGroupMembersLoading) {
    return <PageLoadingIndicator />;
  }
  const sortedMembers = groupMembers.Result.Members.sort((a, b) => {
    if (a.Email < b.Email) {
      return -1;
    }
    if (a.Email > b.Email) {
      return 1;
    }
    return 0;
  });
  const numberOfGroupAdmins = groupMembers.Result.Members.filter((member) =>
    getUserRoles(member.Permissions).has("group_admin")
  ).length;
  return (
    <div className={classes.root}>
      <Paper>
        <List>
          {sortedMembers.map((member, index) => {
            return [
              <MemberItem
                key="item"
                member={member}
                group={userIsSuperUser ? groupSelect.group : userGroupId}
                numberOfGroupAdmins={numberOfGroupAdmins}
              />,
              index < sortedMembers.length - 1 ? (
                <Divider key="divider" />
              ) : null,
            ];
          })}
        </List>
      </Paper>
    </div>
  );
}
