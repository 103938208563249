import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import useUser from "../../../hooks/useUser";
import {
  DOWNLOAD_PLUGIN_PAGE_ROUTE,
  ECOSHOTS_COMPLETED_PAGE_ROUTE,
} from "../../../Routes";
import DownloadDirectPluginPage from "../DownloadDirectPluginPage/DownloadDirectPluginPage";
import DownloadPluginPage from "../DownloadPluginPage/DownloadPluginPage";
import EcoShotsPage from "../EcoShotsPage/EcoShotsPage";
import GroupAdminPage from "../GroupAdminPage/GroupAdminPage";
import ImageProfilesPage from "../ImageProfilesPage/ImageProfilesPage";
import InvitesPage from "../InvitesPage/InvitesPage";
import ModelPosesPage from "../ModelPosesPage/ModelPosesPage";
import PackagesPage from "../PackagesPage/PackagesPage";
import ProfilePage from "../ProfilePage/ProfilePage";
import VariantsPage from "../VariantsPage/VariantsPage";
import useStyles from "./Dashboard.styles";

export default function Dashboard() {
  const classes = useStyles();
  const { user, userGroupId } = useUser();
  const fallbackRoute = userGroupId
    ? ECOSHOTS_COMPLETED_PAGE_ROUTE
    : DOWNLOAD_PLUGIN_PAGE_ROUTE;
  const location = useLocation();

  return (
    <div>
      <div className={classes.contentContainer}>
        <Routes>
          <Route
            path="/download-direct-plugin"
            element={<DownloadDirectPluginPage />}
          />
          <Route path="/download-plugin" element={<DownloadPluginPage />} />
          <Route path="/download-plugin-v3" element={<DownloadPluginPage />} />
          <Route path="/ecoshots/*" element={<EcoShotsPage />} />
          <Route path="/group-administration/*" element={<GroupAdminPage />} />
          <Route path="/image-profiles" element={<ImageProfilesPage />} />
          <Route path="/invites" element={<InvitesPage />} />
          <Route path="/model-poses" element={<ModelPosesPage />} />
          <Route path="/packages" element={<PackagesPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/variants" element={<VariantsPage />} />
          <Route path="*" element={<Navigate to={fallbackRoute} replace />} />
        </Routes>
      </div>
    </div>
  );
}
