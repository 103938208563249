import Typography from "@material-ui/core/Typography";

import useStyles from "./InputTitle.styles";

interface IProps {
  title: string;
  className?: string;
}

export default function InputTitle({ title, className }: IProps) {
  const classes = useStyles();
  return (
    <Typography className={`${classes.title} ${className}`}>{title}</Typography>
  );
}
