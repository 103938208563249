import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AddAPhoto";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useError } from "../../../context/ErrorContext";
import { useGroupData } from "../../../hooks/api/groups";
import { useReportIssue, useUploadAttachment } from "../../../hooks/api/jobs";
import useUser from "../../../hooks/useUser";
import InvertedColourTheme from "../../../InvertedColourTheme";
import { Job } from "../../../types/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import useStyles from "./ReportIssueDialog.styles";

interface IProps {
  job: Job;
  isOpen: boolean;
  onClose: () => void;
}

export default function ReportIssueDialog({ job, isOpen, onClose }: IProps) {
  const classes = useStyles();
  const { addError } = useError();
  const { user } = useUser();
  const userId = user.attributes.sub;
  const [issueDescription, setIssueDescription] = useState("");
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [isAttachFileDialogOpen, setIsAttachFileDialogOpen] = useState(false);
  const [uuid] = useState(uuidv4());

  const { mutate: doReportIssue, isLoading: isReportingIssue } =
    useReportIssue();

  const { data: groupData } = useGroupData();
  const uploadBucket = groupData?.Item.GroupUploadsBucket.S;

  const createUploadedFileKey = (file: File) => {
    return `user/${userId}/attachment/${uuid}/${file.name}`;
  };

  const onSendRequest = () => {
    doReportIssue(
      {
        imageRequestId: job.ImageRequestId.S,
        details: issueDescription,
        uploadedFileKey: attachedFile
          ? createUploadedFileKey(attachedFile)
          : null,
        bucket: uploadBucket,
      },
      {
        onError: () => {
          const message = "We could not submit issue report at this time.";
          addError({
            message,
          });
        },
        onSuccess: () => closeDialog(),
      }
    );
  };

  const { mutate: doAttachFile } = useUploadAttachment();

  const onAttachFile = (file: File) => {
    if (!uploadBucket) {
      return null;
    }
    doAttachFile(
      { file, bucket: uploadBucket, fileKey: createUploadedFileKey(file) },
      {
        onError: () => {
          addError({
            message: "Your attachment could not be uploaded at this time.",
          });
        },
        onSuccess: (response) => {
          setAttachedFile(file);
          setIsAttachFileDialogOpen(false);
        },
      }
    );
  };

  const closeDialog = () => {
    setIssueDescription("");
    setAttachedFile(null);
    onClose();
  };

  const disableSendButton = !issueDescription;

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      scroll="paper"
      classes={{
        paper: classes.dialogWrapper,
      }}
    >
      <DialogTitle>Report Issue</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          If your EcoShot image contains a issue which should have been
          corrected during processing, please report it here. There is no charge
          for corrections, but note that non-standard retouching should be
          requested using the 'Request Retouch' menu option.
        </Typography>
        <Typography gutterBottom></Typography>
        <TextField
          className={classes.textarea}
          color="secondary"
          label="Describe Issue"
          type="text"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={issueDescription}
          onChange={(evt) => setIssueDescription(evt.target.value)}
        />
        {uploadBucket && (
          <div className={classes.attachFileContainer}>
            <Button
              endIcon={<AttachFileIcon />}
              variant="contained"
              color="secondary"
              onClick={() => setIsAttachFileDialogOpen(true)}
            >
              Attach File
            </Button>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.attachedFileName}
            >
              {attachedFile?.name}
            </Typography>
            <ThemeProvider theme={InvertedColourTheme}>
              <DropzoneDialog
                filesLimit={1}
                open={isAttachFileDialogOpen}
                acceptedFiles={["image/*"]}
                onClose={() => setIsAttachFileDialogOpen(false)}
                onSave={(files) => {
                  if (files.length > 0) {
                    onAttachFile(files[0]);
                  }
                }}
                showPreviews={false}
                showPreviewsInDropzone
              />
            </ThemeProvider>
          </div>
        )}
        <Typography className={classes.turnaroundMessage}>
          Metail aim to return corrections as soon as possible - usually in the
          same day. Contact us via the chat in the Image Hub if you have urgent
          deadlines.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isReportingIssue} onClick={closeDialog}>
          Cancel
        </Button>
        <ButtonWithProgress
          variant="text"
          onClick={onSendRequest}
          label="Submit Issue"
          disabled={disableSendButton}
          isLoading={isReportingIssue}
          spinnerPosition="end"
        />
      </DialogActions>
    </Dialog>
  );
}
