import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { LOGIN_PAGE_ROUTE } from "../../../Routes";
import RequestNewVerificationCodeLink from "../../components/RequestNewVerificationCodeLink/RequestNewVerificationCodeLink";
import useStyles from "./PostRegistrationPage.styles";

interface LocationState {
  email?: string;
}

export default function PostRegistrationPage() {
  const classes = useStyles();
  const location = useLocation();
  const email = (location.state as LocationState)?.email;

  useEffect(() => {
    document.title = "EcoShot Image Hub - Post Registration";
  }, []);

  if (!email) {
    return <Navigate to={LOGIN_PAGE_ROUTE} replace />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography variant="h4" component="h1" gutterBottom>
            Thank you for registering!
          </Typography>
        </div>
        <div className={classes.content}>
          <Typography variant="body1" component="h2">
            We've just sent an email to {email}. You'll need to click the link
            within to verify your account before you can log in.
          </Typography>
        </div>
        <RequestNewVerificationCodeLink
          email={email}
          type="post-registration"
        />
      </Paper>
    </div>
  );
}
