import Drawer from "@material-ui/core/Drawer";

import DrawerContent from "./DrawerContent";
import useStyles from "./TemporaryDrawer.styles";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function TemporaryDrawer({ isOpen, handleClose }: IProps) {
  const classes = useStyles();

  return (
    <Drawer className={classes.root} open={isOpen} onClose={handleClose}>
      <DrawerContent onItemClicked={handleClose} />
    </Drawer>
  );
}
