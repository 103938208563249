import React, { useState } from "react";

import TextInput from "../TextInput/TextInput";

const isEmailAddressValid = (emailAddress: string) => {
  // We use the RegExp suggested by W3C in http://www.w3.org/TR/html5/forms.html#valid-e-mail-address
  // This is probably the same logic used by most browsers when type=email.
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(emailAddress);
};

interface IProps {
  title?: string;
  onChange: (email: string, isValid: boolean) => void;
  value: string;
  className?: string;
  showError?: boolean;
  hideTitle?: boolean;
  id?: string;
  showHelperText?: boolean;
}

export default function EmailInput({
  title = "Email",
  onChange,
  value,
  className,
  showError,
  hideTitle,
  showHelperText,
}: IProps) {
  const invalidEmailMessage = "Not a valid email address";
  const companyEmailMessage = "Company email address preferred";
  const [errorMessage, setErrorMessage] = useState(invalidEmailMessage);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const handleChange = (email: string) => {
    const valid = isEmailAddressValid(email);
    setErrorMessage(
      !valid ? invalidEmailMessage : showHelperText ? companyEmailMessage : ""
    );
    setIsEmailValid(valid);
    onChange(email, valid);
  };
  return (
    <TextInput
      className={className}
      title={!hideTitle ? title : undefined}
      autoComplete="email"
      onChange={handleChange}
      value={value}
      helperText={
        showError ? errorMessage : showHelperText ? companyEmailMessage : ""
      }
      error={showError && !isEmailValid}
      id="Email"
    />
  );
}
