import makeStyles from "@material-ui/core/styles/makeStyles";

import { pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  root: pageContentContainer(theme),
  contentContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(
      8
    )}px`,
    margin: "0 auto",
    maxWidth: "32rem",
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  failedRequestMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  inputs: {
    marginBottom: theme.spacing(1),
  },
  emailInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    margin: `${theme.spacing(1)}px 0 0`,
  },
}));
