import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useEffect, useState } from "react";

import TextInput from "../TextInput/TextInput";

const MAX_PASSWORD_LENGTH = 64;
const PASSWORD_REGEX = new RegExp(
  "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[\\W\\w]{10,}$"
);

interface IProps {
  title?: string;
  onChange: (password: string, isValid: boolean) => void;
  onShowPasswordClick: () => void;
  showPassword?: boolean;
  value: string;
  className?: string;
  isConfirmPassword?: boolean;
  expectedPassword?: string;
  showError?: boolean;
  hideHelperText?: boolean;
  newPassword?: boolean;
  isLogIn?: boolean;
}

export default function PasswordInput({
  title,
  onChange,
  onShowPasswordClick,
  showPassword,
  value,
  className,
  isConfirmPassword,
  expectedPassword,
  showError,
  hideHelperText,
  newPassword,
  isLogIn,
}: IProps) {
  const passwordRules =
    "Password must be at least 10 characters and contain at least one number and a mix of upper and lower case letters.";
  const [errorText, setErrorText] = useState(
    isConfirmPassword ? "" : passwordRules
  );
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const handleChange = (password: string) => {
    const truncatedPassword = password.substring(0, MAX_PASSWORD_LENGTH);
    let valid;
    if (!password) {
      valid = false;
      setErrorText("Please enter a password");
    } else if (isConfirmPassword) {
      valid = truncatedPassword === expectedPassword;
      setErrorText(valid ? "" : "Passwords do not match");
    } else if (isLogIn && password) {
      valid = true;
    } else {
      valid = PASSWORD_REGEX.test(truncatedPassword);
      if (!valid) {
        setErrorText(passwordRules);
      }
    }
    setIsPasswordValid(valid);
    onChange(truncatedPassword, valid);
  };

  useEffect(() => {
    if (isConfirmPassword) {
      const passwordsMatch = value === expectedPassword;
      setErrorText(passwordsMatch ? "" : "Passwords do not match");
      setIsPasswordValid(passwordsMatch);
    }
  }, [isConfirmPassword, expectedPassword, value]);

  return (
    <TextInput
      className={className}
      title={
        title ? title : isConfirmPassword ? "Confirm password" : "Password"
      }
      autoComplete={newPassword ? "new-password" : "current-password"}
      onChange={handleChange}
      value={value}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onShowPasswordClick}
              onMouseDown={(evt) => evt.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={
        showError && !isPasswordValid
          ? errorText
          : isConfirmPassword || hideHelperText || isLogIn
          ? ""
          : passwordRules
      }
      error={showError && !isPasswordValid}
    />
  );
}
