import LinearProgress from "@material-ui/core/LinearProgress";

import useStyles from "./FullPageSpinner.styles";

export default function FullPageSpinner() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" className={classes.progress} />
    </div>
  );
}
