import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useUser from "../../../hooks/useUser";
import { LOGIN_PAGE_ROUTE, REGISTER_PAGE_ROUTE } from "../../../Routes";
import MetailLogo from "../MetailLogo/MetailLogo";
import DropdownMenu from "./DropdownMenu";
import useStyles from "./PageHeader.styles";
import PersistentDrawer from "./PersistentDrawer";
import TemporaryDrawer from "./TemporaryDrawer";

export default function PageHeader() {
  const { user, userIsVerified } = useUser();
  const classes = useStyles();
  const navigate = useNavigate();
  const [temporaryDrawerIsOpen, setTemporaryDrawerIsOpen] = useState(false);

  return (
    <>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar>
          {userIsVerified && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={() => setTemporaryDrawerIsOpen(true)}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          <MetailLogo className={classes.logo} />
          <Typography variant="h6" className={classes.title} noWrap>
            EcoShot Image Hub
          </Typography>
          <div className={classes.buttons}>
            {!user && (
              <>
                <Button
                  className={classes.accountButton}
                  onClick={() => navigate(LOGIN_PAGE_ROUTE)}
                >
                  Log in
                </Button>
                <Button
                  className={classes.accountButton}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => navigate(REGISTER_PAGE_ROUTE)}
                >
                  Register
                </Button>
              </>
            )}
            <DropdownMenu />
          </div>
        </Toolbar>
      </AppBar>
      {userIsVerified && (
        <>
          <PersistentDrawer />
          <TemporaryDrawer
            isOpen={temporaryDrawerIsOpen}
            handleClose={() => setTemporaryDrawerIsOpen(false)}
          />
        </>
      )}
    </>
  );
}
