import Typography from "@material-ui/core/Typography";
import { ReactNode } from "react";

import useStyles from "./FailedAccountsRequestMessage.styles";

interface IProps {
  headline: string;
  subline?: string;
  content?: ReactNode;
  className?: string;
}

export default function FailedAccountsRequestMessage({
  headline,
  subline,
  content,
  className,
}: IProps) {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <Typography className={classes.headline}>{headline}</Typography>
      {subline && <Typography>{subline}</Typography>}
      {content}
    </div>
  );
}
