import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PackagesIcon from "@material-ui/icons/BusinessCenter";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExtensionIcon from "@material-ui/icons/Extension";
import FaceIcon from "@material-ui/icons/Face";
import InfoIcon from "@material-ui/icons/Info";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ImageProfilesIcon from "@material-ui/icons/PhotoLibrary";
import PolicyIcon from "@material-ui/icons/Policy";
import SecurityIcon from "@material-ui/icons/Security";
import BasketIcon from "@material-ui/icons/ShoppingBasket";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import classnames from "classnames";
import React, { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import { useUiState } from "../../../context/UiStateContext";
import { useGroupData } from "../../../hooks/api/groups";
import { useUserInvites } from "../../../hooks/api/user";
import useUser from "../../../hooks/useUser";
import {
  DOWNLOAD_PLUGIN_PAGE_ROUTE,
  ECOSHOTS_COMPLETED_PAGE_ROUTE,
  ECOSHOTS_PAGE_ROUTE,
  GROUP_ADMINISTRATION_PAGE_ROUTE,
  IMAGE_PROFILES_PAGE_ROUTE,
  INVITES_PAGE_ROUTE,
  MODEL_POSES_PAGE_ROUTE,
  PACKAGES_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
  VARIANTS_PAGE_ROUTE,
} from "../../../Routes";
import useStyles from "./DrawerContent.styles";
import SelectGroupsButton from "./SelectGroupsButton";

interface IRouteListItem {
  matchRoute: string;
  navigateTo?: string;
  icon: ReactNode;
  label: string;
  badgeContent?: number;
}

interface IProps {
  onItemClicked?: () => void;
}

export default function DrawerContent({ onItemClicked }: IProps) {
  const { logout } = useAuth();
  const {
    user,
    userGroupId,
    userIsSuperUser,
    userIsGroupAdmin,
    userCanRequestEcoshot,
  } = useUser();
  const { data: groupData } = useGroupData();
  const { clearUiState } = useUiState();
  const { data: invites } = useUserInvites();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const groupName = groupData?.Item.GroupName.S;
  const isIntro = groupData?.Item.IsIntro?.BOOL;

  const RouteListItem = ({
    matchRoute,
    navigateTo,
    icon,
    label,
    badgeContent,
  }: IRouteListItem) => (
    <ListItem
      button
      className={classnames(classes.listItem, {
        [classes.listItemSelected]: location.pathname.startsWith(matchRoute),
      })}
      onClick={() => {
        onItemClicked && onItemClicked();
        navigate(navigateTo ? navigateTo : matchRoute);
      }}
    >
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
      <ListItemText primary={label} />
      {badgeContent && (
        <ListItemSecondaryAction>
          <Badge
            color="secondary"
            badgeContent={badgeContent}
            className={classes.badge}
          >
            <Typography variant="body2"></Typography>
          </Badge>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  const userFullname = user.attributes.name;

  const onLogoutClicked = () => {
    clearUiState();
    logout();
  };

  const onHelpCentreClicked = () => {
    window.open(
      "https://tronog.odoo.com/en_GB/knowledge/article/135",
      "_blank"
    );
  };

  const onPrivacyPolicyClicked = () => {
    window.open("https://tronog.com/policies/privacy-policy", "_blank");
  };

  const onPricingPageClicked = () => {
    window.open("https://tronog.com/pages/ecoshot", "_blank");
  };

  const onServicesAgreementClicked = () => {
    window.open(
      "https://tronog.com/pages/ecoshot-services-agreement",
      "_blank"
    );
  };

  const invitations = invites?.Result.GroupInvites ?? [];

  return (
    <div className={classes.root}>
      <div className={classes.accountDetails}>
        <div className={classes.avatarContainer}>
          <AccountCircleIcon className={classes.avatar} />
        </div>
        <div className={classes.fullName}>
          <Tooltip title={userFullname} placement="right-start">
            <Typography variant="body2" noWrap>
              {userFullname}
            </Typography>
          </Tooltip>
          {userIsSuperUser && (
            <Typography
              variant="body2"
              color={"textSecondary"}
              noWrap
              className={classes.groupType}
            >
              Super user
            </Typography>
          )}
        </div>
        {groupName && !isIntro && (
          <Typography variant="subtitle2" className={classes.groupName} noWrap>
            {groupName}
          </Typography>
        )}
        {(!groupName || isIntro) && (
          <Typography variant="subtitle2" className={classes.groupName} noWrap>
            EcoShot Intro
          </Typography>
        )}
        {(userIsGroupAdmin || userIsSuperUser) && (
          <Typography
            variant="body2"
            color={"textSecondary"}
            noWrap
            className={classes.groupType}
          >
            {userIsSuperUser ? "Group Admin (Super User)" : "Group Admin"}
          </Typography>
        )}
        {userIsSuperUser && (
          <div className={classes.selectGroupButtom}>
            <SelectGroupsButton />
          </div>
        )}
      </div>
      <Divider />
      {userGroupId && (
        <RouteListItem
          matchRoute={ECOSHOTS_PAGE_ROUTE}
          navigateTo={ECOSHOTS_COMPLETED_PAGE_ROUTE}
          icon={<AccessibilityIcon fontSize="small" />}
          label="EcoShot Images"
        />
      )}
      {(userCanRequestEcoshot || userIsSuperUser) && (
        <>
          <RouteListItem
            matchRoute={IMAGE_PROFILES_PAGE_ROUTE}
            icon={<ImageProfilesIcon fontSize="small" />}
            label="Image Profiles"
          />
          <Divider />
        </>
      )}
      {(userIsGroupAdmin || userIsSuperUser || isIntro) && (
        <>
          <List>
            {(userIsGroupAdmin || userIsSuperUser) && (
              <RouteListItem
                matchRoute={GROUP_ADMINISTRATION_PAGE_ROUTE}
                icon={<SupervisedUserCircleIcon fontSize="small" />}
                label="Group Admin"
              />
            )}
            <RouteListItem
              matchRoute={PACKAGES_PAGE_ROUTE}
              icon={<PackagesIcon fontSize="small" />}
              label="Packages"
            />
          </List>
          <Divider />
        </>
      )}
      <List>
        {invitations.length > 0 && (
          <RouteListItem
            matchRoute={INVITES_PAGE_ROUTE}
            icon={<MailOutlineIcon fontSize="small" />}
            label="Invites"
            badgeContent={invitations.length}
          />
        )}
        <RouteListItem
          matchRoute={PROFILE_PAGE_ROUTE}
          icon={<AccountCircleIcon fontSize="small" />}
          label="Profile"
        />
        <RouteListItem
          matchRoute={DOWNLOAD_PLUGIN_PAGE_ROUTE}
          icon={<ExtensionIcon fontSize="small" />}
          label="Download Plugin"
        />
      </List>
      <Divider />
      <List>
        <RouteListItem
          matchRoute={MODEL_POSES_PAGE_ROUTE}
          icon={<EmojiPeopleIcon fontSize="small" />}
          label="Model Poses"
        />
        <RouteListItem
          matchRoute={VARIANTS_PAGE_ROUTE}
          icon={<FaceIcon fontSize="small" />}
          label="Styling Options"
        />
        <ListItem
          button
          className={classes.listItem}
          onClick={onHelpCentreClicked}
        >
          <ListItemIcon className={classes.listIcon}>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Help Centre" />
        </ListItem>
        {(!groupName || isIntro) && (
          <>
            <ListItem
              button
              className={classes.listItem}
              onClick={onPricingPageClicked}
            >
              <ListItemIcon className={classes.listIcon}>
                <BasketIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Pricing Information" />
            </ListItem>
          </>
        )}
        <ListItem
          button
          className={classes.listItem}
          onClick={onServicesAgreementClicked}
        >
          <ListItemIcon className={classes.listIcon}>
            <PolicyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Servicess Agreement" />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          onClick={onPrivacyPolicyClicked}
        >
          <ListItemIcon className={classes.listIcon}>
            <SecurityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Privacy Policy" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button className={classes.listItem} onClick={onLogoutClicked}>
          <ListItemIcon className={classes.listIcon}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </div>
  );
}
