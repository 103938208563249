import "./index.css";
import "stop-runaway-react-effects/hijack";

import CssBaseline from "@material-ui/core/CssBaseline";
import Amplify, { Auth } from "aws-amplify";
import ReactDOM from "react-dom";

import App from "./App";
import AppProviders from "./AppProviders";
import * as serviceWorker from "./serviceWorker";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLWEBCLIENTID,
    identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITYPOOLID,
  },
  API: {
    endpoints: [
      {
        name: "API",
        endpoint: process.env.REACT_APP_AMPLIFY_API_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
});

ReactDOM.render(
  <>
    <AppProviders>
      <CssBaseline />
      <App />
    </AppProviders>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
