import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  progress: {
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
}));
