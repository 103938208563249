import makeStyles from "@material-ui/core/styles/makeStyles";

import { container, pageContentContainer } from "../../../MixinsAndVars.styles";

export default makeStyles((theme) => ({
  headerContainer: container(theme),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  contentContainer: pageContentContainer(theme),
  content: {
    maxWidth: 560,
  },
  message: {
    marginBottom: theme.spacing(3),
  },
  groupName: {
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
}));
