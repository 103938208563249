import React, { useState } from "react";

import TextInput from "../TextInput/TextInput";

const VERIFICATION_CODE_REGEX = new RegExp("^[0-9]{6}$");

interface IProps {
  showTitle?: boolean;
  onChange: (code: string, isValid: boolean) => void;
  value: string;
  className?: string;
  showError?: boolean;
}

export default function VerificationCodeInput({
  showTitle,
  onChange,
  value,
  className,
  showError,
}: IProps) {
  const invalidCodeMessage = "The code must be 6 digits";
  const [errorMessage, setErrorMessage] = useState(invalidCodeMessage);
  const [isCurrentValueValid, setIsCurrentValueValid] = useState(false);

  const handleChange = (verificationCode: string) => {
    const valid = VERIFICATION_CODE_REGEX.test(verificationCode);
    setErrorMessage(valid ? "" : invalidCodeMessage);
    setIsCurrentValueValid(valid);
    onChange(verificationCode, valid);
  };

  return (
    <form>
      <TextInput
        title={showTitle ? "Verification code" : undefined}
        autoComplete="code"
        className={className}
        onChange={handleChange}
        value={value}
        helperText={showError && errorMessage}
        error={showError && !isCurrentValueValid}
      />
    </form>
  );
}
