import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  createButton: {
    marginBottom: theme.spacing(4),
  },
  sectionHeader: {
    fontSize: "1.1rem",
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  emailInput: {
    marginBottom: theme.spacing(2),
  },
  profileNameInput: {
    marginTop: theme.spacing(2),
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& select": {
      marginLeft: theme.spacing(0.5),
    },
  },
  checkbox: {
    margin: `-4px 0 -4px -11px`,
  },
  textarea: {
    marginTop: theme.spacing(1.5),
  },
  turnaroundMessage: {
    marginTop: theme.spacing(1.5),
  },
}));
