import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(() => ({
  thumbnail: {
    margin: "0 auto",
    display: "none",
    width: 100,
    height: 150,
    backgroundColor: "#efefef",
  },
  showThumbnail: {
    display: "block",
  },
  missingThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
