import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import useStateReducer from "../hooks/useStateReducer";
import setObjectValues from "../views/utils/setObjectValues";

type UiState = Record<string, any>;

interface IUiStateContext {
  uiState: UiState;
  setUiState: (key: string, val: any) => void;
  clearUiState: () => void;
}

const defaultValue = {};
const UiStateContext = createContext<IUiStateContext>(
  defaultValue as IUiStateContext
);
UiStateContext.displayName = "UiStateContext";

const localStorageKey = "ecoshot-image-hub-ui-state";

function UiStateProvider({ children }: PropsWithChildren<Record<string, any>>) {
  const [uiState, internalSetUiState] = useStateReducer<UiState>({});

  useEffect(() => {
    try {
      const uiStateFromLocalStorage =
        JSON.parse(localStorage.getItem(localStorageKey) ?? "") ?? {};
      internalSetUiState(uiStateFromLocalStorage);
    } catch (error) {
      // nothing we can do if there's an error reading the value from localStorage other than to
      // clear it and start afresh
      localStorage.removeItem(localStorageKey);
    }
  }, [internalSetUiState]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(uiState));
  }, [uiState]);

  const clearUiState = useCallback(() => {
    internalSetUiState(setObjectValues(uiState, null));
    localStorage.removeItem(localStorageKey);
  }, [uiState, internalSetUiState]);

  const setUiState = useCallback(
    (key, val) => internalSetUiState({ [key]: val }),
    [internalSetUiState]
  );

  const value = useMemo(
    () => ({
      uiState,
      setUiState,
      clearUiState,
    }),
    [uiState, setUiState, clearUiState]
  );

  return (
    <UiStateContext.Provider value={value}>{children}</UiStateContext.Provider>
  );
}

function useUiState() {
  const context = useContext(UiStateContext);
  if (context === defaultValue) {
    throw new Error(
      "useUiState hook must be used within a UiStateProvider component"
    );
  }
  return context;
}

export { UiStateProvider, useUiState };
