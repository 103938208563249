import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export interface INotification {
  message: string;
  key: number;
}

interface INotificationContext {
  notifications: Array<INotification>;
  addNotification: (message: string) => void;
  removeNotification: () => void;
}

const defaultValue = {};
const NotificationContext = createContext<INotificationContext>(
  defaultValue as INotificationContext
);

function NotificationProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const [notifications, setNotifications] = useState<Array<INotification>>([]);

  const addNotification = useCallback(
    (message) => {
      setNotifications((prev) => [
        ...prev,
        { message, key: new Date().getTime() },
      ]);
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    () => setNotifications(notifications.slice(1)),
    [notifications, setNotifications]
  );

  const value = useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
    }),
    [notifications, addNotification, removeNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotifications() {
  const context = useContext(NotificationContext);
  if (context === defaultValue) {
    throw new Error(
      "useNotifications hook must be used within a useNotifications component"
    );
  }
  return context;
}

export { NotificationProvider, useNotifications };
