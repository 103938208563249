import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ImageProfilesIcon from "@material-ui/icons/PhotoLibrary";
import { useEffect } from "react";

import { useImageProfiles } from "../../../hooks/api/imageProfiles";
import useFilterJobsUiState from "../../../hooks/useFilterJobsUiState";
import useUser from "../../../hooks/useUser";
import { ImageProfile } from "../../../types/core";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import AddImageProfileButton from "./AddImageProfileButton";
import ImageProfileItem from "./ImageProfileItem";
import useStyles from "./ImageProfilesPage.styles";

export default function ImageProfilesPage() {
  const classes = useStyles();
  const { userIsSuperUser } = useUser();
  const { uiState: groupSelect } = useFilterJobsUiState();
  const { data: imageProfiles, isLoading: areImageProfilesLoading } =
    useImageProfiles(userIsSuperUser ? groupSelect : null);

  useEffect(() => {
    document.title = "EcoShot Image Hub - Image Profiles";
  }, []);

  return (
    <div>
      <Paper square>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <ImageProfilesIcon fontSize="small" />
            <Typography variant="h6">Image Profiles</Typography>
          </div>
        </div>
      </Paper>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {areImageProfilesLoading || !imageProfiles?.Items ? (
            <PageLoadingIndicator />
          ) : (
            <>
              {!userIsSuperUser && <AddImageProfileButton />}
              {imageProfiles?.Items.length > 0 ? (
                imageProfiles.Items.map((item: ImageProfile) => (
                  <ImageProfileItem key={item.ImageProfileId.S} item={item} />
                ))
              ) : (
                <Typography color="textSecondary">
                  You have not yet created any image profiles
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
