import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Fragment } from "react";

import {
  useGroupInvitations,
  useGroupMembers,
} from "../../../hooks/api/groups";
import useFilterJobsUiState from "../../../hooks/useFilterJobsUiState";
import useUser from "../../../hooks/useUser";
import { GroupInvitation } from "../../../types/core";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import InvitationItem from "./InvitationItem";
import useStyles from "./Invitations.styles";
import InviteMemberButton from "./InviteMemberButton";

export default function Invitations() {
  const classes = useStyles();
  const { userIsSuperUser } = useUser();
  const { uiState: groupSelect } = useFilterJobsUiState();
  const { data: groupInvitations, isLoading: areInvitationsLoading } =
    useGroupInvitations(userIsSuperUser ? groupSelect.group : null);
  const { data: groupMembers, isLoading: areGroupMembersLoading } =
    useGroupMembers(userIsSuperUser ? groupSelect.group : null);

  if (areInvitationsLoading || areGroupMembersLoading) {
    return <PageLoadingIndicator />;
  }
  let content;
  if (!groupInvitations?.Items || groupInvitations.Items.length === 0) {
    content = (
      <Typography color="textSecondary">No open invitations</Typography>
    );
  } else {
    content = (
      <Paper>
        <List>
          {groupInvitations.Items.map((invite: GroupInvitation, index) => {
            return (
              <Fragment key={invite.InviteId.S}>
                <InvitationItem invite={invite} />
                {groupInvitations.Items &&
                  index < groupInvitations.Items.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </List>
      </Paper>
    );
  }
  return (
    <div className={classes.root}>
      {groupMembers?.Result && groupInvitations?.Items && !userIsSuperUser && (
        <InviteMemberButton
          groupMembers={groupMembers.Result.Members}
          groupInvitations={groupInvitations.Items}
        />
      )}

      {content}
    </div>
  );
}
