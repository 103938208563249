import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import BulletPoint from "../../components/BulletPoint/BulletPoint";
import useStyles from "./ImageRightsManagementTemplatePage.styles";

export default function ImageRightsManagementTemplatePage() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.content}>
        <Typography variant="h5" component="h2" className={classes.heading}>
          Customer Model Images: Image Rights Management Template
        </Typography>
        <div className={classes.mainContent}>
          <Typography variant="body2">
            This letter confirms our understanding of the Customer Model
            Agency’s Terms and Conditions between [Name of the model agency that
            is supplying the photographed model to the Customer] (Customer Model
            Agency), [Name of EcoShot customer] (Customer) and Metail Limited
            (Metail) who are a 3D technology supplier to the Customer.
          </Typography>
          <Typography variant="body2" gutterBottom>
            "<b>Combined Images</b>" means images and animations derived by, or
            on behalf of, Metail from the combination of 3D designs provided by
            the Customer to Metail and Customer Model Images;
          </Typography>
          <Typography variant="body2" gutterBottom>
            "<b>Customer Model Agency</b>" means the agency, photographer, model
            or other third party that supplied models to the Customer for the
            creation of Customer Model Images and from whom model consents need
            to be obtained;
          </Typography>
          <Typography variant="body2" gutterBottom>
            "<b>Customer Model Images</b>" means the images and scans of models
            provided to Metail by the Customer for use in conjunction with the
            Product by Customer under the terms of the Agreement between the
            Customer and Metail;
          </Typography>
          <BulletPoint bullet="1">
            <Typography variant="body2">
              Customer wishes to use models supplied by the Customer Model
              Agency to enable Customer to create its own Customer Model Images
              for the purpose of Metail deriving Combined Images (Permitted
              Purpose).
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="2">
            <Typography variant="body2">
              The Customer Model Agency shall permit Metail to use the Customer
              Model Images for the Permitted Purpose.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="3">
            <Typography variant="body2">
              Use of the Customer Model Images for the Permitted Purpose shall
              be for [24 months].
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="4">
            <Typography variant="body2">
              That no further additional fees are payable pursuant to the Fees
              clause of the Customer Model Agency’s Client Terms and Conditions
              for the Permitted Purpose.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="5">
            <Typography variant="body2">
              That the Territory for the Permitted Purpose is worldwide pursuant
              to the Territory clause of the Agency’s Terms and Conditions.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="6">
            <Typography variant="body2">
              The Agency acknowledge no further fees are due under the Exclusion
              Fees clause of the Customer Model Agency’s Terms and Conditions.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="7">
            <Typography variant="body2">
              The Customer Model Agency acknowledges that Metail shall have full
              title and ownership of all Intellectual Property Rights arising in
              the Combined Images.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="8">
            <Typography variant="body2">
              The Customer Model Agency acknowledges that all necessary consents
              as required under the United Kingdom’s Data Protection Act 2018
              and/or as applicable under the laws of the Territory have been
              obtained by the Customer Model Agency for the benefit of Customer
              and Metail.
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="9">
            <Typography variant="body2">
              The Customer Model Agency acknowledges that all moral rights in
              the Customer Model Images and the Combined Images have been
              waived.
            </Typography>
          </BulletPoint>

          <Typography variant="body2">
            Half Day Underwear / Swimwear Shoot ( Up to 4 Hrs ) Usage /
            Copyright:
          </Typography>
          <BulletPoint bullet="o">
            <Typography variant="body2">Worldwide</Typography>
          </BulletPoint>
          <BulletPoint bullet="o">
            <Typography variant="body2">[eCommerce imagery]</Typography>
          </BulletPoint>
          <BulletPoint bullet="o">
            <Typography variant="body2">[Product packaging print]</Typography>
          </BulletPoint>
          <BulletPoint bullet="o">
            <Typography variant="body2">
              [Digital and physical signage]
            </Typography>
          </BulletPoint>
          <BulletPoint bullet="o">
            <Typography variant="body2">
              Reproduction within Metail software used by the Customer
            </Typography>
          </BulletPoint>
        </div>
      </Paper>
    </div>
  );
}
