import decimalToBinary from "./decimalToBinary";
import { MemberPermission } from "./memberPermissions";

export default function getUserRoles(num: number) {
  const permissionsBinary = decimalToBinary(num, 8);
  const binaryLength = permissionsBinary.length;
  const roles = new Set<MemberPermission>();
  if (permissionsBinary[binaryLength - 2] === "1") {
    roles.add("request_ecoshot");
  }
  if (permissionsBinary[binaryLength - 3] === "1") {
    roles.add("request_retouch");
  }
  if (permissionsBinary[binaryLength - 4] === "1") {
    roles.add("group_admin");
  }
  if (permissionsBinary[binaryLength - 8] === "1") {
    roles.add("super_user");
  }
  return roles;
}
