import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import useStateReducer from "../../../hooks/useStateReducer";
import {
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
} from "../../../Routes";
import EmailInput from "../../components/EmailInput/EmailInput";
import FailedAccountsRequestMessage from "../../components/FailedAccountsRequestMessage/FailedAccountsRequestMessage";
import FullWidthButtonWithProgress from "../../components/FullWidthButtonWithProgress/FullWidthButtonWithProgress";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import RequestNewVerificationCodeLink from "../../components/RequestNewVerificationCodeLink/RequestNewVerificationCodeLink";
import RouteLink from "../../components/RouteLink/RouteLink";
import useStyles from "./LoginPage.styles";

type IState = {
  email: string;
  isEmailValid: boolean;
  password: string;
  isPasswordValid: boolean;
  showPassword: boolean;
  showInputErrorMessages: boolean;
};

interface LocationState {
  referrer?: string;
}

export default function LoginPage() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const {
    mutate: doLogin,
    isLoading: isLoggingIn,
    error: loginError,
  } = useMutation<
    void,
    Error & { code: string },
    {
      email: string;
      password: string;
      newPasswordOnChallenge?: string;
    }
  >(login);

  useEffect(() => {
    document.title = "EcoShot Image Hub - Log In";
  }, []);

  const [formState, setFormState] = useStateReducer<IState>({
    email: "",
    isEmailValid: false,
    password: "",
    isPasswordValid: false,
    showPassword: false,
    showInputErrorMessages: false,
  });

  const referrer = (location.state as LocationState)?.referrer;

  const onLoginSuccess = () => {
    if (referrer) {
      navigate(referrer);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  const onLogin = async () => {
    if (isLoggingIn) {
      return;
    }
    const { email, isEmailValid, password, isPasswordValid } = formState;
    if (!isEmailValid || !isPasswordValid) {
      setFormState({ showInputErrorMessages: true });
    } else {
      setFormState({ showInputErrorMessages: false });
      await doLogin(
        { email, password },
        {
          onSuccess: onLoginSuccess,
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography variant="h4" component="h1">
            Log in
          </Typography>
        </div>
        {loginError && (
          <FailedAccountsRequestMessage
            className={classes.failedRequestMessage}
            headline={loginError.message}
            content={
              loginError.code === "UserNotConfirmedException" && (
                <RequestNewVerificationCodeLink
                  email={formState.email}
                  type="verify-account"
                  plainStyling
                />
              )
            }
          />
        )}
        <div className={classes.inputs}>
          <EmailInput
            onChange={(email, isEmailValid) =>
              setFormState({ email, isEmailValid })
            }
            value={formState.email}
            className={classes.emailInput}
            showError={formState.showInputErrorMessages}
          />
          <PasswordInput
            onChange={(password, isPasswordValid) =>
              setFormState({ password, isPasswordValid })
            }
            onShowPasswordClick={() =>
              setFormState({ showPassword: !formState.showPassword })
            }
            showPassword={formState.showPassword}
            value={formState.password}
            showError={formState.showInputErrorMessages}
            isLogIn
            hideHelperText
          />
        </div>
        <FullWidthButtonWithProgress
          className={classes.submitButton}
          label="Log in"
          onClick={onLogin}
          isLoading={isLoggingIn}
        />
        <div className={classes.footerLinks}>
          <Typography variant="body2">
            <RouteLink
              label="Forgot your password?"
              route={FORGOT_PASSWORD_PAGE_ROUTE}
              className={classes.footerLink}
            />
          </Typography>
        </div>
        <Typography variant="body2" className={classes.noAccount}>
          Don't have an account?
          <Link
            className={classes.noAccountLink}
            href="#"
            onClick={(evt) => {
              evt.preventDefault();
              navigate(REGISTER_PAGE_ROUTE);
            }}
          >
            Register
          </Link>
        </Typography>
      </Paper>
    </div>
  );
}
